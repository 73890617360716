import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Invitacion from "./components/invitacion/invitacion";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import Eventos from "./views/eventos/Eventos";
import Evento from "./views/eventos/Evento";
import Compra from "./views/encuentro/SolicitudCompra";
import MercadoPago from "./views/encuentro/MercadoPago";
import Ventas from "./views/ventas/Ventas";
import Venta from "./views/ventas/Venta";
import ReciboVenta from "./views/ventas/ReciboVenta";
import { QrTickets } from "./views/eventos/QrTickets";
import { QrVoucher } from "./views/eventos/QrVoucher";
import Tickets from "./views/eventos/Tickets";
import Ticket from "./views/eventos/Ticket";
import Colaboradores from "./views/eventos/Colaborador";
import UsuarioClientes from "./views/eventos/usuarioCliente";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import { NuevoTicket } from "./views/eventos/NuevoTicket";
import { ExcelImport } from "./views/eventos/ExcelImport";
import { Ubicaciones } from "./views/eventos/Ubicaciones";
import Publicaciones from "./views/eventos/Publicaciones";
import Categorias from "./views/eventos/Categorias";
import Impresion from "./views/eventos/Impresion";
import Precios from "./views/Precios"
import Notificaciones from "./views/Notificaciones";
import Inscripcion from "./views/encuentro/Inscripcion";
import { EventoPublic } from "./views/eventos/EventoPublic";
import CrearTarifa from "./views/eventos/CrearTarifa";
import { CrearPublicacion } from "./views/eventos/CrearPublicacion";
import HistorialCompras from "./views/encuentro/HistorialCompras/HistorialCompras";
import { Vouchers } from "./views/eventos/Vouchers";
import Voucher from "./views/eventos/voucher";
import { CrearVoucher } from "./views/eventos/CrearVoucher";
import NewEvent from "./views/eventos/evento/NewEvent";
import { Vales } from "./views/eventos/Vales";
import SetEvent from "./views/eventos/evento/SetEvent";
import Cliente, { connectSocket } from "./components/Socket/cliente";
import { ExcelImportVouchers } from "./views/eventos/ExcelImportVouchers";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { PaginatorProvider } from "./providers/Paginator";
import CuentasBancarias from "./views/eventos/CuentasBancarias";
import CuentaBancaria from "./views/eventos/CuentaBancaria";
/* import CrearCuentaBancaria from "./views/eventos/CrearCuentaBancaria"; */
import CrearBilletera from "./views/eventos/CrearBilletera";
import Layout from "./views/eventos/Layout";
import Tarifas from "./views/eventos/Tarifas";
import EditPublicacion from "./views/eventos/EditPublicacion";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import ProtectedLayout from "./components/ProtectedRoutes/ProtectedLayout";
import Unauthorized from "./components/ProtectedRoutes/Unauthorized";
import { UserProvider } from "./components/ProtectedRoutes/UserContext";
import CarritoCompra from "./views/eventos/CarritoCompra";
import NewEvento from "./views/eventos/evento/NewEvent";
import CreaEventos from "./views/CreaEventos";
import NotificacionComponent from "./components/Socket/cliente";
import { useTranslation } from "react-i18next";

initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <UserProvider>
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <NotificacionComponent />
          <Container fluid className="flex-grow-1" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Switch>
              <Route exect path="/detalles/:id" component={HistorialCompras} />
              <Route exect path="/invitacion" component={Invitacion} />
              <Route exact path="/" component={Home} />
              <Route exact path="/creaeventos" component={CreaEventos} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/notificaciones" component={Notificaciones} />
              <Route exact path="/external-api" component={ExternalApi} />
              <Route exact path="/encuentro/comprar" component={Compra} />
              <Route exact path="/eventos/:id/inscripcion" component={Inscripcion} />
              <Route exact path="/eventos" component={Eventos} />
              <Route exact path="/precios" component={Precios} />
              <Route exact path="/eventos/cuentas/:id" component={CuentaBancaria} />
              <Route exact path="/payment" component={MercadoPago} />
              <Route exact path="/eventos/ventas/:id" component={Venta} />
              <Route exact path="/eventos/ventas/:id/recibo" component={ReciboVenta} />
              <Route exact path="/evento/:id/informacion" component={EventoPublic} />

              <Route exact path="/eventos/crear" component={NewEvent} />
              <Route exact path="/publicaciones/:id/editar" component={EditPublicacion} />
              <Route path="/unauthorized" component={Unauthorized} />
              <Route exact path="/carrito" component={CarritoCompra} />
              {/* <Route exact path="/socket/cliente" component={NotificacionComponent}></Route> */}

              <Route path="/eventos/:id">
                <PaginatorProvider>
                  <ProtectedLayout allowedRoles={[1, 2, 3]}>
                    <Layout>
                      <Switch>
                        <Route exact path="/eventos/:id" component={Evento} />
                        <ProtectedRoute path="/eventos/:id/categorias" component={Categorias} allowedRoles={[1, 2]} />
                        <ProtectedRoute exact path="/eventos/:id/colaboradores" component={Colaboradores} allowedRoles={[1, 2]} />
                        <ProtectedRoute exact path="/eventos/:id/clientes" component={UsuarioClientes} allowedRoles={[1, 3]} />
                        <Route exact path="/eventos/:id/qrtickets" component={QrTickets} />
                        <Route exact path="/eventos/:id/qrvouchers" component={QrVoucher} />
                        <ProtectedRoute exact path="/eventos/:id/ubicaciones" component={Ubicaciones} allowedRoles={[1, 2]} />
                        <ProtectedRoute exact path="/eventos/:id/tarifas" component={Tarifas} allowedRoles={[1, 2]} />
                        <Route exact path="/eventos/:id/crearTarifa" component={CrearTarifa} />
                        <Route exact path="/eventos/:id/nuevoTicket" component={NuevoTicket} />
                        <Route exact path="/eventos/:id/nuevoTicket/importarExcel" component={ExcelImport} />
                        <ProtectedRoute exact path="/eventos/:id/publicaciones" component={Publicaciones} allowedRoles={[1, 3]} />
                        <ProtectedRoute exact path="/eventos/:id/cuentas" component={CuentasBancarias} allowedRoles={[1]} />
                        {/*  <Route exact path="/eventos/:id/crear-cuenta" component={CrearCuentaBancaria} /> */}
                        <Route exact path="/eventos/:id/crear-cuenta" component={CrearBilletera} />
                        <ProtectedRoute exact path="/eventos/:id/ventas" component={Ventas} allowedRoles={[1, 3]} />
                        <ProtectedRoute exact path="/eventos/:id/tickets" component={Tickets} allowedRoles={[1, 2, 3]} />
                        <ProtectedRoute exact path="/eventos/:id/impresion" component={Impresion} allowedRoles={[1, 2]} />
                        <Route exact path="/eventos/:id/publicaciones/nueva" component={CrearPublicacion} />
                        <Route exact path="/eventos/:id/vouchers" component={Vouchers} />
                        <Route exact path="/eventos/:id/nuevoVoucher" component={CrearVoucher} />
                        <Route exact path="/eventos/:id/nuevoVoucher/importarExcel" component={ExcelImportVouchers} />
                        <Route exact path="/eventos/:id/vouchers/:idvoucher" component={Voucher} />
                        <Route exact path="/eventos/:id/tickets/:idticket" component={Ticket} />
                        <Route exact path="/eventos/:id/tickets&vouchers" component={Vales} />
                        <Route exact path="/eventos/:id/editar" component={SetEvent} />
                      </Switch>
                    </Layout>
                  </ProtectedLayout>
                </PaginatorProvider>
              </Route>
            </Switch>
          </Container>
          <div id="fb-root"></div>
          <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v20.0" nonce="M5gDFpMz"></script>
        </div>
      </Router>
    </UserProvider>

  );
};

export default App;
