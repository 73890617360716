import React, { useState, useEffect } from "react";
import { api } from "../../services/api"
import { useHistory, useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMagnifyingGlass, faSearch, faXmark} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Label, FormGroup,Input, Pagination, 
        PaginationItem, PaginationLink, Table, Button,
        Alert} from "reactstrap";
import VolverButton from "../../components/VolverButton";
import AddCategoryModal from "../../components/modales/AddCategoryModal";
import DeleteCategoryModal from "../../components/modales/DeleteCategoryModal";
import EditCategoryModal from "../../components/modales/EditCategoryModal";
import { useTranslation } from "react-i18next";

export const Categorias = () => {

  const [categorias, setCategorias] = useState([""]);
  const [categoria, setCategoria] = useState("");
  const [evento, setEvento] = useState();
  const [showSearch, setShowSearch] = useState();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const history = useHistory();
  const param = useParams();
  const [query, setQuery] = useState("");  
  const [t] = useTranslation("global");

  //PAGINACION//
  const [pageSize, setPageSize] = useState(10);  
  const [totalLength, setTotalLength] = useState(0);  
  const [currentPage, setCurrentPage] = useState(1);   

  const limitPages = 5; 
  const totalPages = Math.max(Math.ceil(totalLength / pageSize)); 
  let startPage = Math.max(currentPage - Math.floor(limitPages / 2), 1); 
  let endPage = Math.min(startPage + limitPages - 1, totalPages); 
  // Ajustamos startPage en caso de q no alcance a mostrar las paginas asignadas al limitPages
  if (endPage - startPage + 1 < limitPages && startPage > 1) {
    startPage = Math.max(endPage - limitPages + 1, 1);
  }

  useEffect(() => {
    callApi().then((data) => {
      callApiCategorias(data);
    })
  }, [state, query, pageSize, currentPage, categoria]);
  
  const callApi = async () => {
    try {
      const response = await api.eventos.get(param.id);

      if (response.status === "success") {
        const data = response.data.evento;
        setEvento(data);
        return data;
      }
    } catch (error) {
      setState({
        ...state,
        error: "Error en la Red.",
      });
    }
  };

  const callApiCategorias = async (evento) => {
    const response2 = await api.categorias.getAll(evento.id, query, pageSize, currentPage);
    const dataCategorias = response2.data.categorias;
    
    setCategorias(dataCategorias);
    if (response2.data.totalDataSize) {
      setTotalLength(response2.data.totalDataSize);
    }
  }

  const callCategoriesForModals = () => {
    callApi().then((data) => {
      callApiCategorias(data);
    })
  }

 //PAGINACION
  const handleChangePage = (page) => {
    setCurrentPage(page);
  }

  const paginationItems = [];
  for (let page = startPage; page <= endPage; page++) {
    paginationItems.push(
      <PaginationItem active={page === currentPage} key={page}>
        <PaginationLink onClick={() => handleChangePage(page)}>
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  }

  //BUSQUEDA
  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    setQuery('');
  };

  // EDIT
  const EditarCategoria = async (value) => {
    try {
      const response = await api.categorias.editCategory(value);
      if (response.status === "success") 
        setCategoria("");
      setState({
        ...state,
        apiMessage: "Elemento editado."
      })
    } catch (error) {
      console.log(`error: ${error}`);
    }
  }

  return (
    <>
      {evento &&(
        <Container fluid="sm">
          <div className="d-flex justify-content-between">
            <AddCategoryModal 
              evento={evento}
              categorias={categorias}
              callApi={callCategoriesForModals}
            />
            <form onSubmit={(e) => e.preventDefault()}>
              {showSearch ? (
                <div className="w-100 d-flex gap-2">
                  <Input
                    id="exampleSearch"
                    name="q"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Buscar.."
                    type="search"
                  />
                  <Button onClick={() => setShowSearch(false)} color="light">
                    <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
                  </Button>
                </div>
              ) : (
                <Button color="secondary" onClick={handleToggleSearch}>
                  <FontAwesomeIcon icon={faSearch} className="fs-6" />
                </Button>
              )}
            </form>
          </div>

          <Table hover responsive size="sm" className="mt-3">
            <thead key="Categoriass" >
              <tr  className="table-light">
                <th > 
                  #
                </th>
                <th >
                  {t("categorie.categorie")}
                </th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {categorias ? (categorias.map((cat) => (
                <tr key={cat.id}>
                  <td>{cat.id}</td>
                  <td>{cat.categoria}</td>
                  <td>
                    <div className="d-flex justify-content-center"> 
                      <DeleteCategoryModal
                        id={cat.id}
                        callApi={callCategoriesForModals}
                      />
                      <EditCategoryModal
                        id={cat.id}
                        callApi={callCategoriesForModals}
                        cat={cat} categorias={categorias}
                        onUpdate={EditarCategoria}
                      />
                    </div>
                  </td>
                </tr>))
              ) : (
                <tr>
                  <td colSpan={3}>
                    <Alert color="secondary" className="m-0">
                      No hay categorías aún.
                    </Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Row>
            <Col xs="auto">
              <small className="text-left">
                {t("categorie.totalEntries")} {totalLength}
              </small>
            </Col>
            <Col xs="auto" className="mx-auto">
              <Pagination>
                <PaginationItem disabled={currentPage <= 1}>
                  <PaginationLink
                    first
                    href=""
                    onClick={() => handleChangePage(1)}
                  />
                </PaginationItem>
                <PaginationItem disabled={currentPage <= 1}>
                  <PaginationLink
                    previous
                    href=""
                    onClick={() => handleChangePage(currentPage - 1)}
                  />
                </PaginationItem>

                {paginationItems}

                <PaginationItem disabled={currentPage >= totalPages}>
                  <PaginationLink
                    next
                    href=""
                    onClick={() => handleChangePage(currentPage + 1)}
                  />
                </PaginationItem>

                <PaginationItem disabled={currentPage >= totalPages}>
                  <PaginationLink
                    last
                    href=""
                    onClick={() => handleChangePage(totalPages)}
                  />
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <VolverButton />
          </div>
        </Container>
      )}
    </>
  );
}
  
export default withAuthenticationRequired(Categorias, {
  onRedirecting: () => <Loading />,
});
