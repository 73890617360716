import { useRef, useEffect, useContext } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
import { PaginatorContext } from "../../providers/Paginator";

const ImageField = ({ onChange, imgProp, onSelect, isSelected }) => {

  const [image] = useImage(imgProp.imgURL);
  const {dimensionContainer, scale}= useContext(PaginatorContext);

  const imgRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([imgRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected])


  return (
    <>
      <Image
        onClick={onSelect}
        onTap={onSelect}
        ref={imgRef}
        draggable
        image={image}
        {...imgProp}
        onDragEnd={(e) => {
          onChange({
            ...imgProp,
            x: e.target.x(),
            y: e.target.y()
          })
        }}

        onDragMove={(e) => {
          const newX = Math.max(0, Math.min(e.target.x(), dimensionContainer.width*scale - imgProp.width))
          const newY = Math.max(0, Math.min(e.target.y(), dimensionContainer.height*scale - imgProp.height))
          imgRef.current.x(newX);
          imgRef.current.y(newY);
        }}

        onTransform={(e) => {
          const node = imgRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...imgProp,
            x: node.x(),
            y: node.y(),
            width: Math.max(50, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          })
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            const isOut =
            newBox.x < 0 ||
            newBox.y < 0 ||
            newBox.x + newBox.width > dimensionContainer.width*scale ||
            newBox.y + newBox.height > dimensionContainer.height*scale;
            
            if (isOut) {
              return oldBox;
            }            
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}

    </>
  )
}

export default ImageField;