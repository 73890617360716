import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect, useState} from "react";
import Loading from "../../components/Loading";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { Button, Container, FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Table, Row, Col, Tooltip, Alert, } from "reactstrap";
import { faMagnifyingGlass, faXmark, faPlus, faTrash, faPencil, faAdd, faSearch, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import VolverButton from "../../components/VolverButton";
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';
import { useMediaQuery } from "react-responsive";
moment.locale('es');
moment.locale('en-GB');

export const Publicaciones = () => {

  const [state, setState] = useState({ showResult: false, apiMessage: "", error: null, });
  const history = useHistory();
  const param = useParams();
  const { id: eventoId } = useParams();
  const { i18n, t } = useTranslation("global");

  const [publicaciones, setPublicaciones] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalLength, setTotalLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [evento, setEvento] = useState();
  const [showSearch, setShowSearch] = useState();
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ query: '(max-width: 390px)' });

  moment.locale(i18n.language === "es" ? "es" : "en-gb");

  const formatFecha = (fecha) => {
   
  
    if (i18n.language === 'es') {
      return isMobile
        ? moment(fecha).clone().local().format("DD/MM/YY") 
        : moment(fecha).clone().local().format("D [de] MMMM YYYY"); 
    } else {
      return isMobile
        ? moment(fecha).clone().local().format("MM/DD/YY") 
        : moment(fecha).clone().local().format("MMMM D, YYYY"); 
    }
  };

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.publicaciones.getAll(eventoId, query, pageSize, currentPage);

        if (response.status === "success") {
          
          setPublicaciones(response.data.publicaciones || []);
          setTotalLength(response.data.totalDataSize);

        } else {
          setPublicaciones([]);
          setTotalLength(0);
        }
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          error: "Error en la Red.",
        }));
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, [eventoId, query, pageSize, currentPage]);

  useEffect(() => {
    const callEventoNombre = async () => {
      try {
        const response = await api.eventos.get(eventoId);
        if (response.status === "success") {
          const data = response.data.evento;
          setEvento(data);
          return data;
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };
    callEventoNombre();
  }, [eventoId]);

  //Delete
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar esta publicación?"
    );
    if (!confirmDelete) return;

    try {
      const response = await api.publicaciones.deletePublicacion(id);
      if (response.status === "success") {
        setPublicaciones(publicaciones.filter((pub) => pub.id !== id));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: "Error al eliminar la publicación.",
      }));
    }
  };

  const goToPage = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > Math.ceil(totalLength / pageSize)) return;
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalLength / pageSize);
  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <PaginationItem active={page === currentPage} key={page}>
        <PaginationLink onClick={() => goToPage(page)}>
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  }

  const handleToggleSearch = () => {
    setShowSearch(!showSearch); // Cambia el estado de visibilidad del campo de búsqueda
    //setFalseQuery(''); // Limpia el campo de búsqueda al mostrarlo
  };

  return (
    <Container fluid style={{ maxWidth: isMobile ? '325px' : '100%' }}>
      <div className="d-flex justify-content-between mb-3">
        <Button color="success"
          className="d-flex align-items-center gap-2"
          onClick={() => history.push(`/eventos/${param.id}/publicaciones/nueva`)}
        >
          <FontAwesomeIcon icon={faAdd} className="fs-6" />
          {t("button.add")}
        </Button>
        <form onSubmit={(e) => e.preventDefault()}>
          {showSearch ? (
            <div className="w-100 d-flex gap-2">
              <Input
                id="exampleSearch"
                name="q"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Buscar.."
                type="search"
              />
              <Button onClick={() => setShowSearch(false)} color="light">
                <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
              </Button>
            </div>
          ) : (
            <Button color="secondary" onClick={handleToggleSearch}>
              <FontAwesomeIcon icon={faSearch} className="fs-6" />
            </Button>
          )}
        </form>
      </div>

      {publicaciones.length > 0 ? (
        <>
          <Table hover responsive size="sm" style={{width:"100%"}}>
            <thead>
              <tr className="table-light">
                {/* <th>#</th> */}
                <th>{t("publications.title")}</th>
                <th>{t("publications.subtitle")}</th>
                <th>Inicia</th>
                <th >Finaliza</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="">
              {publicaciones?.map((pub) => (
                <tr key={pub.id}>
                  <td style={{whiteSpace:"break-spaces"}}>{pub.titulo}</td>

                  <td style={{whiteSpace:"break-spaces"}}>{pub.subtitulo}</td>

                  <td style={{whiteSpace:"break-spaces"}}>
                    {formatFecha(pub.desde)}
                  </td>

                  <td style={{whiteSpace:"break-spaces"}}>
                    {formatFecha(pub.hasta)}
                  </td>

                  <td style={{whiteSpace:"break-spaces"}}>
                    <Button
                      outline
                      color="danger"
                      id="DeleteButton"
                      className="mr-1"
                      onClick={() => handleDelete(pub.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>

                    <Button
                      outline
                      color="primary"
                      id="EditButton"
                      onClick={() => history.push(`/publicaciones/${pub.id}/editar`)}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col xs="auto">
              <small className="text-left">{t("publications.totalEntries")} {totalLength}</small>
            </Col>
            <Col xs="auto" className="mx-auto">
              <Pagination>
                <PaginationItem disabled={currentPage <= 1}>
                  <PaginationLink first onClick={() => goToPage(1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage <= 1}>
                  <PaginationLink previous onClick={() => goToPage(currentPage - 1)} />
                </PaginationItem>
                {paginationItems}
                <PaginationItem disabled={currentPage >= totalPages}>
                  <PaginationLink next onClick={() => goToPage(currentPage + 1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage >= totalPages}>
                  <PaginationLink last onClick={() => goToPage(totalPages)} />
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        </>
      ) : (
        <Alert color="secondary">
          {t("publications.publicationsAlert")}
        </Alert>
      )}

      <div className="d-flex justify-content-end">
        <VolverButton />
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(Publicaciones, {
  onRedirecting: () => <Loading />,
});
