import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { FormGroup, Label, Input, Form , InputGroup, InputGroupText, FormText, Row, Col, Button, Alert,Card, CardHeader, CardBody, CardText, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";


export const NuevoTicket = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [categorias, setCategorias] = useState();
  const [ubicaciones, setUbicaciones] = useState();
  const [tarifas, setTarifas] = useState();
  const [importe, setImporte] = useState()
  // const [evento, setEvento] = useState([]);
  const [tarifaSeleccionada, setTarifaSeleccionada] = useState('');
  const param = useParams();
  const history = useHistory();
  const [t] = useTranslation("global");


  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    idubicacion: ' ',
    idcategoria: ' ',
    idtarifa: '',
    cantidadtarifas: '1',
    importe: '',
    cantidadtickets: '1'
  })
  const [enviado, setEnviado] = useState(false);

  useEffect (() => {
    const fetchCategorias = async () => {
        try {
          let totalCategorias = [];
          let currentPage = 1;
          let hasMoreTickets = true;
          let filtro = '';
      
          while (hasMoreTickets) {
            const response = await api.categorias.getAll(param.id, filtro, 10, currentPage);
            if (response.status === "success") {
              const categorias  = response.data.categorias; 

              if (categorias && categorias.length > 0) {
                totalCategorias = [...totalCategorias, ...categorias];
                currentPage++;
              } else {
                hasMoreTickets = false;
              }
            }
              setCategorias(totalCategorias)
          }    
        } catch (error) {
            setState({
              ...state,
              error: error.error,
            });
        }
    }
    fetchCategorias();
  },[param.id]);

  useEffect (() => {
    const fetchUbicaciones = async () => {
      try {
        let totalUbicaciones = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';
      
        while (hasMoreTickets) {
          const response = await api.locations.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const ubicaciones  = response.data.ubicaciones;

            if (ubicaciones && ubicaciones.length > 0) {
              totalUbicaciones = [...totalUbicaciones, ...ubicaciones];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setUbicaciones(totalUbicaciones)
        }    
      } catch (error) {
          setState({
            ...state,
            error: error.error,
          })
        }
    }
    fetchUbicaciones();
  }, [param.id])

  useEffect (() => {
    const fetchTarifa = async () => {
        try {
          let totalTarifas = [];
          let currentPage = 1;
          let hasMoreTickets = true;
          let filtro = '';
      
          while (hasMoreTickets) {
            const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
            if (response.status === "success") {
              const tarifas  = response.data.tarifas; 

              if (tarifas && tarifas.length > 0) {
                totalTarifas = [...totalTarifas, ...tarifas];
                currentPage++;
              } else {
                hasMoreTickets = false;
              }
            }
              setTarifas(totalTarifas)
          }    
        } catch (error) {
            setState({
              ...state,
              error: error.error,
            });
        }
    }
    fetchTarifa();
  },[param.id]);

  const entradasTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 1 );

  const [unidad, setUnidad] = useState('');
  useEffect(() => {
    const selectedTarifa = tarifas?.find((tarifa) => tarifa.id === parseInt(formData.idtarifa));
    setUnidad(selectedTarifa ? selectedTarifa.unidad : '');
    setTarifaSeleccionada(selectedTarifa ? selectedTarifa.concepto : '');

    if (selectedTarifa && formData.cantidadtarifas) {
      const cantidad = parseInt(formData.cantidadtarifas);
      const precio = selectedTarifa.precio;
      setImporte(cantidad * precio);
    } else {
      setImporte(0);
    }
  }, [formData.idtarifa, formData.cantidadtarifas, tarifas]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const idCategoriaInt = parseInt(formData.idcategoria);
  const idUbicacionInt = parseInt(formData.idubicacion);
  const idTarifa = parseInt(formData.idtarifa);
  const cantidadTarifas = parseInt(formData.cantidadtarifas);
  const cantidadTickets = parseInt(formData.cantidadtickets);

  const [errors, setErrors] = useState({});
  
  const handleSubmit = async (e) => { 
    e.preventDefault();
    let validationErrors = {};

    if (formData.nombre === '') {
      validationErrors.nombre = t('errors.errorName');
    }
    if (formData.idcategoria === '') {
      validationErrors.idcategoria = t('errors.errorCategory');
    }
    if (formData.idubicacion === '') {
      validationErrors.idubicacion = t('errors.errorLocation');
    }
    if (formData.idtarifa === '') {
      validationErrors.idtarifa = t('errors.errorRate');
    }
    

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      ticket: {
        nombre: formData.nombre,
        apellido: formData.apellido,
        idubicacion: idUbicacionInt,
        idcategoria: idCategoriaInt,
        idtarifa: idTarifa,
        cantidad: cantidadTarifas,
        importe: importe
      },
      cantidad: cantidadTickets,
    };

    

    try { 
      const response = await api.eventos.createTicket(param.id, data);
      if (response.status === 'success') { 
        console.log('Datos enviados a la API:', data); 
        setEnviado(true);
        setTimeout(() => {
          history.push(`/eventos/${param.id}`);
        }, 4000);
      } 
      else { console.error('Error al crear el ticket:', response.error); 
      } 
    } catch (error) { 
      console.error('Error en la solicitud:', error.message); 
    } 
  };

  

  return (
    <>
      <Container fluid="sm" className="d-flex justify-content-center flex-wrap">
        <div>
          <div className="d-flex justify-content-center flex-wrap">
            <div className="body p-4 border border-dark-subtle mt-2" style={{width: '800px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderradius: '8px'}}>
              <Form onSubmit={handleSubmit} >
                <Row>
                  <Col md={6}>
                    <FormGroup floating>
                      <Input id="nombre" name="nombre" placeholder={t("ticket.name")} value={formData.nombre} onChange={handleChange}/>
                      <Label for="nombre">{t("tickets.name")}</Label>
                      {errors.nombre && <span className="text-danger">{errors.nombre}</span>}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup floating>
                      <Input id="apellido" name="apellido" placeholder={t("tickets.lastNameOptional")} value={formData.apellido} onChange={handleChange}/>
                      <Label for="apellido">{t("tickets.lastNameOptional")}</Label>
                      {errors.apellido && <span className="text-danger">{errors.apellido}</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup floating>
                      <Input id="idcategoria" name="idcategoria" type="select" value={formData.idcategoria} onChange={handleChange}>
                        <option value="" hidden>
                        {t("tickets.selectACategory")}
                        </option>
                        {categorias?.map((categoria) => (
                          <option key={categoria.id} value={categoria.id}>
                            {categoria.categoria}
                          </option>
                        ))}
                      </Input>
                      <Label for="">{t("tickets.categories")}</Label>
                      {errors.idcategoria && <span className="text-danger">{errors.idcategoria}</span>}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup floating>
                      <Input id="idubicacion" name="idubicacion" type="select" value={formData.idubicacion} onChange={handleChange}>
                        <option value="" hidden>
                        {t("tickets.selectALocation")}
                        </option>
                        {ubicaciones?.map((ubicacion) => (
                          <option key={ubicacion.id} value={ubicacion.id}>
                            {ubicacion.ubicacion}
                          </option>
                        ))}
                      </Input>
                      <Label for="">{t("tickets.locations")}</Label>
                      {errors.idubicacion && <span className="text-danger">{errors.idubicacion}</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup floating>
                      <Input id="idtarifa" name="idtarifa" type="select" value={formData.idtarifa} onChange={handleChange} >
                        <option value="" hidden>
                          {t("tickets.selectARate")}
                        </option>
                        {entradasTarifas?.map((tarifa) => (
                          <option key={tarifa.id} value={tarifa.id}>
                            {tarifa.concepto}
                          </option>
                        ))}
                      </Input>
                      <Label for="">{t("tickets.rates")}</Label>
                      {errors.idtarifa && <span className="text-danger">{errors.idtarifa}</span>}
                    </FormGroup> 
                  </Col>

                  <Col md={6}>
                    <InputGroup>
                      <Input 
                        name="cantidadtarifas" 
                        type="number"
                        min={1}
                        style={{height: '58px'}}
                        onChange={handleChange}
                        value={formData.cantidadtarifas}
                      />
                      {unidad && (
                        <InputGroupText>
                          {unidad}
                        </InputGroupText>
                      )}
                      
                    </InputGroup>
                  </Col>
                </Row>            
                <FormGroup floating >
                  <Input id="cantidadtickets" name="cantidadtickets" type="number" value={formData.cantidadtickets} onChange={handleChange} min={1}/>
                  <Label>{t("tickets.numberOfTickets")}</Label>
                </FormGroup>
              
                {/* Mostrar mensaje de éxito si enviado es true */}
                {enviado && ( 
                  <div className="mt-3">
                    <Alert>{t("tickets.ticketCreated")}</Alert>
                  </div>
                )}
                <div className="d-flex justify-content-end mt-4">
                  <Button color="secondary" className='mr-3' onClick={() => history.push("/eventos/"+ param.id)}>{t("button.cancel")}</Button>
                  <Button color="primary" type="submit">{t("tickets.generateTicket")}</Button>
                </div>
              </Form>
            </div>
            <div style={{width: '300px', height: 'fit-content', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderradius: '8px'}} className="ml-4 py-4 px-3 border border-dark-subtle mt-2">
              <h5 style={{textAlign: "center"}}>{t("tickets.summary")}</h5>
              <hr/>
              { tarifaSeleccionada && formData.cantidadtarifas > 0 && (
                <p>{tarifaSeleccionada} x {formData.cantidadtarifas} {unidad}</p>
              )}
              <div className="d-flex justify-content-between" style={{backgroundColor: '#ededed', padding: '4px 7px'}}>
              <h4 style={{margin: '0px'}}>{t("tickets.total")}:</h4>
              <h4 style={{margin: '0px'}}>
                {importe === 0 || !tarifaSeleccionada || !formData.cantidadtarifas
                  ? '0'
                : 
                  `$${importe}`
                }  
              </h4>
            </div>
          </div>
        </div>
        <div style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderradius: '8px'}} className=" py-4 px-3 border border-dark-subtle mt-4">
          <h>{t("tickets.attention")}</h>
          <div className="d-flex flex-column">
            <p>{t("tickets.import")}</p>
            <Button className="align-self-end" color="success" onClick={() => history.push("/eventos/"+param.id+"/nuevoTicket/importarExcel") }>{t("tickets.upload")}</Button>
          </div>
        </div>
        </div>
      </Container>
      
    </> 
  )
}


