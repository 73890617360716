import React, { useState } from 'react';
import { Alert, Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardTitle, Collapse, Container, Spinner } from 'reactstrap';
import useCarrito from '../../hooks/hookCarrito';
import { faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api } from '../../services/api';

const CarritoCompra = () => {
  const { carrito, itemsCarrito, loadingItem, loadingCarrito, getCarrito, actualizarCantidad, eliminarItem } = useCarrito();
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [loadingMP, setLoadingMP] = useState(false);
  // Calcular el total
  const total = itemsCarrito?.reduce((acc, item) => acc + item.tarifa.precio * item.cantidad, 0);
  
  const handleActualizarCantidad = async (idtarifa, delta) => {
    const item = itemsCarrito.find(item => item.idtarifa === idtarifa);

    if (item) {
      const nuevaCantidad = item.cantidad + delta;

      // Aseguramos que la nueva cantidad no sea negativa
      if (nuevaCantidad < 1) return;

      await actualizarCantidad(idtarifa, nuevaCantidad);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoadingMP(true);

      const idcarrito = parseInt(carrito?.id);
      
      const response = await api.mercadopago.createPago({
        idcarrito: idcarrito,
      });
      
      if (response.status === "success") {
        window.location = response.data.init_point;
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingMP(false);
    }
  }
  
  return (
    <Container className="container-fluid md-container d-flex flex-wrap justify-content-center flex-md-nowrap gap-3 p-4">
      <Card className="col-12 col-md-8 p-0">
        <CardHeader className="py-4" tag="h6">
          {carrito?.evento ? ("Vendido por " + carrito?.evento?.evento) : "Carrito de compras"}
        </CardHeader>
        
        {itemsCarrito?.map(c => (
          <CardBody key={c.id} className="border-bottom position-relative">
            <a className="position-absolute top-0 end-0 px-1"
              style={{cursor: 'pointer'}}
              onClick={() => eliminarItem(c.id)}
            >
              {loadingItem[c.id]
                ?
                <Spinner color="primary" size="sm" />
                :
                <FontAwesomeIcon icon={faXmark} size="lg" />
              }
            </a>
            <div className="d-flex gap-2">
              <CardTitle tag="h5">
                {c.tarifa.concepto}
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted">
                <small>({c.tarifa.unidad})</small>
              </CardSubtitle>
            </div>
            <ButtonToolbar className="justify-content-between">
              <ButtonGroup>
                <Button color="secondary" outline
                  disabled={loadingItem[c.idtarifa]}
                  style={{ width: '40px' }}
                  onClick={() => handleActualizarCantidad(c.idtarifa, -1)}
                >
                  -
                </Button>
                <Button color="secondary" outline disabled style={{ minWidth: '40px' }}>
                  {loadingItem[c.idtarifa] ? <Spinner color="primary" size="sm" /> : c.cantidad}
                </Button>
                <Button color="secondary" outline
                  disabled={loadingItem[c.idtarifa]}
                  style={{ width: '40px' }}
                  onClick={() => handleActualizarCantidad(c.idtarifa, +1)}
                >
                  +
                </Button>
              </ButtonGroup>
              <span className="fs-5 align-self-end">
                $ {c.tarifa.precio * c.cantidad}
              </span>
            </ButtonToolbar>
          </CardBody>
        ))}
        {itemsCarrito.length === 0 &&
          <CardBody>
            El carrito esta vacío...
          </CardBody>
        }
        
        <CardFooter>
          <p className="text-end m-0" onClick={toggle}>
            Metodos de pago <FontAwesomeIcon icon={faChevronUp} />
          </p>
          <div className="d-flex justify-content-end">
            <Collapse isOpen={isOpen} className="my-2" style={{ transition: 'height 0.4s ease-in-out', opacity: '0.4s ease-in-out' }}>
              <Button
                color="info"
                disabled={itemsCarrito.length === 0}
                className="border border-dark text-white fw-bold"
                onClick={handleSubmit}
              >
                {loadingMP ? "Redirigiendo..." : "Mercado Pago"}
              </Button>
              <Button color="warning" disabled className="border border-dark fw-bold ms-3">
                <s>Binance</s>
              </Button>
            </Collapse>
          </div>
        </CardFooter>
      </Card>

      <Card className="col-12 col-md-4 p-0" style={{height: '10rem'}}>
        <CardHeader className="py-4" tag="h6">
          Resumen de compra
        </CardHeader>

        <CardBody className="d-flex flex-column justify-content-end gap-2">
          <small>Productos ({itemsCarrito?.length})</small>
          <div className="d-flex justify-content-between">
            <p className="fs-5 fw-bold">Total</p>
            <p className="fs-5 fw-bold">$ {total?.toFixed(2)}</p>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

export default CarritoCompra;