import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';


const VolverButton = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation("global");

  const handleClick = () => {
    history.goBack();
  };

  return (
    <button className="btn btn-primary d-flex align-items-center gap-2" style={{margin: '5px', backgroundColor:"#141A2C", border: "1px solid #141A2C"}} onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} size="lg" /> {t("button.back")}
    </button>
  )
}
  
export default VolverButton;
