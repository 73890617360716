import React, { Component } from "react";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import contentData from "../utils/contentData";

import Typewriter from "../utils/contentData";

class Content extends Component {
  render() {
    return (
      <div className="next-steps p-2" style={{backgroundColor:"#ffcf00"}}>
        <h2 className="my-2 pt-1 text-center">Bienvenido</h2>
        <h3 className="text-center">Header en construcción</h3>
        <div className="mb-2 text-center">
        <img alt="construccion"
        className="rounded"
        style={{ height: "250px"}}
        src="https://t3.ftcdn.net/jpg/03/53/83/92/360_F_353839266_8yqhN0548cGxrl4VOxngsiJzDgrDHxjG.jpg"></img>
      </div>
       <Typewriter text= "AQQUÍ COLOCARÍA EL HEADER... SI TUVIERA UNO"/>
      </div>
/*             <div className="next-steps my-5">
            <h2 className="my-5 text-center">Que se necesita ?</h2>
            <Row className="d-flex justify-content-between">
              {contentData.map((col, i) => (
                <Col key={i} md={5} className="mb-4">
                  <h6 className="mb-3">
                    <a href={col.link}>
                      <FontAwesomeIcon icon="link" className="mr-2" />
                      {col.title}
                    </a>
                  </h6>
                  <p>{col.description}</p>
                </Col>
              ))}
            </Row>
          </div> */
    );
  }
}

export default Content;
