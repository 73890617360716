import { useState, useEffect } from 'react'
import { FormGroup, Input, FormText, Alert, Table, Button } from "reactstrap";
import * as XLSX from 'xlsx';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';

export const ExcelImportVouchers = () => {
    const [typeError, setTypeError] = useState(null);
    const [excelData, setExcelData] = useState(null);
    const param = useParams();
    const history = useHistory();

    const [tarifas, setTarifas] = useState();
    const [state, setState] = useState({
        showResult: false,
        apiMessage: "",
        error: null,
    });
    const [enviado, setEnviado] = useState(false);
    const [t] = useTranslation("global");
    
    useEffect (() => {
        const fetchTarifa = async () => {
            try {
                let totalTarifas = [];
                let currentPage = 1;
                let hasMoreTickets = true;
                let filtro = '';
          
                while (hasMoreTickets) {
                    const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
                    if (response.status === "success") {
                        const tarifas  = response.data.tarifas; 
    
                        if (tarifas && tarifas.length > 0) {
                            totalTarifas = [...totalTarifas, ...tarifas];
                            currentPage++;
                        } else {
                            hasMoreTickets = false;
                        }
                    }
                    setTarifas(totalTarifas)
                }    
            } catch (error) {
                setState({
                    ...state,
                    error: error.error,
                });
            }
        }
        fetchTarifa();
    },[param.id]);

    const voucherTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 2 );

    const procesarArchivoExcel = (e) => {
        const archivo = e.target.files[0];
        const filtro = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
        if(archivo) {
            if(archivo && filtro.includes(archivo.type)){
                setTypeError(null);
                leerArchivoExcel(archivo);
            }
            else {
                setTypeError('Por favor, asegúrese de que el archivo seleccionado sea de tipo Excel(.xlsx o .xls.)')
            }
        } 
    }

    const errores = [];
    const validaryGuardar = () => {
        if (!excelData) {
            setTypeError('Por favor, cargue un archivo Excel válido antes de enviar.');
            return;
        }
    
        let errores = [];
        const valido = excelData.every((row, index) => {
            console.log(`Validando fila ${index + 1}:`, row); 

            const tarifaValida = voucherTarifas.some(tarifa => {
                console.log(`Comparando tarifa: "${tarifa.concepto}" con row.nametarifa: "${row.nametarifa}"`);
                return tarifa.concepto && row.nametarifa && tarifa.concepto.trim() === row.nametarifa.trim();
            });

            const idEventoValido = row.idevento === parseInt(param.id, 10);
            console.log(`Comparando idevento: "${row.idevento}" con param.id: "${param.id}" - Result: ${idEventoValido}`);

            if (!tarifaValida) {
                errores.push(`Tarifa inválida en fila ${index + 1}: ${row.nametarifa}`);
            }
            if (!idEventoValido) {
                errores.push(`ID de evento inválido en fila ${index + 1}: ${row.idevento}`);
            }
            return tarifaValida && idEventoValido;
        });

        if (!valido) {
            console.log('Errores de validación:', errores);
            setTypeError(`Los datos del archivo Excel contienen errores:\n${errores.join('\n')}`);
            return;
        }
        guardarVouchers();
    };

    const guardarVouchers = async () => {
        try {
            const response = await api.vouchers.createVouchersExcel(param.id, excelData);
    
            if (response.status === 'success') { 
                console.log('Vouchers creados exitosamente:');
                setEnviado(true);
                setTimeout(() => {
                history.push(`/eventos/${param.id}`);
            }, 4000); 
            }
            else { console.error('Error al crear voucherst:', response.error); 
            }
        } catch (error) {
            console.error('Error al intentar guardar los vouchers:', error.message);
        }
    };

    const leerArchivoExcel = (archivo) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
    
        
            const cleanedJson = jsonData.map(row => {
                const cleanedRow = {};
                for (const key in row) {
                    let value = String(row[key]).replace(/\t/g, '').trim();
                    if (key === 'idevento') {
                        value = parseInt(value, 10);
                    }
                    if (key === 'cantidadtarifa') {
                        value = Number(value);
                    }
                    if (key === 'importe') {
                        value = parseFloat(value);
                    }
                    cleanedRow[key.trim()] = value;
                }
                return cleanedRow;
            });
            setExcelData(cleanedJson);
        };
        reader.readAsBinaryString(archivo);
    }
    console.log(excelData);


    return (
        <>
            <div className='container-sm'>
                <Alert color="info">
                    <h4 className='text-center'>{t("excelImport.attention")}</h4>
                    <p>{t("excelImport.explainVouchers")}</p>
                </Alert>
                <h5 className='mt-4'>{t("excelImport.format")}</h5>
                <Table responsive bordered borderless>
                    <thead className='text-center'>
                        <tr className="table-secondary">
                            <th>idevento</th>
                            <th>nombre</th>
                            <th>apellido</th>
                            <th>codigo</th>
                            <th>nametarifa</th>
                            <th>cantidadtarifa</th>
                            <th>importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">{param.id}</th>
                            <td>({t("excelImport.example")} Maria)</td>
                            <td>({t("excelImport.example")} Fabbro)</td>
                            <td>({t("excelImport.example")} SJBYMiD)</td>
                            <td>({t("excelImport.example")} Asado)</td>
                            <td>({t("excelImport.example")} 1)</td>
                            <td>({t("excelImport.example")} 10000)</td>
                        </tr> 
                     </tbody>
                </Table>
  
                <div className='especificaciones mt-4'>
                    <h5>{t("excelImport.specifications")}</h5>
                    <li>{t("excelImport.sp1")} {param.id}</li>
                    <li>{t("excelImport.sp6")} <a className="alert-link" href="" onClick={() => history.push(`/eventos/${param.id}/vouchers`)}>{t("excelImport.sp6-2")} </a></li>
                    <li>{t("excelImport.sp3")}</li>
                    <li>{t("excelImport.sp4")}</li>
                    <li>{t("excelImport.sp5")}</li>
                </div>
                <div className='formulario-ingreso mt-4'>
                    <h5>{t("excelImport.uploadFile")}</h5>
                    <FormGroup>
                        <Input id="exampleFile" name="file" type="file" accept=".xlsx, .xls" onChange={procesarArchivoExcel}/>
                        <FormText>{t("excelImport.extension")}</FormText>
                        {typeError && <Alert color="danger">{typeError}</Alert>}
                    </FormGroup>
                    {enviado && ( 
                        <div className="mt-3">
                            <Alert>{t("excelImport.succesCreated")}</Alert>
                        </div>
                    )}
                </div>
                <div className='opciones d-flex justify-content-end'>
                    <div>
                        <Button color="secondary" className='mr-3' onClick={() => history.push("/eventos/"+ param.id +"/nuevoTicket")}>{t("button.cancel")}</Button>
                    </div>
                    <div>
                        <Button color="primary" onClick={validaryGuardar}>{t("excelImport.create")}</Button>
                    </div>
                </div>
            </div>    
        </>
    )
}
