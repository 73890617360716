import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { config } from "../../services/config";
// import { ToastContainer, toast } from 'react-toastify';
import {toast} from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
import './toast.css'

const socket = io(config.URL_SOCKET,{
  reconnectionAttempts: 5,
  reconnectionDelay: 600000,
  reconnectionDelayMax: 600000,
}); // URL del servidor Socket.IO

function NotificacionComponent() {
  const [connected, setConnected] = useState(socket.connected); // el connected verifica si se establecio bien la conexion
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // escuchar el evento de conexion
    socket.on('connect', () => {
      setConnected(true); // cambiar el estado a conectado. si es que se conecta
      setError(null)
      console.log('Conectado al servidor Socket.IO');
    });

    // manejar errores de conexion
    socket.on('connect_error', (err) =>{
      console.log('Error de conexion: ', err);
      setError('No se pudo conectar al servidor..')
    })

    // manejar desconexion
    socket.on('disconnect', () => {
      console.log('Desconectado del servidor..')
      setConnected(false)
    })

    socket.on('nueva-notificacion', (notification)=> {
      console.log('Notificacion recibida: ', notification);
      setNotifications(prevNotifications => [...prevNotifications, notification])
    
     //Mostrar toast al recibir una notificacion al assignedto
      toast.success(`${notification.titulo} ${notification.descripcion}`, {
      position: 'top-right',
      autoClose: 4000, 
    });
  })


    // limpiar la conexion al desmontar el componente
    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('disconnect')
      socket.off('nueva-notificacion');
    };
  }, []);

  return null;

  //ESTE JSX QUEDA PARA PRUEBAS NOMAS SI SE NECESITA MAS ADELANTE:
  // return (
  //   <div>
  //      {/* <ToastContainer /> */}
  //     <h1>Cliente Socket.IO (prueba)</h1>
  //     {connected ? (
  //       <p style={{ color: 'green' }}>Conectado al servidor!</p>
  //     ) : error ? (
  //       <p style={{color: 'red'}}> {error} </p>
  //     ):(
  //       <p style={{ color: 'orange' }}>Conectando al servidor...</p>
  //     )}

  //     <div>
  //       <h4>Notificaciones</h4>
  //       <ul>
  //         {notifications.length > 0 ? (
  //           notifications.map((notification, i) => (
  //             <li key={i}>{notification.titulo}</li>
  //           ))
  //         ) : (
  //           <p>No hay notificaciones.</p>
  //         )}
  //       </ul>
  //     </div>
  //   </div>
  // );
}

export default NotificacionComponent;