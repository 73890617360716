import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, CardText, Carousel, CarouselItem, CarouselControl, Container, Spinner, } from "reactstrap";
import { api } from "../../services/api";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import '../publicaciones/ads.css';
import moment from 'moment-timezone';
import ShareButton from "../shareButton/shareButton";
import { useMediaQuery } from "react-responsive";

moment.locale('es');
moment.locale('en-GB');

const translations = {
  Deportivos: {
    es: "Deportivos",
    en: "Sports",
  },
  Beneficios: {
    es: "Beneficios",
    en: "Profits",
  },
  Recepciones: {
    es: "Recepciones",
    en: "Social events",
  },
};

const translateCategory = (category, language) => {
  return translations[category] ? translations[category][language] : category;
};

const AdsDetail = ({ className, publicacion, onTipoEventoChange, onFechaEventoChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();
  const param = useParams();
  const [animating, setAnimating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tipoEvento, setTipoEvento] = useState("");
  const [fechaEvento, setFechaEvento] = useState("")
  const [tipoEventoId, setTipoEventoId] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const { i18n, t } = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.eventos.getPublic(publicacion.idevento);
        if (response.status === "success") {
          setFechaEvento(response.data.evento.fecha);
          setTipoEventoId(response.data.evento.eventos_tipos_id);
          const category = response.data.evento.evento_tipo.evento_tipo;
          setTipoEvento(translateCategory(category, i18n.language));
          onTipoEventoChange(publicacion.idevento, category);
          onFechaEventoChange(publicacion.id, response.data.evento.fecha);
        } else {
          console.error("Error fetching evento: ", response.message);
        }
      } catch (error) {
        console.error("Error: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [publicacion.idevento, i18n.language]);

  moment.locale(i18n.language === "es" ? "es" : "en-gb");

  const formatFecha = (fecha) => {
    return i18n.language === 'es'
      ? moment(fecha).clone().local().format("dddd d [de] MMMM")
      : moment(fecha).clone().local().format("dddd, MMMM d");
  };

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === publicacion.attachs?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? publicacion.attachs?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };


  const slides = publicacion.attachs?.map((attach, index) => (
    <CarouselItem key={index}>
      {attach.url.includes("video") ? (
         <video
         src={attach.url}
         controls
         className="w-100 object-fit-cover rounded-top"
         style={{
           height:"180px",
           objectPosition: "center",
         }}
       />
      ) : (
       <img
          src={attach.url}
          alt={publicacion.titulo}
          className="w-100 rounded-top"
          style={{ height: "180px" }}
        />
      )}

    </CarouselItem>
  ));

  if (loading) {
    return (
      <Container
        fluid
        className={`my-4 ${className}`}
        style={{ width: "350px", height: "auto" }}
      >
        <Card className="card-indiv border-0" style={{ height: "100%", boxShadow: "0px 5px 14px -6px black !important" }}>
          <CardBody
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Spinner color="primary" />
          </CardBody>
        </Card>
      </Container>
    );
  }

  return (
    <>
      {isMobile ? (
        <div>
          <Container className={`my-4 ${className}`}>
            <Card className="card-indiv border-0" style={{ height: "100%" }}>
              {publicacion.attachs && publicacion.attachs.length > 0 && (
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  className="mb-0"
                  slide={false}
                >
                  {slides}
                  {publicacion.attachs && publicacion.attachs.length > 1 && (
                    <>
                      <CarouselControl
                        direction="prev"
                        directionText="Anterior"
                        onClickHandler={previous}
                      />
                      <CarouselControl
                        direction="next"
                        directionText="Siguiente"
                        onClickHandler={next}
                      />
                    </>
                  )}
                </Carousel>
              )}
              <CardBody className="d-flex flex-column justify-content-around">
                <div className="align-items-center mb-2">
                  <div className="d-flex align-items-center">
                    <CardTitle style={{ fontSize: "18px", fontWeight: "bold" }} className="text-start mt-1 text-uppercase">
                      {publicacion.titulo}
                    </CardTitle>
                  </div>
                  <div className="d-flex align-items-center">
                    <p style={{ color: "#ADADAD", fontWeight: "bold" }} className=" mb-0 ">

                      {i18n.language === 'es' ? (
                        moment(fechaEvento).clone().local().format("D [de] MMMM YYYY")
                      ) : (
                        moment(fechaEvento).clone().local().format("MMMM D[,] YYYY")
                      )}

                    </p>
                  </div>
                </div>

                <CardText className="d-inline-block" style={{ maxWidth:"100%", marginBottom: "0", fontSize: "14px", /* whiteSpace:"nowrap", overflow: "hidden", textOverflow: "ellipsis", */}}>{publicacion.descripcion}</CardText>

                <div className="d-flex justify-content-end flex-wrap mt-1">
                  <div className="me-auto">
                    <ShareButton idEvento={publicacion.idevento} />
                  </div>
                  <div className="btn-card d-flex align-items-end">
                    <Button
                      outline
                      className="mr-2 btn-ad-more"
                      style={{ fontSize: "14px", border: "none" }}
                      onClick={() =>
                        history.push(`/evento/${publicacion.idevento}/informacion`)
                      }
                    >
                      {t("adsDetails.more")}
                    </Button>
                  </div>
                  {tipoEventoId === 3 ? (
                    <div className="btn-card d-flex align-items-end">
                      <Button
                        className="btn-ad"
                        style={{ backgroundColor: "#E4E4E4", color: "black", border: "none", fontSize: "15px", fontWeight: "bold" }}
                        onClick={() =>
                          history.push(`/eventos/${publicacion.idevento}/inscripcion`)
                        }
                      >
                        {t("adsDetails.register")}
                      </Button>
                    </div>
                  ) : (
                    <div className="btn-card d-flex align-items-end">
                      <Button
                        className="btn-ad"
                        style={{ backgroundColor: "#E4E4E4", color: "black", border: "none", fontSize: "14px", fontWeight: "bold" }}
                        onClick={() =>
                          history.push(`/evento/${publicacion.idevento}/informacion`)
                        }
                      >
                        {t("adsDetails.buy")}
                      </Button>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      ) : (
          <Container fluid className={`my-4 ${className}`}
            style={{ width: "350px", height: "auto" }}
          >
            <Card className="card-indiv border-0" style={{ height: "100%" }}>
              {publicacion.attachs && publicacion.attachs.length > 0 && (
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  className="mb-0"
                  slide={false}
                >
                  {slides}
                  {publicacion.attachs && publicacion.attachs.length > 1 && (
                    <>
                      <CarouselControl
                        direction="prev"
                        directionText="Anterior"
                        onClickHandler={previous}
                      />
                      <CarouselControl
                        direction="next"
                        directionText="Siguiente"
                        onClickHandler={next}
                      />
                    </>
                  )}
                </Carousel>
              )}
              <CardBody className="d-flex flex-column justify-content-around">
                <div className="align-items-center mb-2">
                  <div className="d-flex align-items-center">
                    <CardTitle style={{ fontSize: "19px", fontWeight: "bold" }} className="mt-1 text-uppercase">
                      {publicacion.titulo}
                    </CardTitle>
                  </div>
                  <div className="d-flex align-items-center">
                    <p style={{ color: "#ADADAD", fontWeight: "bold" }} className=" mb-0 ">

                      {i18n.language === 'es' ? (
                        moment(fechaEvento).clone().local().format("D [de] MMMM YYYY")
                      ) : (
                        moment(fechaEvento).clone().local().format("MMMM D[,] YYYY")
                      )}

                    </p>
                  </div>
                </div>

                <CardText className="text-truncate" style={{ width:"100%",marginBottom: "0", fontSize: "14px", whiteSpace:"nowrap", overflow: "hidden", textOverflow: "ellipsis", }}>{publicacion.descripcion}</CardText>

                <div className="d-flex justify-content-end flex-wrap mt-1">
                  <div className="me-auto">
                    <ShareButton idEvento={publicacion.idevento} />
                  </div>
                  <div className="btn-card d-flex align-items-end">
                    <Button
                      outline
                      className="mr-2 btn-ad-more"
                      style={{ fontSize: "14px", border: "none" }}
                      onClick={() =>
                        history.push(`/evento/${publicacion.idevento}/informacion`)
                      }
                    >
                      {t("adsDetails.more")}
                    </Button>
                  </div>
                  {tipoEventoId === 3 ? (
                    <div className="btn-card d-flex align-items-end">
                      <Button
                        className="btn-ad"
                        style={{ backgroundColor: "#E4E4E4", color: "black", border: "none", fontSize: "15px", fontWeight: "bold" }}
                        onClick={() =>
                          history.push(`/eventos/${publicacion.idevento}/inscripcion`)
                        }
                      >
                        {t("adsDetails.register")}
                      </Button>
                    </div>
                  ) : (
                    <div className="btn-card d-flex align-items-end">
                      <Button
                        className="btn-ad"
                        style={{ backgroundColor: "#E4E4E4", color: "black", border: "none", fontSize: "14px", fontWeight: "bold" }}
                        onClick={() =>
                          history.push(`/evento/${publicacion.idevento}/informacion`)
                        }
                      >
                        {t("adsDetails.buy")}
                      </Button>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Container>
      )}
    </>
  );
};

export default AdsDetail;
