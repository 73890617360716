import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input } from 'reactstrap';
import { api } from '../../services/api'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

const AddLocationModal = ({ evento, locations, callApi }) => {
  const [location, setLocation] = useState('');
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
const [loading, setLoading] = useState(false);
const [t, i18n] = useTranslation("global");

const toggle = () => {
    setModal(!modal);
    setError("");
    setLocation("")
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (location.trim() === '') {
        setError(<>{t("errors.emptyField")}</>);
     }else if (Array.isArray(locations) && locations !== undefined && locations.some(value => value.ubicacion === location)){
        setError(<>{t("errors.locationExist")}</>);
     } else if (location.length > 20) {
        setError(<>{t("errors.characters")}</>);
      }else {
             const newLocation = {
             idevento: evento.id,
             ubicacion: location,
            };
          try {
              const response = await api.locations.createLocation(newLocation);
              if (response.status === "success") {
              setLocation('')
              setError("")
              toggle();
              callApi();
             }
            } catch (error) {
                console.log(error);
                setLocation('')
                setError("")
            }
       }
    };
    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    )

  return (
    <div>
    <Button color="success" className="d-flex align-items-center gap-2" onClick={toggle}>
      <FontAwesomeIcon icon={faPlus} className="fs-5" /> {t("button.add")}
    </Button>
    <Modal isOpen={modal} toggle={toggle} style={{ zIndex: 9999, paddingTop:"80px"}}>
    <ModalHeader toggle={toggle} close={closeBtn}>{t("location.addLocation")}</ModalHeader>
      <ModalBody>
      <Form onSubmit={handleSubmit} >
              <FormGroup>
                <Input
                  id="location"
                  name="location"
                  placeholder={t("location.newLocation")}
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  style={{ borderColor: error ? 'red' : '' }}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </FormGroup>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color="secondary" style={{margin: "5px"}} onClick={toggle} disabled={loading}>
              {t("button.cancel")}
              </Button>
              <Button type="submit" style={{margin: "5px"}} color="primary">
              {t("button.add")}
              </Button>
              </div>
            </Form>
      </ModalBody>
    </Modal>
    </div>
  );
};

export default AddLocationModal;