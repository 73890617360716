import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input } from 'reactstrap';
import { api } from '../../services/api'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
const AddCategoryModal = ({ evento, categorias, callApi }) => {
  const [categoria, setCategoria] = useState('');
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [t] = useTranslation("global");

const toggle = () => {
    setModal(!modal);
    setError("");
    setCategoria("")
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (categoria.trim() === '') {
        setError(<>{t("errors.emptyField")}</>);
     }else if (Array.isArray(categorias) && categorias !== undefined && categorias.some(value => value.categoria === categoria)){
        setError(<>{t("errors.locationExist")}</>);
     } else if (categoria.length > 20) {
        setError(<>{t("errors.characters")}</>);
      }else {
             const newCategory = {
             idevento: evento.id,
             categoria: categoria,
            };
          try {
              const response = await api.categorias.createCategoria(newCategory);
              if (response.status === "success") {
              setCategoria('')
              setError("")
              toggle();
              callApi();
             }
            } catch (error) {
                console.log(error);
                setCategoria('')
                setError("")
            }
       }
    };
    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    )

  return (
    <div>
    <Button color="success" className="d-flex align-items-center gap-2" onClick={toggle}>
      <FontAwesomeIcon icon={faPlus}  className="fs-6" /> {t("button.add")}
    </Button>
    <Modal isOpen={modal} toggle={toggle} style={{ zIndex: 9999, paddingTop:"80px"}}>
    <ModalHeader toggle={toggle} close={closeBtn}>{t("categorie.addCategorie")}</ModalHeader>
      <ModalBody>
      <Form onSubmit={handleSubmit} >
              <FormGroup>
                <Input
                  id="location"
                  name="location"
                  placeholder={t("categorie.newCategorie")}
                  value={categoria}
                  onChange={(e) => setCategoria(e.target.value)}
                  style={{ borderColor: error ? 'red' : '' }}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </FormGroup>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color="secondary" style={{margin: "5px"}} onClick={toggle}>
              {t("button.cancel")}
              </Button>
              <Button type="submit" style={{margin: "5px"}} color="primary">
                {t("button.add")}
              </Button>
              </div>
            </Form>
      </ModalBody>
    </Modal>
    </div>
  );
};

export default AddCategoryModal;