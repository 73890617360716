import React, {  useEffect, useRef, useState } from "react";
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

export const CrearPublicacion = () => {
    const { id: eventoId } = useParams();
    const history = useHistory();
    const [formData, setFormData] = useState({
        eventoId: "",
        titulo: "",
        subtitulo: "",
        descripcion: "",
        desde: "",
        hasta: "",
    });
    const [error, setError] = useState("");
    const [t] = useTranslation("global");
    const refSeccion = useRef({});
    const [files, setFiles] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [mediaUrls, setMediaUrls] = useState([]);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [mediaFormats, setMediaFormats] = useState([]);
    const [mediaId, setMediaId] = useState([]);
    const fileInputRef = useRef(null);
    const [fileNames, setFileNames] = useState([]);
    const [fechaEvento, setFechaEvento] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = async (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
        setFileNames(selectedFiles.map(file => file.name));

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("files", file);
        });

        try {
            const response = await api.media.upload(formData);
            if (response.status === "success") {
                const medias = response.data.media;
                setMediaUrls(medias.map((media) => media.url));
                setMediaTypes(medias.map((media) => media.type));
                setMediaFormats(medias.map((media) => media.format));
                setMediaId(medias.map((media) => media.public_id));
            }
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    const deleteMedia = async (index) => {
        const publicId = mediaId[index];

        try {
            const response = await api.media.delete({ public_id: publicId });
            if (response.status === "success") {
                setFileNames(fileNames.filter((_, i) => i !== index));
                const updatedMediaUrls = mediaUrls.filter((_, i) => i !== index);
                const updatedMediaTypes = mediaTypes.filter((_, i) => i !== index);
                const updatedMediaFormats = mediaFormats.filter((_, i) => i !== index);
                const updatedMediaId = mediaId.filter((_, i) => i !== index);

                setMediaUrls(updatedMediaUrls);
                setMediaTypes(updatedMediaTypes);
                setMediaFormats(updatedMediaFormats);
                setMediaId(updatedMediaId);

                const updatedFiles = files.filter((_, i) => i !== index);
                setFiles(updatedFiles);

            }
        } catch (error) {
            console.error("Error deleting media:", error);
        }
    };

    useEffect(() => {
    const fetchEvento = async() =>{
        try{
            const response = await api.eventos.getPublic(eventoId);
            if (response.status === "success"){
                setFechaEvento(response.data.evento.fecha);
            }
        } catch (error) {
            console.error("Error deleting media:", error);
        }
    };

    fetchEvento();
}, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        /*    setError("");
           setSubmitted(false); */
        if (formData.titulo.length < 5 || formData.titulo.length > 50) {
            setError(t("publications.titleError"));
            return;
        }

        if (formData.subtitulo.length < 5 || formData.subtitulo.length > 50) {
            setError(t("publications.subtitleError"));
            return;
        }

        if (formData.descripcion.length < 5 || formData.descripcion.length > 240) {
            setError(t("publications.descripError"));
            return;
        }

        if (!formData.desde || !formData.hasta) {
            setError(t("publications.dateError"));
            return;
        }
        if (formData.hasta > fechaEvento){
            setError("La fecha de finalización no puede ser mayor a la fecha de realización del evento");
            return;
        }

        const desdeFormatted = moment.tz(formData.desde, "America/Buenos_Aires").format("YYYY-MM-DDT00:00:00-03:00");
        const hastaFormatted = moment.tz(formData.hasta, "America/Buenos_Aires").format("YYYY-MM-DDT00:00:00-03:00");


        const data = {
            idevento: parseInt(eventoId),
            titulo: formData.titulo,
            subtitulo: formData.subtitulo,
            descripcion: formData.descripcion,
            desde: desdeFormatted,
            hasta: hastaFormatted,
            attachs: mediaUrls.map((url, index) => ({
                url: url,
                type: mediaTypes[index],
                format: mediaFormats[index],
                public_id: mediaId[index],
            })),
        };

        try {
            const response = await api.publicaciones.createPublicacion(data);
            if (response.status === "success") {
                setFormData({
                    eventoId: "",
                    titulo: "",
                    subtitulo: "",
                    descripcion: "",
                    desde: "",
                    hasta: "",
                });
                setMediaUrls("");
                setMediaTypes("");
                setMediaFormats("");
                setMediaId("");
                setError(null);
                setSubmitted(true);
                setTimeout(() => {
                    history.goBack();
                }, 1500);
            } else {
                setError(<>{t("publications.error1")}</>);
            }
        } catch (error) {
            setError(<>{t("publications.error2")}</>);
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px", padding: "0 10px" }}>
            <div style={{ maxWidth: "600px", width: "100%" }}>
                <h2 className="mb-4">{t("publications.createPublication")}</h2>
                <div>
                    <Form onSubmit={handleSubmit} className="mt-4">
                        <FormGroup>
                            <p className="label-open-card">Título de la publicación</p>
                            <Input
                                type="text"
                                id="titulo"
                                name="titulo"
                                minLength={5}
                                maxLength={50}
                                value={formData.titulo}
                                onChange={handleInputChange}
                               /*  placeholder="Título" */
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <p className="label-open-card">Subtitulo de la publicacion</p>
                            <Input
                                type="text"
                                id="subtitulo"
                                name="subtitulo"
                                minLength={5}
                                maxLength={50}
                                value={formData.subtitulo}
                                onChange={handleInputChange}
                                /* placeholder="Subtitulo" */
                            />
                        </FormGroup>

                        <FormGroup>
                            <p className="label-open-card">Descripción</p>
                            <p className="txt-open-card">Ingrese una descripcion concisa y atrayente para su publicacion</p>
                            <Input
                                style={{ height: "60px", resize: "none" }}
                                type="textarea"
                                id="descripcion"
                                name="descripcion"
                                minLength={5}
                                maxLength={98}
                                value={formData.descripcion}
                                onChange={handleInputChange}
                               /*  placeholder="Descripción" */
                                required
                            />
                        </FormGroup>

                        <div className="mb-3">
                            <p className="label-open-card">Periodo de la publicación</p>
                            <p className="txt-open-card text-justify">Selecciona las fechas en las que deseas que tu publicación sea visible.
                                La publicación comenzará a mostrarse en la fecha de inicio y dejará de estar disponible después de la fecha de finalización.</p>
                            <div className="d-flex flex-row gap-3 flex-wrap mt-1">
                                <FormGroup floating>
                                    <Input
                                        id="desde"
                                        name="desde"
                                        placeholder={t("publications.startDate")}
                                        type="date"
                                        value={formData.desde}
                                        onChange={handleInputChange}
                                        required
                                        style={{ width: "200px" }}
                                    />
                                    <Label>Fecha de inicio</Label>
                                </FormGroup>

                                <FormGroup floating>
                                    <Input
                                        id="hasta"
                                        name="hasta"
                                        placeholder={t("publications.endDate")}
                                        type="date"
                                        value={formData.hasta}
                                        onChange={handleInputChange}
                                        required
                                        style={{ width: "200px" }}
                                    />
                                    <Label>Fecha de finalización</Label>
                                </FormGroup>
                            </div>
                        </div>

                        <FormGroup>

                            <p className="label-open-card">Imagenes para tu publicacion</p>
                            <div className="inputFile">
                                <Input
                                    type="file"
                                    name="files"
                                    id="files"
                                    ref={fileInputRef}
                                    accept="image/*,video/*"
                                    multiple
                                    onChange={handleFileChange}
                                    placeholder={t("publications.addImages")}
                                    style={{ display: "none" }}
                                    required
                                />
                                <label className="" style={{ fontSize: "14px", cursor: "pointer", textAlign: "center", border: "2px dashed #C1C0C3", borderRadius: "5px", padding: "20px" }} htmlFor="files"><i className="material-symbols-outlined" style={{ fontSize: "35px", color: "#C1C0C3" }}>
                                    image
                                </i><br></br>{t("event.inputImage")}</label>
                                <div className="ul-input-img d-flex flex-wrap">
                                    <ul className="ul-img d-flex flex-wrap">
                                        {/* <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño recomendado de imagen: 19200x1280</li> */}
                                        <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño máximo de archivo: 15MB</li>
                                        <li style={{ fontSize: "10px", fontWeight: "normal" }}>Formatos admitidos de imagen: JPEG, PNG</li>
                                    </ul>
                                </div>
                                {files.length > 0 && (
                                    <div className="uploaded-media d-flex flex-wrap">
                                        {files.map((file, index) => {
                                            const fileURL = URL.createObjectURL(file);
                                            const isImage = file.type.startsWith("image/");
                                            const isVideo = file.type.startsWith("video/");

                                            return (
                                                <div className="">
                                                    <div key={index} className="media-preview d-flex align-items-start mt-4" style={{ maxW: "200px", height: "100px" }}>
                                                        {isImage && (
                                                            <img
                                                                src={fileURL}
                                                                alt={`Preview ${index}`}
                                                                className="object-fit-cover"
                                                                style={{
                                                                    width: "50px", height: "50px",
                                                                    objectPosition: "center",
                                                                }}
                                                            />

                                                        )}
                                                        {isVideo && (
                                                            <video
                                                                src={fileURL}
                                                                controls
                                                                className="object-fit-cover"
                                                                style={{
                                                                    width: "80px", height: "80px",
                                                                    objectPosition: "center",
                                                                }}
                                                            />
                                                        )}
                                                        <div className="content-preview">
                                                            <div className="text-preview">
                                                                <p className="title-preview">{fileNames[index] || `Archivo ${index + 1}`}</p>
                                                                <p className="title-preview">png</p>
                                                            </div>
                                                            <button className="btn-preview" type="button" onClick={() => deleteMedia(index)}>
                                                                <i className="material-symbols-outlined" style={{ color: "red", fontSize: "22px" }}>
                                                                    delete
                                                                </i>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            );

                                        })}
                                    </div>
                                )}
                            </div>
                        </FormGroup>
                        {error && <Alert color="danger">{error}</Alert>}
                        {submitted && (
                            <Alert color="success">
                                {t("publications.successCreatedAlert")}
                            </Alert>
                        )}
                        <div className="d-flex justify-content-end mb-4">
                            <Button type="success" color="success" onClick={handleSubmit}>
                                {t("button.create")}
                            </Button>{" "}
                        </div>
                    </Form>
                </div>
            </div >
        </div >
    );
};
