import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { PaginatorContext } from "../../providers/Paginator";
import { formatDistanceToNow } from "date-fns"; // Importar la dependencia instalada
import { es } from "date-fns/locale"; // Importar el locale español
import VolverButton from "../../components/VolverButton";
import { useTranslation } from "react-i18next";


export const Ventas = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const history = useHistory();
  const param = useParams();
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState();

  const {
    query,
    setQuery,
    totalLength,
    setTotalLength,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useContext(PaginatorContext);

  const [ventas, setVentas] = useState([]); // Almacenamiento de los registros
  const [showSearch, setShowSearch] = useState();
  //const [submitted, setSubmitted] = useState(false);

  const limitPages = 5; // Definimos cuantas paginas queremos mostrar a la vez
  const totalPages = Math.max(Math.ceil(totalLength / pageSize), 1); // Calculamos el total de paginas, en caso de ser < 1 que tome valor 1.
  let startPage = Math.max(currentPage - Math.floor(limitPages / 2), 1); // Calculamos la primera pagina a mostrar
  let endPage = Math.min(startPage + limitPages - 1, totalPages); // Calculamos la ultima pagina a mostrar
  // Ajustamos startPage en caso de q no alcance a mostrar las paginas asignadas al limitPages
  if (endPage - startPage + 1 < limitPages && startPage > 1) {
    startPage = Math.max(endPage - limitPages + 1, 1);
  }

  useEffect(() => {
    const callApi = async (id) => {
      setLoading(true);

      try {
        const response = await api.ventas.getAll(
          id,
          query,
          pageSize,
          currentPage
        );

        if (response.status === "success") {
          setVentas(response.data.ventas);
          
          if (response.data.totalDataSize) {
            setTotalLength(response.data.totalDataSize);
          }
          if (!ventas) {
            setTotalLength(0);
          }
        }
        //setSubmitted(false);
      } catch (error) {
        setState({
          ...state,
          error: error,
        });
      } finally {
        setLoading(false);
      }
    };

    callApi(param.id);
  }, [state, param.id, pageSize, currentPage, totalLength, query]);

  const paginationItems = [];
  for (let page = startPage; page <= endPage; page++) {
    paginationItems.push(
      <PaginationItem active={page === currentPage} key={page}>
        <PaginationLink onClick={() => handleChangePage(page)}>
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  }

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (venta) => {
    history.push(`/eventos/ventas/${venta.id}`);
  };

  /* const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setCurrentPage(1);
  }; */
  
  //BUSQUEDA
  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    setQuery("");
  };

  return (
    <Container fluid="sm">
      <form onSubmit={(e) => e.preventDefault()} className="d-flex justify-content-end">
        {showSearch ? (
          <div className="w-50 d-flex gap-2">
            <Input
              id="exampleSearch"
              name="q"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Buscar.."
              type="search"
            />
            <Button onClick={() => setShowSearch(false)} color="light">
              <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
            </Button>
          </div>
        ) : (
          <Button color="secondary" onClick={handleToggleSearch}>
            <FontAwesomeIcon icon={faSearch} className="fs-6" />
          </Button>
        )}
      </form>

      {state.error && (
        <Alert color="danger" className="container">
          {state.error}
        </Alert>
      )}

      <Table hover responsive striped size="sm" className="mt-3">
        <thead>
          <tr className="table-light">
            <th className="text-center">#</th>
            <th>{t("sales.buyer")}</th>
            <th>{t("sales.initDate")}</th>
            <th>{t("sales.payDate")}</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={4} align="center">
                <Spinner color="primary" />
              </td>
            </tr>
          ) : (
            ventas ? (
              ventas.map((venta) => (
                <tr
                  key={venta.id}
                  onClick={() => handleRowClick(venta)}
                  style={{ cursor: "pointer" }}
                >
                  <th scope="row" className="text-center">{venta.id}</th>
                  
                  <td>{venta.email}</td>
  
                  <td>
                    {formatDistanceToNow(new Date(venta.fecha), {
                      addSuffix: true,
                      locale: es,
                    })}
                  </td>
  
                  <td className={
                    venta.atendido === null
                      ? "text-warning fw-bold"
                      : ""
                    }
                  >
                    {venta.atendido !== null
                      ? formatDistanceToNow(new Date(venta.atendido), {
                          addSuffix: true,
                          locale: es,
                        })
                      : t("sales.pending")
                    }
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>
                  <Alert color="secondary" className="m-0">
                    {t("sales.saleAlert")}
                  </Alert>
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
      <Row>
        <Col xs="auto">
          <small className="text-left">
          {t("sales.totalEntries")} {totalLength}
          </small>
        </Col>
        <Col xs="auto" className="mx-auto">
          <Pagination>
            <PaginationItem disabled={currentPage <= 1}>
              <PaginationLink
                first
                href=""
                onClick={() => handleChangePage(1)}
              />
            </PaginationItem>
            <PaginationItem disabled={currentPage <= 1}>
              <PaginationLink
                previous
                href=""
                onClick={() => handleChangePage(currentPage - 1)}
              />
            </PaginationItem>

            {paginationItems}

            <PaginationItem disabled={currentPage >= totalPages}>
              <PaginationLink
                next
                href=""
                onClick={() => handleChangePage(currentPage + 1)}
              />
            </PaginationItem>

            <PaginationItem disabled={currentPage >= totalPages}>
              <PaginationLink
                last
                href=""
                onClick={() => handleChangePage(totalPages)}
              />
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <VolverButton />
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(Ventas, {
  onRedirecting: () => <Loading />,
});
