import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Alert, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { api } from '../../services/api';
import VolverButton from '../../components/VolverButton';
import { useTranslation } from 'react-i18next';

function CuentaBancaria() {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const history = useHistory();
  const { goBack } = useHistory();
  const param = useParams();
  const [t] = useTranslation("global");

  const [editMode, setEditMode] = useState(false);
  const [cuenta, setCuenta] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [titular, setTitular] = useState("");
  const [dni, setDni] = useState("");
  const [cbu, setCbu] = useState("");
  const [alias, setAlias] = useState("");
  const [banco, setBanco] = useState("");

  useEffect(() => {
    if (cuenta) {
      setTitular(cuenta.titular);
      setDni(cuenta.dni);
      setCbu(cuenta.cbu);
      setAlias(cuenta.alias);
      setBanco(cuenta.banco);
    }
  }, [cuenta]);

  useEffect(() => {
    const callApi = async (id) => {
      try {
        const response = await api.eventoCuentas.get(id);

        if (response.status === "success") {
          setCuenta(response.data.eventoCuenta);
        }

      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red."
        });
      }
    }

    callApi(param.id);
  }, [state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditMode(false);
    const id = parseInt(param.id);

    const data = {
      id: id,
      idevento: cuenta.idevento,
      titular: titular,
      dni: dni,
      cbu: cbu,
      alias: alias,
      banco: banco
    }

    try {
      const response = await api.eventoCuentas.setEventoCuenta(id, data);
      
      if (response.status === "success") {
        setState({
          ...state,
          apiMessage: response.message
        });
      }

    } catch (error) {
      setState({
        ...state,
        error: "Error al aplicar los cambios"
      });
    }
  }

  const handleCancel = () => {
    setEditMode(false);
    setFormValid(false);
    setTitular(cuenta.titular);
    setDni(cuenta.dni);
    setCbu(cuenta.cbu);
    setAlias(cuenta.alias);
    setBanco(cuenta.banco);
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  }

  const confirmDelete = async (id) => {
    setIsModalOpen(false);

    try {
      const response = await api.eventoCuentas.deleteEventoCuenta(id);

      if (response.status === "success") {
        goBack();
      }

    } catch (error) {
      setState({
        ...state,
        error: "Error en la Red."
      });
    }
  }

  return (
    <div>

      {state.error &&
        <Alert color="danger" className="container">
          {state.error}
        </Alert>
      }
      
      {state.apiMessage &&
        <Alert color="success" className="container">
          {state.apiMessage}
        </Alert>
      }

      {cuenta &&
        <Form className="col-md-6 mt-3 mx-auto col-12" onSubmit={handleSubmit}>
          <div className="d-flex gap-3">
            <FormGroup className="w-100">
              <Label for="titular">
               {t("accountBank.accountHolder")}
              </Label>
              <Input
                id="titular"
                name="titular"
                type="text"
                value={titular}
                disabled={!editMode}
                onChange={(e) => {setTitular(e.target.value); setFormValid(true)} }
              />
            </FormGroup>

            <FormGroup className="w-100">
              <Label for="DNI">
                DNI
              </Label>
              <Input
                id="DNI"
                name="dni"
                type="number"
                value={dni}
                disabled={!editMode}
                onChange={(e) => {setDni(e.target.value); setFormValid(true)}}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <Label for="cbu">
              CBU
            </Label>
            <Input
              id="cbu"
              name="cbu"
              type="number"
              value={cbu}
              disabled={!editMode}
              onChange={(e) => {setCbu(e.target.value); setFormValid(true)}}
            />
          </FormGroup>

          <div className="d-flex gap-3">
            <FormGroup className="w-100">
              <Label for="alias">
                Alias
              </Label>
              <Input
                id="alias"
                name="alias"
                type="text"
                value={alias}
                disabled={!editMode}
                onChange={(e) => {setAlias(e.target.value); setFormValid(true)}}
              />
            </FormGroup>

            <FormGroup className="w-100">
              <Label for="banco">
                {t("accountBank.bank")}
              </Label>
              <Input
                id="banco"
                name="banco"
                type="text"
                value={banco}
                disabled={!editMode}
                onChange={(e) => {setBanco(e.target.value); setFormValid(true)}}
              />
            </FormGroup>
          </div>

          <div className="d-flex justify-content-between">
            <button className="btn btn-outline-danger" disabled={editMode}
              onClick={handleDelete} style={{margin: '5px'}}>
             {t("button.delete")} <FontAwesomeIcon icon={faTrash} />
            </button>

            <div className="d-flex justify-content-end gap-3">
              {!editMode ? (
                <>
                  <button className="btn btn-primary" onClick={() => setEditMode(true)} style={{margin: '5px'}}>
                  {t("button.edit")} <FontAwesomeIcon icon={faPenToSquare} />
                  </button>
                  
                  <VolverButton/>
                </>
              ) : (
                <>
                  <button className="btn btn-outline-primary" onClick={handleSubmit} disabled={!formValid} style={{margin: '5px'}}>
                  {t("button.save")} <FontAwesomeIcon icon={faFloppyDisk} />
                  </button>
                  <button className="btn btn-secondary" onClick={() => handleCancel()} style={{margin: '5px'}}>
                  {t("button.cancel")}
                  </button>
                </>
              )}
            </div>
          </div>
        </Form>
      }

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} centered>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
          Confirmar atención
        </ModalHeader>

        <ModalBody>
          ¿Estás seguro de desvincular esta cuenta bancaria?
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => confirmDelete(param.id)}>
            Confirmar
          </Button>

          <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default withAuthenticationRequired(CuentaBancaria, {
  onRedirecting: () => <Loading />,
});