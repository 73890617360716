import React, { createContext, useState, useEffect } from 'react';


export const PaginatorContext = createContext();

export const PaginatorProvider = ({ children }) => {
  const [query, setQuery] = useState("");             // Filtro por busqueda textual
  const [totalLength, setTotalLength] = useState(0);  // Cantidad de registros por pagina
  const [currentPage, setCurrentPage] = useState(1);  // Total de registros
  const [pageSize, setPageSize] = useState(10);       // Pagina activa
  const [categoriaSelected, setCategoriaSelected] = useState("");
  const [isToggled, setIsToggled] = useState(false);

  //estados para la vista impresion
  const [font, setOpenFont] = useState(false);
  const [fontToolBar, setOpenFontToolBar] = useState(false);
  const [image, setOpenImage] = useState(false);
  const [qrCanva, setOpenQr] = useState(false);
  const [templates, setOpenTemplates] = useState(false);
  const [index, setIndex] = useState(0);
  const [event, setEvent] = useState('')
  const [saved, setSaved] = useState(false)
  const [edited, setEdited] = useState(false)
  const [error, setError] = useState(false)

  //estados para el posicionamiento de los elementoss en un ticke
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });

  const [tipografia, setTipografia] = useState({
    roboto: 'Roboto', merriweather: 'Merriweather', pacifico: 'Pacifico', playfairDisplay: 'Playfair Display', oswald: 'Oswald', lobster: 'Lobster', anton: 'Anton', indieFlower: 'Indie Flower', abrilFatface: 'Abril Fatface', fjallaOne: 'Fjalla One'
  });
  const [selectedId, setSelectedId] = useState(null);
  const [tipografiaSelected, setTipografiaSelected] = useState('Roboto');
  const [sizeSelected, setSizeSelected] = useState(14);
  const [textCount, setTextCount] = useState(0);
  const [scale, setScale] = useState(1);

  const [isCreated, setIsCreated] = useState(false);

  const [textField, setTextField] = useState([]);
  const [qrField, setQrField] = useState([]);
  const [imgField, setImageField] = useState([])
  const [elements, setElements] = useState([]);
  const [isEditSucces, setEditSucces] = useState(false);

  const [dimensionContainer, setDimensionContainer] = useState({
    type: 'tarjeta',
    width: 100,
    height: 50,
  });
  const [textArea, setTextArea] = useState(false);
  const [history, setHistory] = useState([elements])
  const [indexHistory, setIndexHistory] = useState(0)
  const [ableUndo, setAbleUndo] = useState(false)
  const [ableRedo, setAbleRedo] = useState(false)

  const uploadHistory = (newElements) => {
    setAbleUndo(true)
    const newHistory = history.slice()
    if (indexHistory > 0) {
      newHistory.splice(history.length - indexHistory)
      setIndexHistory(0)
    }
    if(indexHistory===0){
      setAbleRedo(false)
    }
    if(indexHistory===history.length){
      setAbleUndo(false)
    }
    newHistory.push(newElements)
    setHistory(newHistory)
  }


  return (
    <PaginatorContext.Provider value={
      {
        query,
        setQuery,
        totalLength,
        setTotalLength,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        categoriaSelected,
        setCategoriaSelected,
        isToggled,
        setIsToggled,

        //vista impresion
        font,
        image,
        qrCanva,
        templates,
        setOpenFont,
        setOpenImage,
        setOpenQr,
        setOpenTemplates,

        setState,
        state,

        dimensionContainer,
        textField,
        setTextField,
        textArea,
        setTextArea,
        textCount,
        setTextCount,
        qrField,
        setQrField,
        index,
        setIndex,
        imgField,
        setImageField,
        tipografia,
        setTipografia,
        tipografiaSelected,
        setTipografiaSelected,
        sizeSelected,
        setSizeSelected,
        fontToolBar,
        setOpenFontToolBar,
        selectedId,
        setSelectedId,
        elements,
        setElements,
        isEditSucces,
        setEditSucces,
        isCreated,
        setIsCreated,
        scale,
        setScale,
        setDimensionContainer,
        event,
        setEvent,
        error,
        setError,
        saved,
        setSaved,
        edited,
        setEdited,
        history,
        setHistory,
        indexHistory,
        setIndexHistory,
        uploadHistory,
        ableUndo,
        setAbleUndo,
        ableRedo,
        setAbleRedo,

      }
    }>
      {children}
    </PaginatorContext.Provider>
  );
}