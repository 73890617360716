import { useContext, useState } from "react";
import { PaginatorContext } from "../../providers/Paginator";
import { Badge, Tooltip } from "reactstrap";
import SideBar from "./SideBar";
import AddLabel from "./AddLabel";

const QrSideBar = () => {
  const { elements, setElements, scale, dimensionContainer, index, setIndex, setOpenQr, event } = useContext(PaginatorContext);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const HanddleAddQr = () => {
    const qrGenerated = elements.slice();
    setIndex(index + 1)
    qrGenerated.push({
      id: index.toString(),
      x: ((dimensionContainer.width * scale) / 2) - 25,
      y: ((dimensionContainer.height * scale) / 2) - 25,
      width: 50,
      height: 50,
      type: 'qr',
    })
    setElements(qrGenerated)
  }

  const HandleAddVariable = (variableType) => {
    let textFill = ''
    if (variableType === "evento") {
      textFill = event
    }
    if (variableType === "codigo") {
      textFill = '012345678912'
    }
    if (variableType === "nombre") {
      textFill = 'Juan Pintos'
    }
    const textGenerated = elements.slice();
    setIndex(index + 1)
    textGenerated.push({
      id: index.toString(),
      x: ((dimensionContainer.width * scale) / 2) - 100,
      y: ((dimensionContainer.height * scale) / 2) - 25,
      text: textFill,
      fontSize: 14,
      rotation: 0,
      width: 137,
      height: 15,
      state: false,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      textDecoration: '',
      fill: '#000000',
      align: 'left',
      type: `text-${variableType}`,
    })
    setElements(textGenerated)
  }


  return (
    <>
      <SideBar>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className="p-0 m-0 fs-4">Variables Dinamicas</p>
          </div>
          <div>
            <Badge pill color="warning" className="m-0 p-0" id="DisabledAutoHideExample">
              <i className="material-symbols-outlined fs-6 m-1 p-0">question_mark</i>
            </Badge>
            <Tooltip
              placement="bottom"
              isOpen={tooltipOpen}
              autohide={false}
              target="DisabledAutoHideExample"
              toggle={toggle}
            >
              Estas variables se reemplazaran por los datos registrados cuando descargues el PDF. Los valores son representativos.
            </Tooltip>
          </div>
          <div>
            <button className="d-flex align-items-center" onClick={() => setOpenQr(false)}>
              <i className="material-symbols-outlined">close</i>
            </button>
          </div>
        </div>
        <div>
          <p className="fs-6">Las <b>variables dinamicas</b> son texto fijos que <b>no pueden</b> ser editados durante el diseño. Estos valores serán reemplazados por la información registrada en la base de datos</p>
        </div>
        <div className="d-flex flex-column gap-2">
          <AddLabel type={'text-nombre'}>Nombre y Apellido</AddLabel>
          <AddLabel type={'text-evento'}>Nombre del evento</AddLabel>
          <AddLabel type={'text-codigo'}>Codigo</AddLabel>
          <AddLabel type={'qr'}>Codigo QR</AddLabel>
        </div>
      </SideBar>
    </>
  )
};

export default QrSideBar;