import React, { useState } from "react";
import {
  ButtonGroup,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "./shareButton.css";
import facebook_icon from "../../assets/facebook_icon.svg";
import Twitter_icon from "../../assets/Twitter_icon.svg";
import whatsapp_icon from "../../assets/whatsapp_icon.svg";

const ShareButton = ({idEvento}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const location = useLocation();
  // const currentPath = location.pathname;
  //   const currentPath = "https://www.google.com";
const currentPath = `https://caar.ipsistemas.com.ar/evento/${idEvento}/informacion`
  const shareMessage = encodeURIComponent(
    "Hey! Mira este evento.. " + currentPath
  );

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="up">
      <DropdownToggle className="button-share">
        <i
          className="material-symbols-outlined align-items-center"
          style={{ fontSize: "22px" }}
        >
          share
        </i>
      </DropdownToggle>
      <DropdownMenu className="custom-dropdown-menu">
        <DropdownItem
          href={`https://www.facebook.com/sharer/sharer.php?u=` + currentPath}
          target="_blank"
          className="drop_item facebook"
        >
          <img className="rrss" src={facebook_icon} alt="fb"></img>
        </DropdownItem>
        <DropdownItem
          href={`https://api.whatsapp.com/send?text=` + shareMessage}
          target="_blank"
          className="drop_item-whatsapp"
        >
          <img className="rrss" src={whatsapp_icon} alt="wsp"></img>
        </DropdownItem>
        <DropdownItem
          href={`https://twitter.com/intent/tweet?url=` + currentPath}
          target="_blank"
          className="drop_item twitter"
        >
          <img className="rrss" src={Twitter_icon} alt="tw"></img>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ShareButton;
