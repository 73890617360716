import { useContext } from "react";
import { PaginatorContext } from "../../providers/Paginator";
import SideBar from "./SideBar";
import UseTemplate from "./UseTemplate"

const TemplateSideBar = () => {
  const { setOpenTemplates, dimensionContainer, setDimensionContainer, setScale, elements, setElements, event } = useContext(PaginatorContext)

  return (
    <>
      <SideBar>
        <div className='d-flex flex-column'>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div>
              <p className="p-0 m-0 fs-4">Plantillas</p>
            </div>
            <div>
              <button className="d-flex align-items-center" onClick={() => setOpenTemplates(false)}>
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <div>
            <p className="m-0 p-0 fs-5">Recomendaciones</p>
          </div>
        </div>
        <div className='w-100 d-flex flex-column gap-1'>
          <UseTemplate template={'pulsera'}>Plantilla de Pulsera</UseTemplate>
          <UseTemplate template={'tarjeta'}>Plantilla de Tarjeta</UseTemplate>
          <UseTemplate template={'voucher'}>Plantilla de Voucher</UseTemplate>
        </div>
      </SideBar>
      {/* <div
        style={{ width: '400px', transition: 'transform 0.3s ease', height: '100%' }}
        className='position-absolute bg-body-secondary start-0 w-full border rounded-3' >
        <div className="d-flex flex-column w-full gap-2 h-100">
          <div className="pt-2 px-2 d-flex justify-content-between align-items-center">
            <div>
              <p className="p-0 m-0 fs-4">Plantillas</p>
            </div>
            <div>
              <button className="d-flex align-items-center" onClick={() => setOpenTemplates(false)}>
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <div className="h-100 overflow-auto">
            <p className="text-center fs-5 pb-2 m-0">Diseños:</p>
            <div className="position-relative d-flex flex-column gap-1 h-100 px-2">
              <button className="btn btn-light rounded-3 h-35 p-3" onClick={() => HandlePulseraTemplate()}><p className="text-center p-0 m-0 align-middle fs-5 fw-normal">Plantilla de Pulsera</p></button>
              <button className="btn btn-light rounded-3 h-35 p-3" onClick={() => HandleTarjetaTemplate()}><p className="text-center p-0 m-0 align-middle fs-5 fw-normal">Plantilla de Tarjeta</p></button>
              <button className="btn btn-light rounded-3 h-35 p-3" onClick={() => HandleVoucherTemplate()}><p className="text-center p-0 m-0 align-middle fs-5 fw-normal">Plantilla de Voucher</p></button>
            </div>
          </div>

        </div>
      </div> */}
    </>
  )
};

export default TemplateSideBar;