import React, { useEffect, useState } from 'react';
import { Button, Label, Form, FormGroup, Input, Container } from 'reactstrap';
import { api } from '../../services/api'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import VolverButton from '../../components/VolverButton';
import { useHistory } from "react-router-dom";
import { TarifaDetalles } from "../../components/modales/TarifaDetalles"

const CrearTarifa = () => {
  const [precio, setPrecio] = useState('');
  const [concepto, setConcepto] = useState('');
  const [unidad, setUnidad] = useState('');
  const [tarifas, setTarifas] = useState('');
  const [tarifasTipo, setTarifasTipo] = useState([]);
  const [idTipo, setIdTipo] = useState('');
  const history = useHistory();
  const [precioError, setPrecioError] = useState("");
  const [unidadError, setUnidadError] = useState("");
  const [conceptoError, setConceptoError] = useState("");
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation("global");
  const param = useParams();

  const [asumirCostos, setAsumirCostos] = useState(false)

  useEffect(() => {
    callApi();
    callTipoTarifas();
  },[])

  const callApi = async () =>{
    try {
      const response = await api.tarifas.getAll(param.id);
      if(response.status === "success"){
        setTarifas(response.data.tarifas);
      }
    } catch (error) {
        console.log(error);
    }
  }

  const callTipoTarifas = async () =>{
    try {
      const response = await api.tarifas.GetAllTarifasTipos();
      if(response.status === "success"){
        setTarifasTipo(response.data.tarifasTipo);
      }
    } catch (error) {
        console.log(error);
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (precio.trim() === '') {
      setPrecioError(<>{t("errors.emptyField")}</>);
    }if (concepto.trim() === '') {
        setConceptoError(<>{t("errors.emptyField")}</>);
     } if (unidad.trim() === '') {
        setUnidadError(<>{t("errors.emptyField")}</>);
   } else if (concepto.length > 20) {
      setConceptoError(<>{t("errors.characters")}</>);
    } else if (unidad.length > 20) {
      setUnidadError(<>{t("errors.characters")}</>);
    }else if (Array.isArray(tarifas) && tarifas !== undefined && tarifas.some(value => value.concepto === concepto)){
      setConceptoError(<>{t("rates.conceptError")}</>);
    }else {

        const newTarifa = {
         idevento: parseInt(param.id),
         idmoneda: 1,
         concepto: concepto,
         unidad: unidad,
         precio: parseFloat(precio),
         idtipo: parseInt(idTipo)
            }
          try {
              const response = await api.tarifas.createTarifa(newTarifa);
              console.log(newTarifa);
              if (response.status === "success") {
              console.log("agregado correctamente")
              setPrecio('')
              setConcepto('')
              setUnidad('')
              setPrecioError("")
              setUnidadError("")
              setConceptoError("")
              setTimeout(() => {
                history.goBack();
              }, 1000);
             }
            } catch (error) {
                console.log(error);
                setPrecio('')
                setConcepto('')
                setUnidad('')
                setPrecioError("")
                setUnidadError("")
                setConceptoError("")
            }
       }}

      const handleCheckboxChange = (e) => {
        if (e.target.checked) {
          setAsumirCostos(true)
        } else {
          setAsumirCostos(false)
        }
      }
       

  return (
    <Container fluid="sm" >
      <Form className="col-md-6 mx-auto col-12" onSubmit={handleSubmit} >
        <FormGroup floating>
          <Input
            id="concepto"
            name="concepto"
            type="string"
            value={concepto}
            onChange={(e) => setConcepto(e.target.value)}
            style={{ borderColor: conceptoError ? 'red' : '' }}
            placeholder={t("rates.concept")}
          />
          <Label for="concepto">
            {t("rates.concept")}
          </Label>
          {conceptoError && <p style={{ color: 'red' }}>{conceptoError}</p>}
        </FormGroup>
        <FormGroup floating>
          <Input
            id="precio"
            name="precio"
            type="number"
            value={precio}
            min={1}
            onChange={(e) => setPrecio(e.target.value)}
            style={{ borderColor: precioError ? 'red' : '' }}
            placeholder={t("rates.price")}
          />
          <Label for="precio">
            {t("rates.price")}
          </Label>
          {precio > 0 && (
            <div className='d-flex justify-content-between'>
              <small style={{ color: 'gray' }}>
                {t("tickets.finalPrice")}: {asumirCostos ? parseFloat(precio).toFixed(2) : parseFloat(precio * 1.05).toFixed(2)}
              </small>
              <TarifaDetalles precio={Number(precio)} asumirCostos={asumirCostos} />
            </div>
          )}          
          {precioError && <p style={{ color: 'red' }}>{precioError}</p>}
          {precio > 0 && (
            <FormGroup check>
              <Input
                id="asumirCostos"
                name="asumirCostos"
                type="checkbox"
                onChange={handleCheckboxChange}
              />
              <Label check for="asumirCostos">
                {t("tickets.assumeTheCosts")}
              </Label>
            </FormGroup>
          )}
        </FormGroup>
        
        
        <FormGroup floating>
          <Input
            id="unidad"
            name="unidad"
            type="string"
            value={unidad}
            onChange={(e) => setUnidad(e.target.value)}
            style={{ borderColor: unidadError ? 'red' : '' }}
            placeholder={t("rates.unit")}
          />
          <Label for="unidadc">
            {t("rates.unit")}
          </Label>
          {unidadError && <p style={{ color: 'red' }}>{unidadError}</p>}
        </FormGroup>
        <FormGroup floating>
          <Input 
            id="idtipo" 
            name="idtipo" 
            type="select" 
            value={idTipo} 
            onChange={(e) => setIdTipo(e.target.value)}
            style={{ borderColor: unidadError ? 'red' : '' }}
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {tarifasTipo?.map((tarifa) => (
              <option key={tarifa.id} value={tarifa.id}>
                {tarifa.tipo}
              </option>
            ))}
          </Input>
          <Label for="tipo">
            {t("rates.type")}
          </Label>
          {unidadError && <p style={{ color: 'red' }}>{unidadError}</p>}
        </FormGroup>
        <Button type="submit" style={{margin: "5px"}} color="primary">
          {t("button.create")}
        </Button>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <VolverButton/>
      </div>
    </Container>
  );
};

export default CrearTarifa;