import React, { useState, useEffect } from "react";
import { Container, Card, Input, Form, Table, Badge, Alert, Button, Tooltip } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { api } from "../services/api";
import { GoCheckCircle } from "react-icons/go";
import { MdOutlineCancel } from "react-icons/md";
import "./Profile.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from 'react-i18next';
import ProfileMobile from "./ProfileMobile";
import { useMediaQuery } from "react-responsive";

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCalendarDays, faCartShopping, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const ProfileComponent = () => {
  const { user } = useAuth0();
  const [participaciones, setParticipaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [invitaciones, setInvitaciones] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [compras, setCompras] = useState([]);
  const [usuario, setUsuario] = useState();
  const history = useHistory();
  const [botonPerfil, setBotonPerfil] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');
  const { i18n } = useTranslation();
  const [t] = useTranslation("global");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTool = () => setTooltipOpen(!tooltipOpen);
  const [activeButton, setActiveButton] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
    direccion: "",
    ciudad: "",
    provincia: "",
    cp: "",
    pais: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [paises, setPaises] = useState([]);
  const [nombrePaisCompleto, setNombrePaisCompleto] = useState("");
  const [provincias, setProvincias] = useState([]);
  const [nombreProvCompleta, setNombreProvCompleta] = useState("");
  const [ciudades, setCiudades] = useState([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState("");
  const [provSeleccionada, setProvSeleccionada] = useState("");

  const callApi = async () => {
    try {
      const response = await api.usuarios.getUsuario();
      if (response.status === "success") {
        setUsuario(response.data.usuario);
        const invitacionesCliente = response.data.invitaciones.invitacionesCliente?.map(invitacion => ({
          ...invitacion,
          tipo: 'Cliente'
        })) ?? [];
        const invitacionesColaborador = response.data.invitaciones.invitacionesColaborador?.map(invitacion => ({
          ...invitacion,
          tipo: 'Colaborador'
        })) ?? [];

        const todasInvitaciones = [...invitacionesCliente, ...invitacionesColaborador];

        setParticipaciones(response.data.usuario.participaciones);
        setInvitaciones(todasInvitaciones);
      }
    } catch (error) {
      setError(error.message || "Error al cargar el usuario");
    }
    setLoading(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    if (usuario) {
      setFormData({
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        telefono: usuario.telefono,
        direccion: usuario.direccion,
        ciudad: usuario.ciudad,
        provincia: usuario.provincia,
        cp: usuario.cp,
        pais: usuario.pais,
      });
    }
  }, [usuario]);

  const renderizarEstado = (prop) => {
    setBotonPerfil(prop);
  }

  const handleChange = (event) => {
    setSelectedOption(event.target.value)
    changeLanguage(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  /* ---------- PAIS, PROV, CIUDAD  ----------*/
  const [loadingProvincias, setLoadingProvincias] = useState(false);
  const [loadingCiudades, setLoadingCiudades] = useState(false);

  useEffect(() => {
    const fetchPaises = async () => {
      const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
      const url = "https://api.countrystatecity.in/v1/countries";

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'X-CSCAPI-KEY': API_KEY
          }
        });

        if (!response.ok) {
          throw new Error('Error al obtener los países');
        }

        const data = await response.json();
        setPaises(data);
      } catch (error) {
        console.error('Error al obtener los países:', error);
      }
    };
    fetchPaises();
  }, []);

  const buscarNombreProvincia = (iso2Provincia) => {
    const provinciaEncontrada = provincias.find(provincia => provincia.iso2 === iso2Provincia);
    return provinciaEncontrada ? provinciaEncontrada.name : iso2Provincia;
  };

  const buscarNombreCiudad = (ciudadId) => {
    const ciudadEncontrada = ciudades.find(ciudad => ciudad.id === ciudadId);
    return ciudadEncontrada ? ciudadEncontrada.name : ciudadId;
  };

  useEffect(() => {
    const fetchProvincias = async (paisIso2) => {
      setLoadingProvincias(true);
      const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
      const url = `https://api.countrystatecity.in/v1/countries/${paisIso2}/states`;

      try {
        const response = await fetch(url, { headers: { 'X-CSCAPI-KEY': API_KEY } });
        if (!response.ok) {
          throw new Error('No se pudieron obtener las provincias');
        }
        const result = await response.json();
        setProvincias(result);
      } catch (error) {
        console.error('Error al obtener provincias:', error);
      } finally {
        setLoadingProvincias(false);
      }
    };

    if (paisSeleccionado) {
      fetchProvincias(paisSeleccionado);
    } else {
      setProvincias([]);
    }
  }, [paisSeleccionado]);

  useEffect(() => {
    const fetchCiudades = async (paisIso2, provinciaIso2) => {
      setLoadingCiudades(true);
      const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
      const url = `https://api.countrystatecity.in/v1/countries/${paisIso2}/states/${provinciaIso2}/cities`;

      try {
        const response = await fetch(url, { headers: { 'X-CSCAPI-KEY': API_KEY } });
        if (!response.ok) {
          throw new Error('No se pudieron obtener las ciudades');
        }
        const result = await response.json();
        setCiudades(result);
      } catch (error) {
        console.error('Error al obtener ciudades:', error);
      } finally {
        setLoadingCiudades(false);
      }
    };

    if (provSeleccionada && paisSeleccionado) {
      fetchCiudades(paisSeleccionado, provSeleccionada);
    } else {
      setCiudades([]);
    }
  }, [provSeleccionada, paisSeleccionado]);

  const handleCountryChange = (event) => {
    const selectedPais = event.target.value;
    /* const nombrePais = buscarNombrePais(selectedPais); */
    setPaisSeleccionado(selectedPais);
    setFormData(prevState => ({
      ...prevState,
      pais: selectedPais, // Guardar el nombre completo del país
      provincia: "",
      ciudad: ""
    }));
  };

  const handleProvChange = (event) => {
    const selectedProv = event.target.value;
    const nombreProvincia = buscarNombreProvincia(selectedProv);
    setProvSeleccionada(selectedProv);
    setFormData(prevState => ({
      ...prevState,
      provincia: nombreProvincia, // Guardar el nombre completo de la provincia
      ciudad: ""
    }));

  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const nombreCiudad = buscarNombreCiudad(selectedCityId);
    setFormData(prevState => ({
      ...prevState,
      ciudad: nombreCiudad // Guardar el nombre completo de la ciudad
    }));
  };
  /* ---------- FIN PAIS, PROV, CIUDAD  ----------*/

  const historialCompras = async () => {
    try {
      const response = await api.compras.getAllCompras();

      if (response.status === 'success') {
        setCompras(response.data.compras);
      } else {
        setError('Error al cargar las compras');
      }

      if (response.status === 'in progress') {
        setError('La carga de las compras está en progreso');
      }

    } catch (error) {
      setError('Error en la Red');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    historialCompras();
  }, []);

  const updateMyEvents = async () => {
    try {
      await callApi();
    } catch (error) {
      setError(error.message || "Error al actualizar los eventos");
    }
  };

  const handleAccept = async (id, tipo) => {
    const data = {
      id: id,
      idestado: 2
    };

    try {
      let response;
      if (tipo === 'Cliente') {
        response = await api.invitaciones.setInvitacionCliente(id, data);
      } else if (tipo === 'Colaborador') {
        response = await api.invitaciones.setInvitacionColaborador(id, data);
      }

      if (response.status === "success") {
        setResponseMessage('Invitación aceptada.');
        await updateMyEvents();
      } else {
        setResponseMessage('Error al procesar la solicitud.');
      }
    } catch (err) {
      console.log(`Error en la respuesta a la solicitud: ${err}`);
      setResponseMessage('Error al procesar la solicitud..');
    }
  };

  const handleReject = async (id, tipo) => {
    const confirmReject = window.confirm("¿Estás seguro de que deseas rechazar esta invitación?");

    if (confirmReject) {
      const data = {
        id: id,
        idestado: 3
      };

      try {
        let response;
        if (tipo === 'Cliente') {
          response = await api.invitaciones.setInvitacionCliente(id, data);
        } else if (tipo === 'Colaborador') {
          response = await api.invitaciones.setInvitacionColaborador(id, data);
        }

        if (response.status === "success") {
          setResponseMessage('Invitación rechazada.');
          await updateMyEvents();
        } else {
          setResponseMessage('Error al procesar la solicitud.');
        }
      } catch (err) {
        console.log(`Error en la respuesta a la solicitud: ${err}`);
        setResponseMessage('Error al procesar la solicitud..');
      }
    }
  };

  const masDetalles = (compra) => {
    history.push(`/detalles/${compra.id}`);
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Alert color="danger">
        {error}
      </Alert>
    );
  }
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
    renderizarEstado(buttonIndex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const regexE = /^(?!([a-zA-Z])\1+$)[a-zA-Z\sñÑ\u00C0-\u017F]*$/;

    if (formData.nombre?.trim() === "") {
      setError(<>{t("errors.emptyField")}</>);
      return;
    }

    if (!regexE.test(formData.nombre)) {
      setError(<>{t("errors.charactersEvent")}</>);
      return;
    }

    if (formData.nombre?.length > 50) {
      setError(<>{t("errors.eventName")}</>);
      return;
    }

    if (formData.apellido?.trim() === "") {
      setError(<>{t("errors.emptyField")}</>);
      return;
    }

    if (!regexE.test(formData.apellido)) {
      setError(<>{t("errors.charactersEvent")}</>);
      return;
    }

    if (formData.apellido?.length > 50) {
      setError(<>{t("errors.charactersDescription")}</>);
      return;
    }

    const data = {
      nombre: formData.nombre,
      apellido: formData.apellido,
      telefono: formData.telefono,
      direccion: formData.direccion,
      ciudad: formData.ciudad,
      provincia: formData.provincia,
      cp: formData.cp,
      pais: formData.pais,
    };

    try {
      const response = await api.usuarios.EditUsuario(data);

      if (response.status === "error") {
        setError("Error: " + response.message);
      } else if (response.status === "success") {
        setError("");
        setSubmitted(true);
        setTimeout(() => {
          history.go(0);
        }, 1500);
      }
    } catch (error) {
      console.error("Error al editar usuario:", error);
      setError("Error al editar usuario.");
    }
  };


  return (
    <div className="container-fluid">
      <div className="row align-items-start">
        <div className="col-3">
          <div className="container">
            <div className="row">
              <div className="col-3">
                <img
                  className="rounded-circle img-fluid profile-picture mt-3 mb-3"
                  alt={user.name + " avatar"}
                  src={user.picture}
                />
              </div>
              <div className="col-9">
                <h2 className="text-start fs-5 text-capitalize mt-3 mb-3 romper-texto nombre-usuario">
                  {usuario && (usuario.nombre && usuario.apellido)
                    ? `${usuario.nombre} ${usuario.apellido}`
                    : `${user?.email}`}
                </h2>
              </div>
            </div>
            <div className="row align-items-start">
              <div className="container-fluid mt-3 mb-3">
                <button type="button"
                  className={`btn  w-100 text-start ${activeButton === 1 ? "btn-activo-profile" : "btn-transparente"}`}
                  onClick={() => handleButtonClick(1)}><FontAwesomeIcon
                    icon={faUser} /> Perfil</button>
              </div>
            </div>

            <div className="row align-items-start">
              <div className="container-fluid">
                <button type="button"
                  className={`btn w-100 text-start ${activeButton === 4 ? "btn-activo-profile" : "btn-transparente"}`}
                  onClick={() => handleButtonClick(4)}>
                  <FontAwesomeIcon
                    icon={faEnvelope} />
                  Invitaciones
                  {invitaciones?.length > 0 && (
                    <span className="position-absolute mt-1 p-1 bg-danger border border-light rounded-circle" />
                  )}
                </button>
              </div>
            </div>
            <div className="row align-items-start">
              <div className="container-fluid mt-3 mb-3">
                <button type="button"
                  className={`btn w-100 text-start ${activeButton === 2 ? "btn-activo-profile" : "btn-transparente"}`}
                  onClick={() => handleButtonClick(2)}><FontAwesomeIcon
                    icon={faCalendarDays} /> Eventos</button>
              </div>
            </div>
            <div className="row align-items-start">
              <div className="container-fluid">
                <button type="button"
                  className={`btn w-100 text-start ${activeButton === 3 ? "btn-activo-profile" : "btn-transparente"}`}
                  onClick={() => handleButtonClick(3)}><FontAwesomeIcon
                    icon={faCartShopping} /> Compras</button>
              </div>
            </div>
            <div className="row align-items-start">
              <div className="container-fluid mt-3 mb-3">
                <button type="button"
                  className={`btn  w-100 text-start ${activeButton === 5 ? "btn-activo-profile" : "btn-transparente"}`}
                  onClick={() => handleButtonClick(5)}><FontAwesomeIcon
                    icon={faUser} /> Facturacion</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-9">
          {botonPerfil === 1 && (
            <div className="container">
              <div className="row mt-2 ml-1">
                <h3 className="d-flex align-items-center m-0">
                  <strong>Información personal</strong>
                  <div id="toolEdit" className="align-items-start fs-5">
                    {usuario && (
                      <Button className="py-0" outline color="grey" style={{ border: "none" }} onClick={() => setEditProfile(true)}>
                        <i className="material-symbols-outlined">border_color</i>
                      </Button>

                    )}
                  </div>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    autohide={false}
                    target="toolEdit"
                    toggle={toggleTool}
                  >
                    Editar Información
                  </Tooltip>
                </h3>
              </div>

              {editProfile ? (

                <div className="container">
                  <Form id="form-profile" onSubmit={handleSubmit}>
                    <Table className="mb-0 table">
                      <tbody>
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.name")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="nombre"
                              name="nombre"
                              type="text"
                              value={formData.nombre}
                              onChange={handleInputChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.lastName")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="apellido"
                              name="apellido"
                              type="text"
                              value={formData.apellido}
                              onChange={handleInputChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.phone")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="telefono"
                              name="telefono"
                              type="text"
                              value={formData.telefono}
                              onChange={handleInputChange}
                              className="profile-input"
                              style={{ width: "40%" }}
                            />
                          </td>
                        </tr>

                        {/* País */}
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.country")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="pais"
                              type="select"
                              name="pais"
                              value={formData.pais}
                              onChange={handleCountryChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                            >
                              <option value="" disabled>Selecciona un país</option>
                              {paises.map((pais) => (
                                <option key={pais.iso2} value={pais.iso2}>
                                  {pais.name}
                                </option>
                              ))}
                            </Input>
                          </td>
                        </tr>

                        {/* Provincia */}
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.state")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="provincia"
                              name="provincia"
                              type="select"
                              value={formData.provincia}
                              onChange={handleProvChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                              disabled={loadingProvincias || !provincias.length}
                            >
                              <option value="" disabled>
                                {loadingProvincias ? "Cargando provincias..." : "Selecciona una provincia"}
                              </option>
                              {provincias.map((provincia) => (
                                <option key={provincia.iso2} value={provincia.iso2}>
                                  {provincia.name}
                                </option>
                              ))}
                            </Input>
                          </td>
                        </tr>

                        {/* Ciudad */}
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.city")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="ciudad"
                              name="ciudad"
                              type="select"
                              value={formData.ciudad}
                              onChange={handleCityChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                              disabled={loadingCiudades || !ciudades.length}
                            >
                              <option value="" disabled>
                                {loadingCiudades ? "Cargando ciudades..." : "Selecciona una ciudad"}
                              </option>
                              {ciudades.map((ciudad) => (
                                <option key={ciudad.id} value={ciudad.name}>
                                  {ciudad.name}
                                </option>
                              ))}
                            </Input>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.cp")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="cp"
                              name="cp"
                              type="text"
                              value={formData.cp}
                              onChange={handleInputChange}
                              className="profile-input"
                              style={{ width: "10%" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.address")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="direccion"
                              name="direccion"
                              type="text"
                              value={formData.direccion}
                              onChange={handleInputChange}
                              className="profile-input"
                              style={{ width: "40%" }}
                            />

                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">Email</th>
                          <td className="p-2 align-middle">
                            {user.email}{" "}
                            {user.email_verified ? (
                              <Badge className="ml-2" color="success" disabled>
                                Verificado
                              </Badge>
                            ) : (
                              <Badge color="danger">Sin Verificar</Badge>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">Facebook</th>
                          <td className="p-2 align-middle">Vincular</td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">Google</th>
                          <td className="p-2 align-middle">Vincular</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="d-flex flex-row ml-auto justify-content-end mt-2">
                      <Button type="button" color="primary" outline className="mr-2 d-inline-flex" onClick={() => setEditProfile(false)}>{t("button.cancel")}</Button>{" "}
                      <Button type="submit" form="form-profile" color="primary" style={{}}>{t("button.save")}</Button>{" "}
                    </div>
                  </Form>
                  {error && <Alert color="danger">{error}</Alert>}
                  {submitted && <Alert color="success" style={{ fontSize: "14px", marginTop: "5px" }}>{t("profile.editAlert")}</Alert>}

                </div>
              ) : (
                <div className="container">
                  <Table className="mb-0 table">
                    <tbody>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.name")}</th>
                        <td className="p-2 align-middle">{usuario?.nombre}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.lastName")}</th>
                        <td className="p-2 align-middle">{usuario?.apellido}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.phone")}</th>
                        <td className="p-2 align-middle">{usuario?.telefono}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.country")}</th>
                        <td className="p-2 align-middle">{usuario?.pais}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.state")}</th>
                        <td className="p-2 align-middle">{usuario?.provincia}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.city")}</th>
                        <td className="p-2 align-middle">{usuario?.ciudad}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.cp")}</th>
                        <td className="p-2 align-middle">{usuario?.cp}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.address")}</th>
                        <td className="p-2 align-middle">{usuario?.direccion}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">Email</th>
                        <td className="p-2 align-middle">
                          {user.email}{" "}
                          {user.email_verified ? (
                            <Badge className="ml-2" color="success" disabled>
                              Verificado
                            </Badge>
                          ) : (
                            <Badge color="danger">Sin Verificar</Badge>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">Facebook</th>
                        <td className="p-2 align-middle">Vincular</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">Google</th>
                        <td className="p-2 align-middle">Vincular</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}


              <div className="row mt-2 ml-1">
                <h3 className="d-flex align-items-center mt-4 config" >
                  <strong>Configuración</strong>
                </h3>
              </div>
              <div className="container">
                <Table className="mb-0 table">
                  <tbody>
                    <tr>
                      <th scope="row" className="align-middle">Idioma</th>
                      <td className="p-2 align-middle">
                        <select id="dropdown" value={i18n.language} onChange={handleChange}>
                          <option value="" disabled>
                            Selecciona una opción
                          </option>
                          <option value="es">Español</option>
                          <option value="en">English</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {botonPerfil === 2 && (
            <div className="container">
              <h3 className="mt-2 ml-3 align-middle"><strong>Mis Eventos</strong></h3>
              {participaciones?.length > 0 ? (
                <div className="container">
                  <Table className="mb-0 table">
                    <thead>
                      <tr>
                        <th className="align-middle">Evento</th>
                        <th className="align-middle">Fecha</th>
                        <th className="align-middle">Rol</th>
                        <th className="align-middle">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {participaciones?.map(participacion => {
                        const fechaEvento = new Date(participacion.evento.fecha);
                        const fechaActual = new Date();
                        let estadoEvento = fechaEvento < fechaActual ? "Caducado" : "Activo";

                        if (participacion.evento.baja) {
                          estadoEvento = "Eliminado";
                        }

                        let rowClass = "";
                        if (participacion.evento.baja) {
                          rowClass = "tabla-danger";
                        } else if (estadoEvento === "Caducado") {
                          rowClass = "tabla-warning";
                        } else {
                          rowClass = "tabla-success";
                        }

                        return (
                          <tr key={participacion.id}>
                            <td className="p-2 align-middle">{participacion.evento.evento}</td>
                            <td className="p-2 align-middle">{new Date(participacion.evento.fecha).toLocaleDateString()}</td>
                            <td className="p-2 align-middle">{participacion.rol.rol}</td>
                            <td className="p-2 align-middle">
                              <span className={rowClass}>{estadoEvento}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <Alert color="secondary">
                  No tienes ningún evento registrado.
                </Alert>
              )}
            </div>
          )}
          {botonPerfil === 3 && (
            <div className="container">
              <h3 className="mt-2 ml-3"><strong>Historial de Compras</strong></h3>
              {compras?.length > 0 ? (
                <div className="container">
                  <Table className="mb-0 table">
                    <thead>
                      <tr>
                        <th className="align-middle">Evento</th>
                        <th className="align-middle">Fecha de Compra</th>
                        <th className="align-middle">Estado</th>
                        <th className="align-middle">Detalles</th>
                      </tr>
                    </thead>
                    <tbody>
                      {compras?.map(compra => {
                        if (compra.idcarrito !== 0) {
                          const fechaCompra = new Date(compra.fecha);
                          const estadoCompra = compra.atendido === null ? "Pendiente" : "Completado";

                          return (
                            <tr key={compra.id}>
                              <td className="p-2 align-middle">{compra.carrito.evento.evento}</td>
                              <td className="p-2 align-middle">{fechaCompra.toLocaleDateString()}</td>
                              <td className="p-2 align-middle">{estadoCompra}</td>
                              <td className="p-2 align-middle">
                                <Button onClick={() => masDetalles(compra)} color="primary">Ver detalles</Button>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <Alert color="secondary">
                  No tienes ninguna compra registrada.
                </Alert>
              )}
            </div>
          )}
          {botonPerfil === 4 && (
            <div className="container">
              <h3 className="mt-2 ml-3"><strong>Invitaciones Pendientes</strong></h3>
              {responseMessage && <Alert color="info">{responseMessage}</Alert>}
              {invitaciones?.length > 0 ? (
                <div className="container">
                  <Table className="mb-0 table">
                    <thead>
                      <tr>
                        <th className="align-middle">Evento</th>
                        <th className="align-middle">Rol</th>
                        <th className="align-middle">Estado</th>
                        <th className="align-middle">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invitaciones?.map(invitacion => (
                        <tr key={invitacion.id}>
                          <td className="p-2 align-middle">{invitacion.evento.evento}</td>
                          <td className="p-2 align-middle">{invitacion.tipo}</td>
                          <td className="p-2 align-middle">{invitacion.estado.estado}</td>
                          <td className="p-2 align-middle">
                            <Button className="btn btn-outline-success btn-sm m-1"
                              onClick={() => handleAccept(invitacion.id, invitacion.tipo)}
                              style={{ background: 'transparent' }}
                              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(40, 167, 69, 0.85)'}
                              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                              <GoCheckCircle />
                            </Button>
                            <Button className="btn btn-outline-danger btn-sm m-1"
                              onClick={() => handleReject(invitacion.id, invitacion.tipo)}
                              style={{ background: 'transparent' }}
                              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(220, 53, 69, 0.95)'}
                              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                              <MdOutlineCancel />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <Alert color="secondary">
                  No tienes ninguna invitación pendiente.
                </Alert>
              )}
            </div>
          )}
          {botonPerfil === 5 && (
            <div className="container">
              <div className="row mt-2 ml-1">
                <h3 className="d-flex align-items-center m-0">
                  <strong>Datos de Facturacion</strong>
                  <div id="toolEdit" className="align-items-start fs-5">
                    {usuario && (
                      <Button className="py-0" outline color="grey" style={{ border: "none" }} onClick={() => setEditProfile(true)}>
                        <i className="material-symbols-outlined">border_color</i>
                      </Button>

                    )}
                  </div>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    autohide={false}
                    target="toolEdit"
                    toggle={toggleTool}
                  >
                    Editar Información
                  </Tooltip>
                </h3>
              </div>

              {editProfile ? (

                <div className="container">
                  <Form id="form-profile" onSubmit={handleSubmit}>
                    <Table className="mb-0 table">
                      <tbody>
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.name")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="nombre"
                              name="nombre"
                              type="text"
                              value={formData.nombre}
                              onChange={handleInputChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                            />
                          </td>
                        </tr>
                        {/* País */}
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.country")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="pais"
                              type="select"
                              name="pais"
                              value={formData.pais}
                              onChange={handleCountryChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                            >
                              <option value="" disabled>Selecciona un país</option>
                              {paises.map((pais) => (
                                <option key={pais.iso2} value={pais.iso2}>
                                  {pais.name}
                                </option>
                              ))}
                            </Input>
                          </td>
                        </tr>

                        {/* Provincia */}
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.state")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="provincia"
                              name="provincia"
                              type="select"
                              value={formData.provincia}
                              onChange={handleProvChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                              disabled={loadingProvincias || !provincias.length}
                            >
                              <option value="" disabled>
                                {loadingProvincias ? "Cargando provincias..." : "Selecciona una provincia"}
                              </option>
                              {provincias.map((provincia) => (
                                <option key={provincia.iso2} value={provincia.iso2}>
                                  {provincia.name}
                                </option>
                              ))}
                            </Input>
                          </td>
                        </tr>

                        {/* Ciudad */}
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.city")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="ciudad"
                              name="ciudad"
                              type="select"
                              value={formData.ciudad}
                              onChange={handleCityChange}
                              className="profile-input"
                              style={{ width: "50%" }}
                              disabled={loadingCiudades || !ciudades.length}
                            >
                              <option value="" disabled>
                                {loadingCiudades ? "Cargando ciudades..." : "Selecciona una ciudad"}
                              </option>
                              {ciudades.map((ciudad) => (
                                <option key={ciudad.id} value={ciudad.name}>
                                  {ciudad.name}
                                </option>
                              ))}
                            </Input>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.cp")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="cp"
                              name="cp"
                              type="text"
                              value={formData.cp}
                              onChange={handleInputChange}
                              className="profile-input"
                              style={{ width: "10%" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">{t("profile.address")}</th>
                          <td className="p-2 align-middle">
                            <Input
                              id="direccion"
                              name="direccion"
                              type="text"
                              value={formData.direccion}
                              onChange={handleInputChange}
                              className="profile-input"
                              style={{ width: "40%" }}
                            />
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                    <div className="d-flex flex-row ml-auto justify-content-end mt-2">
                      <Button type="button" color="primary" outline className="mr-2 d-inline-flex" onClick={() => setEditProfile(false)}>{t("button.cancel")}</Button>{" "}
                      <Button type="submit" form="form-profile" color="primary" style={{}}>{t("button.save")}</Button>{" "}
                    </div>
                  </Form>
                  {error && <Alert color="danger">{error}</Alert>}
                  {submitted && <Alert color="success" style={{ fontSize: "14px", marginTop: "5px" }}>{t("profile.editAlert")}</Alert>}

                </div>
              ) : (
                <div className="container">
                  <Table className="mb-0 table">
                    <tbody>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.name")}</th>
                        <td className="p-2 align-middle">Nombre</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.country")}</th>
                        <td className="p-2 align-middle">Pais</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.state")}</th>
                        <td className="p-2 align-middle">Provincia</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.city")}</th>
                        <td className="p-2 align-middle">Ciudad</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.cp")}</th>
                        <td className="p-2 align-middle">CP</td>
                      </tr>
                      <tr>
                        <th scope="row" className="align-middle">{t("profile.address")}</th>
                        <td className="p-2 align-middle">Direccion</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

            </div>
          )}
        </div>
      </div >
    </div>
  );
};

const Profile = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 770px)' });

  return (
    <div>
      {isMobile ? <ProfileMobile /> : <ProfileComponent />}
    </div>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});