import React, { useState, useEffect, useRef } from "react";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import {
  Container,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import DeleteClientModal from "../../components/modales/DeleteClientModal";
import { useTranslation } from "react-i18next";
const UsuarioClientes = () => {
  const [evento, setEvento] = useState(null);
  const [eventoclientes, setEventoClientes] = useState([]);
  const history = useHistory();
  const param = useParams();
  const form = useRef();
  const [t] = useTranslation("global");
  const [userRole, setUserRole] = useState(null);
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await api.eventos.get(param.id);
        if (response.status === "success") {
          setEvento(response.data.evento);
          setEventoClientes(response.data.clientes || []); // Asegurar que sea un array
        } else {
          setError(response.message || "Error al cargar el evento");
        }
      } catch (error) {
        setError(error.message || "Error al cargar el evento");
      }
    };
    fetchEventos();
  }, []);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await api.participaciones.getParticipaciones();

        if (response.status === "success") {
          const rol = response.data.participaciones.find(
            (p) => p.idevento == param.id
          )?.idrol;
          setUserRole(rol);
        } else {
          setState({ ...state, error: "Error al obtener el rol." });
        }
      } catch (error) {
        setState({ ...state, error: "Error en la red." });
      }
    };
    fetchRole([]);
  }, [param.id]);

  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (email === "") {
      setError("El correo electrónico es obligatorio");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Introduce un correo electrónico válido");
      return;
    }

    const data = { email };
    const response = api.eventos.createCliente(param.id, data);
    return response.then((response) => {
      if (response.status === "error") {
        setError("Error: " + response.message);
        setSubmitting(false);
      }
      if (response.status === "success") {
        setSubmitting(false);
        setSubmitted(true);
        setError("");
        const data = {
          recipient: email,
          evento: evento.evento,
        };
        const response = api.email.send(data);
        setEmail("");
        setTimeout(function () {
          history.go(0);
        }, 1500);
        if (response.status === "error") {
          setError("Error: " + response.message);
        }
      }
    });
  };

  return (
    <>
      {evento && (
        <Container fluid="sm">
          {userRole === 1 && (
            <>
              <Form onSubmit={handleSubmit} ref={form}>
                <FormGroup>
                  <Label for="email">{t("customers.email")}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t("customers.enterEmail")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                {submitting && (
                  <Alert color="warning">
                    {" "}
                    <Spinner color="primary" className="mr-3"></Spinner>{" "}
                    Cargando.. por favor espere
                  </Alert>
                )}
                {error && <Alert color="danger">{error}</Alert>}
                {submitted && (
                  <Alert color="success">
                    {t("customers.successEmailAlert")}
                  </Alert>
                )}
                <Button type="submit" color="primary">
                  {t("button.register")}
                </Button>
              </Form>
            </>
          )}
          <br />
          <Table responsive size="sm">
          <thead key="Colaboradores" >
              <tr  className="table-light">
                <th > 
                  Nombre
                </th>
                <th > 
                  Estado
                </th>
                <th > 
                 {''}
                </th>
                </tr>
                </thead>
            <tbody>
              {eventoclientes?.length > 0 ? (
                eventoclientes.map((cliente) => (
                  <tr key={cliente.id}>
                    <th scope="row">{cliente.cliente}</th>
                    <td>{cliente.estado.estado}</td>
                    <td style={{ textAlign: "right" }}>
                      {userRole === 1 &&
                        cliente.idestado !== 3 &&
                        cliente.idestado !== 4 && (
                          <DeleteClientModal
                            eventoId={param.id}
                            clienteId={cliente.id}
                          />
                        )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No hay clientes registrados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      )}
    </>
  );
};

export default withAuthenticationRequired(UsuarioClientes, {
  onRedirecting: () => <Loading />,
});
