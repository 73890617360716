import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { api } from '../../services/api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { useTranslation } from 'react-i18next';


export const ExcelExport = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [ticketsTotal, setTicketsTotal] = useState([]);
  const param = useParams();
  const [t] = useTranslation("global");

  useEffect (() => {
    const callApi = async () => {
      try {
        const response = await api.eventos.getTickets(param.id, 100, 1);
        if (response.status === "success") {
          setTicketsTotal(response.data.tikets);
        }
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        })
      }
    }
    callApi();
  },[param.id, state]);

  const filasAExportar = ['idevento', 'id', 'nombre', 'apellido', 'codigo', 'idubicacion','ubicacion.ubicacion', 'idcategoria','categoria.categoria']; 

  const filteredData = ticketsTotal?.map(item => {
    const newItem = {};
    filasAExportar.forEach(field => {
      const [mainField, subField] = field.split('.');
      if (subField) {
        newItem[`${mainField}_${subField}`] = item[mainField][subField];
      } else {
        newItem[mainField] = item[mainField];
      }
    });
    return newItem;
  });

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), "tickets.xlsx");
  };

  return (
    <Button color="primary" style={{display: 'flex', alignItems: 'center', gap: '5px', marginLeft: '5px'}} onClick={exportToExcel}>
      <i className="material-symbols-outlined">download</i>
      {t("tickets.download")}
    </Button>
  )
}