import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../../services/api';
import { Table } from 'reactstrap';
import Loading from '../../components/Loading';
import VolverButton from '../../components/VolverButton';
import { useTranslation } from 'react-i18next';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const ReciboVenta = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });

  const [recibo, setRecibo] = useState();
  const param = useParams();
  const [t] = useTranslation("global");

  const total = recibo?.compra?.carrito?.items?.reduce((acc, item) => acc + item.tarifa.precio * item.cantidad, 0);
  
  useEffect(() => {
    const callApi = async (id) => {
      try {
        const response = await api.ventas.getRecibo(id);

        if (response.status === "success") {
          setRecibo(response.data.recibo);
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red."
        });
      }
    }

    callApi(param.id);
  }, [state]);

  return (
    <>
      {recibo ? (
        <div className="container-fluid container-sm mx-auto mt-5" >
          <div className="py-4 d-flex flex-wrap row-gap-4 align-items-center text-center bg-secondary text-white rounded-top">
            <p className="col-12 col-md-6 px-2 mb-0">
              Pago realizado por<br/>
              <span className="fw-bold">{recibo.email}</span>
            </p>
            <p className="col-12 col-md-6 px-2 mb-0">
              Método de pago: <span className="fw-bold">{recibo.recibo_mp.mp.medio}</span>
            </p>
          </div>
          
          <div className="py-4 d-flex align-items-center text-center bg-secondary-subtle ">
            <p className="col-4 mb-0">
              Recibo N°<br/>
              #{recibo.id}
            </p>
            <p className="col-4 mb-0">
              Fecha<br/>
              {new Date(recibo.recibo_mp.recibo.fecha).toLocaleString('es-AR')}
            </p>
            <p className="col-4 mb-0">
              Total abonado<br/>
              {recibo.recibo_mp.importe} {recibo.divisa}
            </p>
          </div>

          <Table striped borderless responsive >
            <thead>
              <tr className="text-center">
                <th className="col-2">Tipo</th>
                <th className="col-4">Descripción</th>
                <th className="col-2">Precio</th>
                <th className="col-2">Cantidad</th>
                <th className="col-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {recibo.compra.carrito?.items ?
                recibo.compra.carrito.items.map((item, i) =>(
                  <tr key={i}>
                    <td align="center" className="py-3">{item.tarifa.tipo.tipo}</td>
                    <th >{item.tarifa.concepto}</th>
                    <td align="center">$ {item.tarifa.precio}</td>
                    <td align="center">{item.cantidad}</td>
                    <td align="center">$ {(item.tarifa.precio * item.cantidad).toFixed(2)}</td>
                  </tr>
                )
              ) : (
                <tr>
                  <th colSpan={5}>Nada para mostrar.</th>
                </tr>
              )}
              <tr className="text-center">
                <td colSpan={3}><hr/></td>
                <th className="border border-3 border-secondary-subtle border-end-0">Total</th>
                <th className="border border-3 border-secondary-subtle border-start-0">$ {total?.toFixed(2)}.-</th>
              </tr>
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <VolverButton />
            <button className="btn btn-secondary-outline disabled" style={{margin: '5px'}}>
              <FontAwesomeIcon icon={faDownload} size="lg" /> Descargar
            </button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default withAuthenticationRequired(ReciboVenta, {
  onRedirecting: () => <Loading />,
});