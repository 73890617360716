import React, { useEffect, useState, useRef } from "react";
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../services/api";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EditPublicacion = () => {
  const { id, pubId } = useParams();
  const [formData, setFormData] = useState({
    eventoId: "",
    titulo: "",
    subtitulo: "",
    descripcion: "",
    desde: "",
    hasta: "",
  });
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const history = useHistory();
  const [t] = useTranslation("global");
  const fileInputRef = useRef(null);



  useEffect(() => {
    const fetchPublicacion = async () => {
      try {
        const response = await api.publicaciones.get(id);
        if (response.status === "success") {
          const data = response.data.publicacion;
          setFormData({
            eventoId: data.idevento,
            titulo: data.titulo,
            subtitulo: data.subtitulo,
            descripcion: data.descripcion,
            desde: moment(data.desde).format("YYYY-MM-DD"),
            hasta: moment(data.hasta).format("YYYY-MM-DD"),
          });
          setAttachments(data.attachs.map(attach => ({
            id: attach.id,
            url: attach.url,
            type: attach.type,
            format: attach.format,
            public_id: attach.public_id
          })));
        }
      } catch (error) {
        setError("Error al cargar los datos de la publicación.");
      }
    };
    fetchPublicacion();
  }, [id]);

  const handleCancel = () => {
    setFormData({
      eventoId: "",
      titulo: "",
      subtitulo: "",
      descripcion: "",
      desde: "",
      hasta: "",
    });
    setAttachments([]);
    setNewFiles([]);
    setError("");
    setSubmitted(false);
    history.goBack();
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'titulo':
        return value.length < 5 || value.length > 50 ? t("publications.titleError") : "";
      case 'subtitulo':
        return value.length < 5 || value.length > 50 ? t("publications.subtitleError") : "";
      case 'descripcion':
        return value.length < 5 || value.length > 240 ? t("publications.descripError") : "";
      case 'desde':
      case 'hasta':
        return !value ? t("publications.dateError") : "";
      default:
        return "";
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));

    const fieldError = validateField(name, value);
    setError(prevErrors => ({
      ...prevErrors,
      [name]: fieldError,
    }));
  };



  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setNewFiles(selectedFiles);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await api.media.upload(formData);
      if (response.status === "success") {
        const newAttachments = response.data.media.map((media) => ({
          url: media.url,
          type: media.type,
          format: media.format,
          public_id: media.public_id,
        }));
        setAttachments(prevAttachments => [...prevAttachments, ...newAttachments]);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const deleteMedia = async (index) => {
    const attachmentToDelete = attachments[index];

    try {
      if (attachmentToDelete.id) {
        await api.media.delete({ public_id: attachmentToDelete.public_id });
      }
      setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting attachment:", error);
      setError("Error al eliminar el archivo adjunto.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = Object.keys(formData).reduce((acc, key) => {
      const fieldError = validateField(key, formData[key]);
      if (fieldError) {
        acc[key] = fieldError;
      }
      return acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
    }

    const data = {
      id: parseInt(id),
      idevento: formData.eventoId,
      titulo: formData.titulo,
      subtitulo: formData.subtitulo,
      descripcion: formData.descripcion,
      desde: moment.parseZone(formData.desde).format("YYYY-MM-DDT00:00:00-03:00"),
      hasta: moment.parseZone(formData.hasta).format("YYYY-MM-DDT00:00:00-03:00"),
      attachs: attachments,
    };

    try {
      const response = await api.publicaciones.updatePublicacion(data);

      if (response.status === "success") {
        setFormData({
          eventoId: "",
          titulo: "",
          subtitulo: "",
          descripcion: "",
          desde: "",
          hasta: "",
        });
        setAttachments([]);
        setNewFiles([]);
        setError("");
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
          history.goBack();
        }, 1500);
      } else {
        console.error("Error submitting form:", error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px", padding: "0 10px" }}>
      <div style={{ maxWidth: "600px", width: "100%" }}>
        <h2>{t("publications.editPublication")}</h2>
        <Form onSubmit={handleSubmit} className="mt-4">
          <FormGroup>
            <p className="label-open-card">Título de la publicación</p>
            <div className="position-relative">
              <Input
                type="text"
                id="titulo"
                name="titulo"
                minLength={5}
                maxLength={50}
                value={formData.titulo}
                onChange={handleInputChange}
                className={error.titulo ? 'border-danger' : ''}
              />
              {error.titulo && (
                <i className="material-symbols-outlined position-absolute top-50 end-0 translate-middle-y text-danger me-2" >
                  error
                </i>
              )}
            </div>
            {error.titulo && <p className="text-danger small mt-1">{error.titulo}</p>}
          </FormGroup>

          <FormGroup>
            <p className="label-open-card">Subtitulo de la publicacion</p>
            <Input
              type="text"
              id="subtitulo"
              name="subtitulo"
              minLength={5}
              maxLength={50}
              value={formData.subtitulo}
              onChange={handleInputChange}
            /* placeholder="Subtitulo" */
            />
          </FormGroup>

          <FormGroup>
            <p className="label-open-card">Descripción</p>
            <p className="txt-open-card">Ingrese una descripcion concisa y atrayente para su publicacion</p>
            <div className="position-relative">
              <Input
                style={{ height: "60px", resize: "none" }}
                type="textarea"
                id="descripcion"
                name="descripcion"
                minLength={5}
                maxLength={98}
                value={formData.descripcion}
                onChange={handleInputChange}
                className={error.descripcion ? 'border-danger' : ''}
              />
              {error.descripcion && (
                <i className="material-symbols-outlined position-absolute top-50 end-0 translate-middle-y text-danger me-2" >
                  error
                </i>

              )}
            </div>
            {error.descripcion && <p className="text-danger small mt-1">{error.descripcion}</p>}
          </FormGroup>

          <div className="mb-3">
            <p className="label-open-card">Periodo de la publicación</p>
            <p className="txt-open-card text-justify">Selecciona las fechas en las que deseas que tu publicación sea visible.
              La publicación comenzará a mostrarse en la fecha de inicio y dejará de estar disponible después de la fecha de finalización.</p>
            <div className="d-flex flex-row gap-3 flex-wrap mt-1">
              <FormGroup floating>
                <div className="position-relative">
                  <Input
                    id="desde"
                    name="desde"
                    placeholder={t("publications.startDate")}
                    type="date"
                    value={formData.desde}
                    onChange={handleInputChange}
                    required
                    style={{ width: "200px" }}
                    className={error.desde ? 'border-danger' : ''}
                  />
                  {/* {error.desde && (
                    <i className="material-symbols-outlined position-absolute top-50 end-0 translate-middle-y text-danger me-2" >
                      error
                    </i>
                  )} */}
                </div>
                {error.desde && <p className="text-danger small mt-1">{error.desde}</p>}
                {/* <Label>Fecha de inicio</Label> */}
              </FormGroup>
              <FormGroup floating>
                <div className="position-relative">
                  <Input
                    id="hasta"
                    name="hasta"
                    placeholder={t("publications.endDate")}
                    type="date"
                    value={formData.hasta}
                    onChange={handleInputChange}
                    required
                    style={{ width: "200px" }}
                    className={error.hasta ? 'border-danger' : ''}
                  />
                  {/* {error.hasta && (
                    <i className="material-symbols-outlined position-absolute top-50 end-0 translate-middle-y text-danger me-2" >
                      error
                    </i>
    
                  )} */}
                </div>
                {error.hasta && <p className="text-danger small mt-1">{error.hasta}</p>}
                {/* <Label>Fecha de finalización</Label> */}
              </FormGroup>
            </div>
          </div>
          <FormGroup>
            <p className="label-open-card">Imagenes para tu publicacion</p>
            <div className="inputFile">
              <Input
                type="file"
                name="files"
                id="files"
                ref={fileInputRef}
                accept="image/*, video/*"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {error.attachment ? (
                <p className="text-danger small mt-1">{error.attachment}</p>
              ) : (
                <label className="" style={{ fontSize: "14px", cursor: "pointer", textAlign: "center", border: "2px dashed #C1C0C3", borderRadius: "5px", padding: "20px" }} htmlFor="files"><i className="material-symbols-outlined" style={{ fontSize: "35px", color: "#C1C0C3" }}>
                  image
                </i><br></br>{t("event.inputImage")}</label>
              )}

              <div className="ul-input-img d-flex flex-wrap">
                <ul className="ul-img d-flex flex-wrap">
                  {/* <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño recomendado de imagen: 19200x1280</li> */}
                  <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño máximo de archivo: 15MB</li>
                  <li style={{ fontSize: "10px", fontWeight: "normal" }}>Formatos admitidos de imagen: JPEG, PNG</li>
                </ul>
              </div>
              {attachments.length > 0 && (
                <div className="uploaded-media d-flex flex-wrap">
                  {attachments.map((attachment, index) => (
                    <div className="renglon-media">
                      <div key={index} className="media-preview d-flex my-3">
                        {attachment.url.includes("video") ? (
                          <video
                            src={attachment.url}
                            controls
                            className="w-100 object-fit-cover rounded-top"
                            style={{
                              height: "180px",
                              objectPosition: "center",
                            }}
                          />
                        ) : (
                          <img src={attachment.url} alt={`Media ${index}`} className="object-fit-cover"
                            style={{
                              width: "50px", height: "50px",
                              objectPosition: "center",
                            }} />
                        )}

                        <div className="content-preview">
                          <div className="text-preview">
                            <p className="title-preview">{/* fileNames[index] ||  */`Archivo ${index + 1}`}</p>
                            <p className="title-preview">png</p>
                          </div>
                          <button className="btn-preview" type="button" onClick={() => deleteMedia(index)}>
                            <i className="material-symbols-outlined" style={{ color: "red", fontSize: "22px" }}>
                              delete
                            </i>
                          </button>

                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </FormGroup>

          {Object.values(error).some(error => error) && (
            <Alert color="danger">
              Por favor, corrige los errores antes de enviar el formulario.
            </Alert>
          )}
          {submitted && (
            <Alert color="success">
              {t("publications.successEditedAlert")}
            </Alert>
          )}
          <div className="d-flex justify-content-end mb-4">
            <Button color="secondary" className="mr-2" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
            <Button type="success" color="success" onClick={handleSubmit}>
              {t("button.edit")}
            </Button>{" "}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditPublicacion;
