import { useState, useEffect } from 'react';
import { api } from '../services/api';

const useNotificaciones = () => {
  const [notificaciones, setNotificaciones] = useState([]);
  const [pendingNotificaciones, setPendingNotificaciones] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotificaciones = async () => {
      try {
        const response = await api.notificaciones.getNotificaciones();
        if (response.status === 'success') {
          const notificacionesArray = response.data.notificaciones;
          setNotificaciones(notificacionesArray);

          const tienePendientes = notificacionesArray.some(notificacion => !notificacion.atendido);
          setPendingNotificaciones(tienePendientes);
        } else {
          setError('Error al obtener las notificaciones');
        }
      } catch (error) {
        setError('Error en la red.');
      }
    };

    fetchNotificaciones();
  }, []);

  return { notificaciones, pendingNotificaciones, error };
};

export default useNotificaciones;
