import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation("global");

  return(
  <footer id="footer" className=" p-3 text-center" style={{backgroundColor:"#F3F5F9"}}>
    <div className="mb-2">
      <img src="/web-EVENTO.png" width="110px"></img>
    </div>
    <p>
        {t("footer.footer")}    
    </p>
  </footer>
  )
};

export default Footer;
