export const config ={
    URL_API: "https://caar.ipsistemas.com.ar/api",
    URL_PUBLIC:  "https://caar.ipsistemas.com.ar/public",
    URL_SOCKET: "https://caar.ipsistemas.com.ar/socket",
} 


/*

export const config ={
    URL_API: "http://localhost:7001/api",
    URL_PUBLIC:  "http://localhost:7001/public",
    URL_SOCKET: "https://caar.ipsistemas.com.ar/socket",
}

*/