import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendar,
  faPowerOff,
  faBell,
  faCartShopping,
  faRightToBracket,
  faArrowRight,
  faHouse,
  faCalendarDays
} from "@fortawesome/free-solid-svg-icons";
import useNotificaciones from "../hooks/hookNotifications";
import useCarrito from "../hooks/hookCarrito";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Dropdown,
} from "reactstrap";
import { es } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "hamburgers/dist/hamburgers.css";
import "./navbar.css";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NavBar = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [isOpen, setIsOpen] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { notificaciones, pendingNotificaciones } = useNotificaciones();
  const { getCarrito, itemsCarrito, loadingCarrito } = useCarrito();
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const routeName = {
    "/eventos": "Mis Eventos",
    "/notificaciones": "Notificaciones",
    "/carrito": "Carrito",
    "/profile": "Perfil",
  }[location.pathname];

  const navItems = [
    { path: "/eventos", name: "Mis Eventos", icon: faCalendar },
    { path: "/notificaciones", name: "Notificaciones", icon: faBell },
    { path: "/carrito", name: "Carrito", icon: faCartShopping },
    { path: "/profile", name: "Perfil", icon: faUser },
  ].filter((item) => item.name !== routeName);

  const toggleHamburger = () => {
    setIsActive(!isActive);
    toggle();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    if (profileMenuOpen) {
      setProfileMenuOpen(false);
    }
  };

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const setToken = async () => {
    const token = await getAccessTokenSilently();
    sessionStorage.setItem("token", token);
  };

  const logoutWithRedirect = () => {
    sessionStorage.removeItem("token");
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  if (isAuthenticated) {
    setToken();
  }

  const closeMenus = () => {
    setIsOpen(false);
    setIsActive(false);
    setProfileMenuOpen(false);
    setNotificationsOpen(false);
  };

  const [carritoOpen, setCarritoOpen] = useState(false);
  const toggleCarrito = () => {
    if (!carritoOpen) {
      getCarrito();
    }
    setCarritoOpen((prevState) => !prevState);
  }

  return (
    <>
    <ToastContainer></ToastContainer>
      {isMobile ? (
        <Navbar color="light" light style={{ top: "0", width: "100%" }}>
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div>
              <button
                aria-label="Menu"
                aria-controls="navigation"
                className={`hamburger hamburger--squeeze ${
                  isActive ? "is-active" : ""
                }`}
                type="button"
                onClick={toggleHamburger}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              {routeName && <span className="fs-4 fw-semibold">{routeName}</span>}
            </div>
            <NavbarBrand tag={RouterNavLink} to="/" exact>
              <img src="/mobile-EVENTO.png" width="55px" alt="eventologo" />
            </NavbarBrand>
          </div>
          <Collapse isOpen={isActive} navbar>
            <Nav navbar>
              {isAuthenticated ? (
                <>
                  {navItems.map((item) => (
                    <div
                      className="d-flex align-items-center mb-2"
                      key={item.name}
                    >
                      <FontAwesomeIcon icon={item.icon} className="mr-3" />
                      {itemsCarrito.length > 0 && item.name === "Carrito" && (
                        <span className="position-absolute translate-middle p-1 bg-danger border border-light rounded-circle" />
                      )}
                      {pendingNotificaciones && item.name === "Notificaciones" && (
                        <span className="position-absolute translate-middle p-1 bg-danger border border-light rounded-circle" />
                      )}
                      <NavItem className="m-0">
                        <RouterNavLink
                          to={item.path}
                          className="text-decoration-none"
                          onClick={closeMenus}
                        >
                          {item.name}
                        </RouterNavLink>
                      </NavItem>
                    </div>
                  ))}
                  <NavItem>
                    <FontAwesomeIcon icon={faPowerOff} className="mr-3" />
                    <RouterNavLink
                      to="#"
                      id="qsLogoutBtn"
                      onClick={() => {
                        logoutWithRedirect();
                        closeMenus();
                      }}
                      className="text-decoration-none"
                    >
                      {t("navbar.logout")}
                    </RouterNavLink>
                  </NavItem>
                </>
              ) : (
                <div>
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faHouse} className="mr-3" />
                    <NavItem className="m-0">
                      <RouterNavLink
                        to="/"
                        className="text-decoration-none"
                        onClick={closeMenus}
                      >
                        Inicio
                      </RouterNavLink>
                    </NavItem>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                  <FontAwesomeIcon icon={faCalendarDays} className="mr-3" />
                  <NavItem className="m-0">
                    <RouterNavLink                
                      to="/creaeventos"
                      className="text-decoration-none"
                      onClick={closeMenus}
                    >
                     {t("navbar.createEvents")}
                    </RouterNavLink>
                  </NavItem>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <FontAwesomeIcon icon={faRightToBracket} className="mr-3" />
                    <NavItem className="m-0">
                      <RouterNavLink
                        id="qsLoginBtn"
                        to="#"
                        onClick={() => {
                          loginWithRedirect();
                          closeMenus();
                        }}
                        className="text-decoration-none"
                      >
                        {t("navbar.login")}
                      </RouterNavLink>
                    </NavItem>
                  </div>
                </div>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      ) : (
        <Navbar color="light" light expand="md" container={false}>
          <Container className="p-0">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <NavbarBrand tag={RouterNavLink} to="/" exact>
                  <img src="/mobile-EVENTO.png" width="55px" alt="eventologo" />
                </NavbarBrand>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={closeMenus}
                    >
                      {t("navbar.home")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/creaeventos"
                      exact
                      activeClassName="router-link-exact-active"
                      className="mt-2"
                      onClick={closeMenus}
                    >
                     {t("navbar.createEvents")}
                    </NavLink>
                  </NavItem>
                  {isAuthenticated && (
                    <NavItem>
                      <NavLink
                        tag={RouterNavLink}
                        to="/eventos"
                        exact
                        activeClassName="router-link-exact-active"
                        className="mt-2"
                        onClick={closeMenus}
                      >
                        {t("navbar.myEvents")}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              </div>

              
              <div className="d-flex align-items-center">
                {isAuthenticated && (
                <Nav className="d-none d-md-flex align-items-center" navbar>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      id="notificationsDropdown"
                      className="mr-2"
                      onClick={toggleNotifications}
                      style={{ position: "relative" }}
                    >
                      <FontAwesomeIcon
                        icon={faBell}
                        style={{ height: "20px" }}
                      />
                      {pendingNotificaciones && (
                        <span className="position-absolute mt-1 p-1 bg-danger border border-light rounded-circle" />
                      )}
                    </DropdownToggle>
                    <DropdownMenu
                      end
                      style={{ maxHeight: "440px", minWidth:"341px", overflowY: "auto", borderRadius: "8px", boxShadow: "0 30px 30px rgba(0, 0, 0, 0.1)" }}
                    >
                      <DropdownItem header style={{ padding:"5px", backgroundColor: "#f8f9fa", borderBottom: "3px solid #e9ecef" ,textAlign: "center"}}>Notificaciones</DropdownItem>
                      
                      {notificaciones?.filter(notificacion => !notificacion.atendido).length ? (
                      notificaciones
                        .filter(notificacion => !notificacion.atendido)
                        .sort((a, b) => new Date(b.createon) - new Date(a.createon))
                        .slice(0, 3).map((notificacion) => (
                        <DropdownItem
                          key={notificacion.id}
                          tag={Link}
                          to={"/Notificaciones"}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "339px",
                            justifyContent: "space-between",
                            padding: "15px",
                            borderBottom: "2px solid #f1f1f1",
                            backgroundColor: "transparent",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                            }}
                          >
                            <div style={{ fontWeight: "bold", fontSize:"15px" }}>
                              {notificacion.titulo}
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginTop: "5px"
                              }}
                            >
                              {notificacion.descripcion.length > 43
                                ? `${notificacion.descripcion.substring(
                                    0,
                                    43
                                  )}...`
                                : notificacion.descripcion}
                            </div>
                            <span style={{ color:"gray", fontSize: '12px', margin: "5px" }}>
                              {formatDistanceToNow(new Date(notificacion.createon), { addSuffix: true, locale: es })}
                            </span>
                          </div>
                          {!notificacion.atendido && (
                            <span className="p-1 bg-danger border border-light rounded-circle" />
                          )}
                        </DropdownItem>
                      ))
                    ): (
                      <DropdownItem
                        tag={RouterNavLink}
                        to="/Notificaciones"
                        onClick={closeMenus}
                          style={{
                            height: "70px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#f8f9fa",
                            color: "#6c757d",
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          No tienes Notificaciones...
                        </DropdownItem>
                      )}

                      {notificaciones?.length > 0 && (
                      <DropdownItem
                        tag={RouterNavLink}
                        to="/Notificaciones"
                        onClick={closeMenus}
                        style={{
                          backgroundColor: "#f8f9fa",
                          color: "#DC3545",
                          padding: "10px",
                          textAlign: "center"
                        }}
                      >
                        Ver Todas
                      </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <Dropdown nav inNavbar isOpen={carritoOpen} toggle={toggleCarrito}>
                    <DropdownToggle
                      nav
                      className="mr-2 pb-0 mt-1"
                      style={{ position: "relative" }}
                    >
                      <FontAwesomeIcon
                        icon={faCartShopping}
                        style={{ height: "20px" }}
                      />
                      {itemsCarrito.length > 0 && (
                        <span className="position-absolute translate-middle p-1 bg-danger border border-light rounded-circle" />
                      )}
                    </DropdownToggle>

                    <DropdownMenu
                      end
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <DropdownItem header>Carrito</DropdownItem>
                      {itemsCarrito.length > 0 ? (
                        itemsCarrito.map((c) => (
                          <DropdownItem key={c.id} tag={Link} to={"/carrito"}>
                            <div className="d-flex flex-column">
                              <span>{c.tarifa.concepto}</span>

                              <div className="d-flex justify-content-between">
                                <small className="text-secondary">
                                  x{c.cantidad} ({c.tarifa.unidad})
                                </small>

                                <small className="text-secondary">
                                  $ {c.tarifa.precio * c.cantidad}
                                </small>
                              </div>
                            </div>
                          </DropdownItem>
                        ))
                      ) : (
                        <DropdownItem disabled className="text-secondary text-center">
                          {loadingCarrito
                            ? <Spinner color="primary" size="sm" />
                            : "El carrito esta vacío..."
                          }
                        </DropdownItem>
                      )}
                      {itemsCarrito && (
                        <DropdownItem
                          className="text-primary"
                          tag={Link}
                          to={"/carrito"}
                        >
                          Ir al carrito{" "}
                          <FontAwesomeIcon icon={faArrowRight} size="sm" />
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </Nav>
                )}

                <Nav className="d-none d-md-block" navbar>
                  {!isAuthenticated ? (
                    <NavItem>
                      <Button
                        id="qsLoginBtn"
                        color="primary"
                        className="btn-margin"
                        onClick={() => {
                          loginWithRedirect();
                          closeMenus();
                        }}
                      >
                        {t("navbar.login")}
                      </Button>
                    </NavItem>
                  ) : (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav id="profileDropDown">
                        <img
                          src={user.picture}
                          alt="Profile"
                          className="nav-user-profile rounded-circle"
                          width="50"
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>{user.name}</DropdownItem>
                        <DropdownItem
                          tag={RouterNavLink}
                          to="/profile"
                          className="dropdown-profile"
                          activeClassName="router-link-exact-active"
                        >
                          <FontAwesomeIcon icon="user" className="mr-3" />{" "}
                          {t("navbar.profile")}
                        </DropdownItem>
                        <DropdownItem
                          id="qsLogoutBtn"
                          onClick={() => {
                            logoutWithRedirect();
                            closeMenus();
                          }}
                        >
                          <FontAwesomeIcon icon="power-off" className="mr-3" />{" "}
                          {t("navbar.logout")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </Nav>
              </div>
            </div>
          </Container>
        </Navbar>
      )}
    </>
  );
};

export default NavBar;
