import React from "react";
import loading from "../assets/loading.svg";

const Loading = () => (
  <div className="spinner" style={{height: '100vh'}}>
    <img className="m-auto" src={loading} alt="Loading" height={400}/>
  </div>
);

export default Loading;
