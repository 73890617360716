import { format } from "date-fns";
import { useMediaQuery } from "react-responsive";
import moment from 'moment-timezone';
import { es } from 'date-fns/locale';
import { useLocation, useParams, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { MenuMobil } from "./Menu/MenuMobil";
import { useState, useEffect } from "react";
moment.locale('es');


export const Header = ({ evento }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const location = useLocation();
    const param = useParams();
    const [titulo, setTitulo] = useState('')
    const [subtitulo, setSubtitulo] = useState('');
    const [icono, setIcono] = useState('');
    const {t} = useTranslation("global");
    const localTime = moment.tz.guess();
    const { id } = useParams();

    useEffect(() => {
        const url = "/eventos/" + param.id;

        switch (location.pathname) {
            case url:
                setIcono("equalizer")
                setTitulo("Dashboard")
                setSubtitulo('');
                break;
            case url + "/ubicaciones":
                setIcono("location_on");
                setTitulo(t("location.locations"));
                setSubtitulo('');
            break;
            case url + "/tarifas":
                setIcono("payments");
                setTitulo(t("rates.rate"));
                setSubtitulo('');
            break;
            case url + "/creartarifa":
                setIcono("payments");
                setTitulo(t("rates.rate"));
                setSubtitulo(t("button.create"));
            break;
            case url + "/categorias":
                setIcono("sell");
                setTitulo(t("categorie.categories"));
                setSubtitulo('');
            break;
            case url + "/qrvouchers":
                setIcono("qr_code");
                setTitulo(t("menuTemplate.redeemVouchers"));
                setSubtitulo('');
            break;
            case url + "/qrtickets":
                setIcono("qr_code");
                setTitulo(t("menuTemplate.accessControl"));
                setSubtitulo('');
            break;
            case url + "/tickets&vouchers":
                setIcono("book_online");
                setTitulo("Tickets & Vouchers");
                setSubtitulo('');
            break;
            case url + `/eventos/${id}` :
                setIcono("book_online");
                setTitulo("Tickets & Vouchers");
                setSubtitulo('');
            break;
            case url + "/ventas":
                setIcono("trending_up");
                setTitulo(t("menuTemplate.sales"));
                setSubtitulo('');
            break;
            case url + "/clientes":
                setIcono("group");
                setTitulo(t("menuTemplate.customers"));
                setSubtitulo('');
            break;
            case url + "/cuentas":
                setIcono("account_balance_wallet");
                setTitulo(t("menuTemplate.wallet"));
                setSubtitulo('');
            break;
            case url + "/crear-cuenta":
                setIcono("account_balance_wallet");
                setTitulo(t("menuTemplate.wallet"));
                setSubtitulo(t("button.add"))
            break;
            case url + "/publicaciones":
                setIcono("web");
                setTitulo(t("menuTemplate.publications"));
                setSubtitulo('')
            break;
            case url + "/publicaciones/nueva":
                setIcono("web");
                setTitulo(t("menuTemplate.publications"));
                setSubtitulo(t("button.create"))
            break;
            case url + "/colaboradores":
                setIcono("group");
                setTitulo(t("menuTemplate.collaborators"));
                setSubtitulo('');
            break;
            case url + "/nuevoVoucher":
                setIcono("book_online");
                setTitulo('Vouchers');
                setSubtitulo(t("button.create"));
            break;
            case url + "/nuevoVoucher/importarExcel":
                setIcono("book_online");
                setTitulo('Vouchers');
                setSubtitulo(t("excelImport.massiveCreation"));
            break;
            case url + "/nuevoTicket":
                setIcono("book_online");
                setTitulo('Ticket');
                setSubtitulo(t("button.create"));
            break;
            case url + "/nuevoTicket/importarExcel":
                setIcono("book_online");
                setTitulo('Ticket');
                setSubtitulo(t("excelImport.massiveCreation"));
            break;
            case url + "/editar":
                setIcono("settings");
                setTitulo(t("event.editEvent"));
                setSubtitulo('');
            break;
            default:
                setIcono('');
                setTitulo('');
                setSubtitulo('');
            break;
        }
    },[location.pathname, param.id, t]);
    
    return (
        <header
            className="mb-3"
            style={{
                padding: "20px 10px",
            }}
        >
            <div className="d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center" style={{flex: 1}}>
                    <div className="d-flex">
                        <div className="d-flex justify-content-center align-items-center mr-2 text-light" style={{width: "35px", height: "35px", borderRadius: "50%", backgroundColor: "#2d2e2c"}}>
                            <i className="material-symbols-outlined ">{icono}</i>
                        </div>
                        <div>
                           <h2 style={{margin: "0px", display: "flex", alignItems: "center", fontWeight: "400"}}>{titulo}</h2>
                            { subtitulo.length != 0 && (
                                <h5 className="text-secondary fw-normal">{subtitulo}</h5>
                            )} 
                        </div>
                        
                    </div>
                    {isMobile && (
                        <MenuMobil evento={evento}/>
                    )}
                </div>
            </div>
        </header>

    )
}