import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { useState } from 'react';
import { useMediaQuery } from "react-responsive";
import transaccion from "../../assets/transaccion.svg"
import { useTranslation } from 'react-i18next';

export const TarifaDetalles = ({precio, asumirCostos}) => {
  const [modal, setModal] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [t] = useTranslation("global");

  const toggle = () => setModal(!modal);

    return (
        <>
            <div>
                <>
                    <small className="text-primary" style={{cursor: "pointer"}} onClick={toggle}>
                        {t("tickets.moreDetails")}
                    </small>
                    <Modal isOpen={modal} toggle={toggle} fullscreen={isMobile ? true : false}>
                        <ModalHeader toggle={toggle}>{t("tickets.details")}</ModalHeader>
                            
                            <ModalBody  style={!isMobile ? { display: 'flex' } : {}}> 
                                {!isMobile && (
                                    <div style={{width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <img src={transaccion} className='object-fit-cover' style={{width: "100%", objectPosition: "center"}}/>
                                    </div>
                                )}
                                <Table borderless>
                                    <thead>
                                        <tr>
                                            <th>{t("tickets.buyer'sCosts")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td className='pl-3'>{t("tickets.priceOfTheRate")}</td>
                                            <td class="text-end">{precio.toFixed(2)}</td>
                                        </tr>
                                        {!asumirCostos && (
                                            <>
                                                <tr>
                                                    <td className='pl-3'>{t("tickets.applicationCosts")}</td>
                                                    <td className="text-end">+ {(precio * 0.05).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td className='pl-3'>Total</td>
                                                    <td className="text-end">{(precio + precio * 0.05).toFixed(2)}</td>
                                                </tr>
                                            </>
                                            
                                        )}
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th>{t("tickets.yourPayment")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {asumirCostos ? (
                                            <>
                                                <tr>
                                                    <td className='pl-3'>{t("tickets.priceOfTheRate")}</td>
                                                    <td class="text-end">{precio.toFixed(2)}</td>
                                                </tr>
                                        
                                                <tr>
                                                    <td className='pl-3'>{t("tickets.applicationCosts")}</td>
                                                    <td className="text-end">-{(precio * 0.05).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td className='pl-3'>{t("tickets.yourProfit")}</td>
                                                    <td className="text-end">{(precio - precio * 0.05).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        ):(
                                            <tr>    
                                                <td className='pl-3'>{t("tickets.yourProfit")}</td>
                                                <td className="text-end">{precio.toFixed(2)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </ModalBody>
                        <ModalFooter>
                            <p className="text-primary" style={{cursor: "pointer"}} onClick={toggle}>Listo</p>
                        </ModalFooter>
                    </Modal>
                </>
            </div>
        </>
    
    )
}
