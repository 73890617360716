import React, { useState, useEffect, useRef } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { api } from "../services/api";
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellSlash, faTrashCan, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { faCalendarDays as faSolidCalendarDays } from '@fortawesome/free-solid-svg-icons'; 
import "./noti.css";
import {useNotificaciones} from './../hooks/hookNotifications'
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";



export const Notificaciones = () => {
  const [notificaciones, setNotificaciones] = useState([]);
  const [mostrar, setMostrar] = useState("todo");
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const [showDetails, setShowDetails] = useState(null);
  const detailsRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
          const fetchNotificaciones = async () => {
            try {
              const response = await api.notificaciones.getNotificaciones();
              //console.log("Notificaciones Response:", response);

              if (response.status === "success") {
                const notificacionesSet = new Map();
                response.data.notificaciones.forEach(notificacion => notificacionesSet.set(notificacion.id, notificacion));
                setNotificaciones(Array.from(notificacionesSet.values()));
              } else {
                setState({
                  ...state,
                  error: "Error al obtener las notificaciones",
                });
              }
            } catch (error) {
              setState({
                ...state
              });
            }
          };

    fetchNotificaciones();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (detailsRef.current && !detailsRef.current.contains(event.target) && !event.target.closest('.bell-icon')) {
        setShowDetails(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filtrarNotificaciones = () => {
    if (mostrar === "todo") return notificaciones;
    if (mostrar === "noleidas") {
      return notificaciones.filter(notificacion => !notificacion.atendido);
    }
    return notificaciones;
  };

  const marcarComoLeida = async (id) => {
    const confirmAtender = async () => {
      const body = {
        id: parseInt(id),
        atendido: new Date().toISOString()
      };

      try {
        const response = await api.notificaciones.atenderSolicitud(id, body);
        console.log("Marcar Como Leída Response:", response);

        if (response.status === "success") {
          setNotificaciones(prevNotificaciones =>
            prevNotificaciones.map(notificacion =>
              notificacion.id === id ? { ...notificacion, atendido: body.atendido } : notificacion
            )
          );
          setShowDetails(null);
        } else {
          setState({
            ...state,
            error: "Error al marcar la notificación como leída",
          });
        }
      } catch (error) {
        console.error("Error en la Red al marcar como leída:", error);
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };

    confirmAtender();
  };


  const eliminarNotificacion = async(id) =>{
    const confirmEliminar = async () => {
      const body = {
        id: parseInt(id)
      };

      try {
        const response = await api.notificaciones.eliminarNotificacion(id);
        console.log("Eliminar Notificacion:", response);

        if (response.status === "success") {
          setNotificaciones(prevNotificaciones =>
            prevNotificaciones.map(notificacion =>
              notificacion.id === id ? { ...notificacion, baja: body.baja } : notificacion
            )
          );
          setShowDetails(null);
        } else {
          setState({
            ...state,
            error: "Error al querer eliminar la notificación...",
          });
        }
      } catch (error) {
        console.error("Error en la Red al eliminar notificacion:", error);
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };

    confirmEliminar();
  }

  const handleBellClick = (id) => {
    setShowDetails(prevId => (prevId === id ? null : id));
  };

  const notificacionesFiltradas = filtrarNotificaciones();
  const noNotificaciones = notificacionesFiltradas.length === 0;


  return (
    <div className="notifications-container">
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">Notificaciones</h2>
        </div>
        <div className="card-content">
        <div className="unique-scroll" />
          <div className="tabs" id="notifications-tabs">
            <button 
              className={`tab ${mostrar === "todo" ? "active" : ""}`} 
              onClick={() => setMostrar("todo")}
            >
              Todas
              </button>
            <button 
              className={`tab ${mostrar === "noleidas" ? "active" : ""}`} 
              onClick={() => setMostrar("noleidas")}
            >
              No leídas
            </button>
          </div>

          {filtrarNotificaciones().length === 0 ? (
            <div className="empty-notifications">
            <img src="https://img.freepik.com/vector-gratis/hombre-sysadmine-o-programador-informatico-que-trabaja-computadora_575670-70.jpg?t=st=1725402209~exp=1725405809~hmac=894d9edd95a2c53294e571737506f6ba9ffa795230cee4b5610eef4f505c35a5&w=740" alt="no notifications" className="empty-image"></img>
            <h3 className="empty-message">
            {mostrar === "noleidas" ? "No tienes notificaciones no leídas" : "No tienes notificaciones"}
            </h3>
          </div>
          ) : (
            notificaciones
            .filter(notificacion => mostrar === "noleidas" ? !notificacion.atendido : true)
            .sort((a, b) => new Date(b.createon) - new Date(a.createon)) // Ordenar por fecha
            .map((notificacion) => (
              <div key={notificacion.id} className="notification-card">
                <div className="notification-content">
                  <h3 className="notification-title" style={{cursor:"pointer"}} onClick={() => history.push("/profile")}>{notificacion.titulo}
                  {/* <span style={{ fontSize: '14px', margin:"15px" }} className="text-body-secondary fw-medium">
                      creado por: {notificacion.createby}
                  </span> */}
                  </h3>
                  <p className="notification-description">{notificacion.descripcion}</p>
                  <div className="notification-date">
                    <FontAwesomeIcon icon={faSolidCalendarDays} style={{ color: "#456ee8", marginRight: "0.5rem" }} />
                    <span style={{ fontSize: '14px', margin: "5px" }}>
                      {formatDistanceToNow(new Date(notificacion.createon), { addSuffix: true, locale: es })}
                    </span>
                  </div>
                </div>

                <div className="notification-actions">
                    <button
                    className={`btn-icon bell-icon ${notificacion.atendido ? "read" : ""}`}
                    onClick={() => marcarComoLeida(notificacion.id)}
                  >
                    <FontAwesomeIcon icon={notificacion.atendido ? faBellSlash : faBell} />
                  </button>
                  <button
                    className={`btn-icon trash-icon ${notificacion.baja ? "red" : ""}`}
                    onClick={() => eliminarNotificacion(notificacion.id)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
);
}

export default withAuthenticationRequired(Notificaciones, {
  onRedirecting: () => <Loading />,
});