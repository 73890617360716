import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import "moment/locale/es";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useMediaQuery } from "react-responsive";
moment.locale('es');
moment.locale('en-GB');

export const Evento = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  const [data, setData] = useState([]);
  const history = useHistory();
  const { i18n, t } = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.eventos.getAll();

        if (response.status === "success") {
          setData(response.data.eventos);
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };
    callApi();
  }, []);

  const handleRowClick = (evento) => {
    history.push("/eventos/" + evento.id);
  };
  
  return (
    <>
      {isMobile ? (
        <div className="py-4 w-100 h-100 d-flex flex-column justify-content-between">
          <div>
          {data?.map((evento) => (
            <>
              <div
                className="container d-flex align-items-center py-3 px-4"
                key={evento.id}
              >
                <div
                  className="avatar-evento"
                  style={{ width: "60px", height: "60px" }}
                >
                  {evento.attachs && evento.attachs.length > 0 ? (
                    <img
                      src={evento.attachs[0].url}
                      key={evento.attachs[0].id}
                      alt="Fiesta"
                      className="object-fit-cover rounded-circle"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectPosition: "center",
                      }}
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/img_fiesta.jpg"}
                      alt="Fiesta"
                      className="object-fit-cover rounded-circle"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectPosition: "center",
                      }}
                    ></img>
                  )}
                </div>
                <div
                  className="info-evento d-flex justify-content-between "
                  style={{ flex: 1 }}
                >
                  <div
                    className="ml-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(evento)}
                  >
                    <p className="mb-0 fw-semibold text-black fs-5">
                      {evento.evento}
                    </p>
                    <div
                      className="align-items-center text-body-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      <p className="m-0 fw-medium">
                      {i18n.language === 'es' ? (
                        moment(evento.fecha).clone().local().format("D [de] MMMM YYYY")
                      ):(
                        moment(evento.hora).clone().local().format("MMMM D[,] YYYY")
                      )}{" "}
                        |{" "}
                        <span
                          style={{ fontSize: "15px" }}
                          className="text-body-secondary fw-medium ml-1"
                        >
                          {evento.rol}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="solicutudes-evento d-flex align-items-center justify-content-center">
                    {evento.solicitudes ? (
                      <a
                        className="text-primary d-flex flex-column"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(`/eventos/${evento.id}/ventas`)
                        }
                      >
                        <i
                          className="material-symbols-outlined text-primary"
                          style={{ width: "20px", height: "20px" }}
                        >
                          notifications
                        </i>
                        <p className="fw-bold fs-6 m-0 text-center">
                          {evento.solicitudes}
                        </p>
                      </a>
                    ) : (
                      <i
                        className="material-symbols-outlined ext-secondary"
                        style={{ width: "20px", height: "20px" }}
                      >
                        notifications
                      </i>
                    )}
                  </div>
                </div>
              </div>
              <div className="px-4 w-100">
                <hr className="m-0"></hr>
              </div>
            </>
          ))}
        </div>
        <div
            className="mr-4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="d-flex justify-content-end align-items-center btn btn-dark"
              onClick={() => history.push("/eventos/crear")}
            >
              <i className="material-symbols-outlined">add</i>
              {t("event.addEvent")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="body">
          <div className="container py-2 mt-3">
            <div>
              <h1>{t("navbar.myEvents")}</h1>
              <h5 className="fw-normal">{t("event.controlEvent")}</h5>
            </div>

            <div
              className="mb-3"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="d-flex justify-content-end align-items-center btn btn-dark"
                onClick={() => history.push("/eventos/crear")}
              >
                <i className="material-symbols-outlined">add</i>
                {t("event.addEvent")}
              </Button>
            </div>

            <div>
              <table className="table table-hover border border">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{t("menuTemplate.homePage")}</th>
                    <th scope="col">{t("event.date")}</th>
                    <th scope="col">{t("event.role")}</th>
                    <th scope="col">{t("event.notifications")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((evento) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(evento)}
                      key={evento.id}
                    >
                      <td
                        className="avatar-evento"
                        style={{ width: "80px", height: "80px" }}
                      >
                        {evento.attachs && evento.attachs.length > 0 ? (
                          <img
                            src={evento.attachs[0].url}
                            key={evento.attachs[0].id}
                            alt="Fiesta"
                            className="object-fit-cover rounded-circle"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectPosition: "center",
                            }}
                          />
                        ) : (
                          <img
                            src={process.env.PUBLIC_URL + "/img_fiesta.jpg"}
                            alt="Fiesta"
                            className="object-fit-cover rounded-circle"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectPosition: "center",
                            }}
                          ></img>
                        )}
                      </td>
                      <td className="fw-semibold text-black fs-5">
                        {evento.evento}
                      </td>
                      <td className=" align-items-center fw-medium">
                      {i18n.language === 'es' ? (
                        moment(evento.fecha).clone().local().format("D [de] MMMM YYYY")
                      ):(
                        moment(evento.hora).clone().local().format("MMMM D[,] YYYY")
                      )}{" "}
                        | {moment(evento.hora).clone().local().format("HH:mm")}
                      </td>
                      <td>
                        <span
                          className="badge rounded-pill text-dark align-content-center"
                           style={{
                            backgroundColor: "#EDEDED",
                            width: "120px",
                            height: "30px"
                          }}
                        >
                          {evento.rol}
                        </span>
                      </td>
                      <td className="solicutudes-evento align-items-center justify-content-center">
                        {evento.solicitudes ? (
                          <a
                            className="text-primary d-flex flex-column"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/eventos/${evento.id}/ventas`)
                            }
                          >
                            <i
                              className="material-symbols-outlined text-primary"
                              style={{ width: "20px", height: "20px" }}
                            >
                              notifications
                            </i>
                            <p className="fw-bold fs-6 m-0 text-center">
                              {evento.solicitudes}
                            </p>
                          </a>
                        ) : (
                          <i
                            className="material-symbols-outlined ext-secondary"
                            style={{ width: "20px", height: "20px" }}
                          >
                            notifications
                          </i>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default withAuthenticationRequired(Evento, {
  onRedirecting: () => <Loading />,
});
