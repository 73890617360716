import { config } from "./config";
import 'isomorphic-fetch';


const apiOrigin = config.URL_API;
const publicOrigin = config.URL_PUBLIC;


const publicOptions = (method, data) => {
    let opt = {
        method,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    if (data) {
        opt.body = JSON.stringify(data);
    }
    return opt;
};
const pivateOptions = (method, data) => {
    let opt = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        }
    };
    if (data) {
        opt.body = JSON.stringify(data);
    }

    return opt;
};
const mediaOptions = (method, data) => {
    let opt = {
        method,
        headers: {
            'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        }
    };
    if (data) {
        opt.body = data;
    }

    return opt;
};

export const api = {
    auth: {
        async getAuthenticatedUser() {
            const response = await fetch(apiOrigin + '/authenticate', publicOptions('GET'));
            const data = await response.json();
            return data;
        },
    },

    usuarios: {
        async createUsuario(data) {
            const response = await fetch(apiOrigin + '/usuarios', pivateOptions('POST', data));
            const res = await response.json();
            return res
        },
        async getUserExist(email) {
            const response = await fetch(`${apiOrigin}/usuario/exist?${email ? `&email=${email}` : ''}`, pivateOptions('GET'));
            const res = await response.json();
            return res
        },
        async getUsuario() {
            const response = await fetch(apiOrigin + '/usuario', pivateOptions('GET'));
            const res = await response.json();
            return res;
        },
        async EditUsuario(data) {
            const response = await fetch(apiOrigin + '/usuario', pivateOptions('PUT', data));
            const res = await response.json();
            return res
        }
    },

    eventos: {
        async getAllPublic() {
            const response = await fetch(publicOrigin + '/eventos', publicOptions('GET'));
            const data = await response.json();
            return data;
        },
        async getPublic(id) {
            const response = await fetch(publicOrigin + '/eventos/' + id, publicOptions('GET'));
            const data = await response.json();
            return data
        },
        async getAll() {
            const response = await fetch(apiOrigin + '/eventos', pivateOptions('GET'));
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(apiOrigin + '/eventos/' + id, pivateOptions('GET'));
            const data = await response.json();
            return data;
        },

        async getAllEventosTipos() {
            const response = await fetch(apiOrigin + '/eventos/tipos', pivateOptions('GET'));
            const data = await response.json();
            return data;
        },

        async getTickets(idEvento, pageSize, currentPage) {
            const response = await fetch(apiOrigin + `/eventos/${idEvento}/tickets?q&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
            const res = await response.json();
            return res
        },

        async getTicket(id, qr) {
            const response = await fetch(apiOrigin + '/eventos/' + id + "/" + qr, pivateOptions('PUT'));
            const data = await response.json();
            return data;
        },

        async getTicketEvento(id) {
            const response = await fetch(apiOrigin + '/eventos/tickets/' + id, pivateOptions('GET'));
            const data = await response.json();
            return data
        },

        async createTicket(id, data) {
            const response = await fetch(apiOrigin + '/eventos/' + id + "/ticket", pivateOptions('POST', data));
            const res = await response.json();
            return res
        },

        async createTickets(id, data) {
            const response = await fetch(apiOrigin + '/eventos/' + id + "/tickets", pivateOptions('POST', data));
            const res = await response.json();
            return res
        },

        async setTicket(id, qr, salida) {
            const response = await fetch(apiOrigin + '/eventos/' + id + "/" + qr + "/" + salida, pivateOptions('PUT'));
            const data = await response.json();
            return data;
        },
        async getTicketByEvento(idEvento, idcategoria, query = '', page = 1) {
            const response = await fetch(`${apiOrigin}/eventos/${idEvento}/tickets?q=${query}${idcategoria ? `&idcategoria=${idcategoria}` : ''}&page=${page}`, pivateOptions('GET'));
            const data = await response.json();
            return data;
        },
        async createEvento(data) {
            const response = await fetch(apiOrigin + '/eventos', pivateOptions('POST', data));
            const res = await response.json();
            return res;
        },
        async deleteEvento(id) {
            const response = await fetch(apiOrigin + '/eventos/' + id, pivateOptions('DELETE'));
            const data = await response.json();
            return data;
        },
        async setEvento(id, data) {
            const response = await fetch(apiOrigin + '/eventos/' + id, pivateOptions('PUT', data));
            const res = await response.json();
            return res;
        },

        async createCliente(id, data) {
            const response = await fetch(`${apiOrigin}/eventos/${id}/cliente`, pivateOptions('POST', data));
            const res = await response.json();
            return res;
        },
        async deleteCliente(eventoId, clienteId) {
            try {
                // const response = await fetch(`${apiOrigin}/eventos/${eventoId}/cliente/${clienteId}`, pivateOptions('DELETE'));
                const response = await fetch(`${apiOrigin}/eventos/cliente/${clienteId}`, pivateOptions('DELETE'));
                if (!response.ok) {
                    throw new Error('Error al eliminar el cliente'); // Manejo genérico de error
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error('Error al eliminar el cliente:', error.message);
                throw error;
            }
        },
        async getImpresion(id) {
            try {
                const response = await fetch(`${apiOrigin}/eventos/${id}/impresion`, pivateOptions('GET'));
                if (!response.ok) {
                    throw new Error('Error al obtener la impresión');
                }
                const res = await response.json();
                return res;
            } catch (error) {
                console.log('Error al obtener la impresión:', error.message);
                throw error;
            }
        },
        async createImpresion(id, data) {
            try {
                const response = await fetch(`${apiOrigin}/eventos/${id}/impresion`, pivateOptions('POST', data));
                if (!response.ok) {
                    throw new Error('Error al crear la impresión');
                }
                const res = await response.json();
                return res;
            } catch (error) {
                console.error('Error al crear la impresión:', error.message);
                throw error;
            }
        },
        async updateImpresion(id, data) {
            try {
                const response = await fetch(`${apiOrigin}/eventos/${id}/impresion`, pivateOptions('PUT', data));
                if (!response.ok) {
                    throw new Error('Error al editar la impresión');
                }
                const res = await response.json();
                return res;
            } catch (error) {
                console.error('Error al editar la impresión:', error.message);
                throw error;
            }
        },
        async downloadImpresion(id) {
            const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/download`, pivateOptions('GET'));
            const res = await response.blob();
            return res
        }
    },
    colaboradores: {
        async createColaborador(id, data) {
            const response = await fetch(apiOrigin + '/eventos/' + id + "/colaborador", pivateOptions('POST', data));
            const res = await response.json();
            return res
        },

        async deleteColaborador(id) {
            const response = await fetch(apiOrigin + '/eventos/colaborador/' + id, pivateOptions('DELETE'));
            const data = await response.json();
            return data
        }
    },
    locations: {
        async getAll(id, q = "", pageSize = 10, currentPage = 0) {
            const response = await fetch(apiOrigin + `/ubicaciones?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}&idevento=${id}`, pivateOptions('GET'));
            const data = await response.json();
            return data;
        },
        async createLocation(data) {
            const response = await fetch(apiOrigin + '/ubicaciones', pivateOptions('POST', data));
            const res = await response.json();
            return res;
        },
        async deleteLocation(id) {
            const response = await fetch(apiOrigin + `/ubicaciones/${id}`, pivateOptions('DELETE'));
            const data = await response.json();
            return data;
        },
        async updateLocation(data) {
            const response = await fetch(apiOrigin + '/ubicaciones', pivateOptions('PUT', data));
            const res = await response.json();
            return res;
        }
    },

    solicitudes: {
        async getAll(id, q = "", pageSize = 10, currentPage = 0) {
            const response = await fetch(apiOrigin + `/eventos/${id}/solicitudes?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(apiOrigin + `/eventos/solicitudes/${id}`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },
        async createSolicitud(id, data) {
            const response = await fetch(`https://caar.ipsistemas.com.ar/public/eventos/${id}/solicitud`, publicOptions('POST', data));
            const res = await response.json();
            return res;
        },
        async atenderSolicitud(id, data) {
            const response = await fetch(apiOrigin + `/eventos/solicitudes/${id}`, pivateOptions('PUT', data));
            const res = response.json();
            return res
        }
    },

    ventas: {
        async getAll(id, q = "", pageSize = 10, currentPage = 0) {
            const response = await fetch(apiOrigin + `/evento/${id}/ventas?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(apiOrigin + `/evento/venta/${id}`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },
        async getRecibo(id) {
            const response = await fetch(apiOrigin + `/evento/venta/${id}/recibo`, pivateOptions('GET'));
            const data = response.json();
            return data;
        }
    },

    categorias: {
        async getAll(id, q = '', pageSize = 10, currentPage = 0) {
            const response = await fetch(apiOrigin + `/categorias?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}&idevento=${id}`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },

        async deleteCategoria(id) {
            try {
                const response = await fetch(`${apiOrigin}/categorias/${id}`, pivateOptions('DELETE'));
                // if (!response.ok) {
                //     throw new Error('Error al eliminar la categoría'); // Manejo genérico de error
                // }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error('Error al eliminar la categoría:', error.message);
                throw error;
            }
        },

        async createCategoria(data) {
            try {
                const response = await fetch(apiOrigin + '/categorias', pivateOptions('POST', data));
                if (!response.ok) {
                    throw new Error('Error al crear la categoría');
                }
                const res = await response.json();
                return res;
            } catch (error) {
                console.error('Error al crear la categoría:', error.message);
                throw error;
            }
        },


        async editCategory(data) {
            try {
                const response = await fetch(`${apiOrigin}/categorias`, pivateOptions('PUT', data));
                if (!response.ok) {
                    throw new Error('Error al editar la categoría');
                }
                const res = await response.json();
                return res;
            } catch (error) {
                console.error('Error al editar la categoría:', error.message);
                throw error;
            }
        },
    },

    publicaciones: {
        async getAllActive(q = '', pageSize = 10, currentPage = 0) {
            const response = await fetch(`${publicOrigin}/eventos/publicaciones/activas?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}`, publicOptions('GET'));
            const data = await response.json();
            return data;
        },
        async getAll(id, q = "", pageSize = 10, currentPage = 0) {
            const response = await fetch(`${apiOrigin}/publicaciones?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}&idevento=${id}`, pivateOptions('GET'));
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(`${apiOrigin}/publicaciones/${id}`, pivateOptions('GET'));
            const data = await response.json();
            return data;
        },
        async createPublicacion(data) {
            const response = await fetch(`${apiOrigin}/publicaciones`, pivateOptions('POST', data));
            const res = await response.json();
            return res;
        },
        async deletePublicacion(id) {
            const response = await fetch(`${apiOrigin}/publicaciones/${id}`, pivateOptions('DELETE'));
            const data = await response.json();
            return data;
        },
        async updatePublicacion(data) {
            const response = await fetch(`${apiOrigin}/publicaciones`, pivateOptions('PUT', data));
            const res = await response.json();
            return res;
        },
    },

    eventoCuentas: {
        async getAll(id) {
            const response = await fetch(apiOrigin + `/eventos/${id}/cuentas`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(apiOrigin + `/eventos/cuentas/${id}`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },
        async createCuenta(id, data) {
            const response = await fetch(apiOrigin + `/eventos/${id}/cuentas`, pivateOptions('POST', data));
            const res = await response.json();
            return res;
        },
        async setEventoCuenta(id, data) {
            const response = await fetch(apiOrigin + `/eventos/cuentas/${id}`, pivateOptions('PUT', data));
            const res = response.json();
            return res;
        },
        async deleteEventoCuenta(id) {
            const response = await fetch(apiOrigin + `/eventos/cuentas/${id}`, pivateOptions('DELETE'));
            const data = response.json();
            return data;
        },
    },
    mercadopago: {
        async createPago(data) {
            const response = await fetch(apiOrigin + "/payment", pivateOptions('POST', data));
            const res = response.json();
            return res;
        },
        async vincularCuenta(data) {
            const response = await fetch(apiOrigin + "/vinculation", pivateOptions('POST', data));
            const res = response.json();
            return res;
        },
    },

    invitaciones: {
        async setInvitacionColaborador(id, data) {
            const response = await fetch(`${apiOrigin}/eventos/colaborador/${id}`, pivateOptions('PUT', data));
            const res = await response.json();
            return res;
        },
        async setInvitacionCliente(id, data) {
            const response = await fetch(`${apiOrigin}/eventos/cliente/${id}`, pivateOptions('PUT', data));
            const res = await response.json();
            return res;
        },
        async searchInvitation() {
            const response = await fetch(`${apiOrigin}/invitaciones/pendientes`, pivateOptions('GET'));
            const res = await response.json();
            return res;
        }
    },

    notificaciones: {
        async getNotificaciones() {
            const response = await fetch(`${apiOrigin}/notificaciones`, pivateOptions('GET'));
            const res = await response.json();
            return res;
        },

        async atenderSolicitud(id, data) {
            const response = await fetch(`${apiOrigin}/notificacion/${id}`, pivateOptions('PUT', data));
            const res = await response.json();
            return res;
        },

        async eliminarNotificacion(id) {
            const response = await fetch(`${apiOrigin}/notificacion/${id}`, pivateOptions('DELETE'));
            const res = await response.json()
            return res;
        }
    },

    inscripciones: {
        async createInscripcion(id, data) {
            const response = await fetch(`http://localhost:7001/public/eventos/${id}/inscripcion`, publicOptions('POST', data));
            const res = response.json();
            return res;
        },
    },
    tarifas: {
        async getAll(id, q = "", pageSize = 10, currentPage = 0) {
            const response = await fetch(apiOrigin + `/tarifas?q=${q}&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}&idevento=${id}`, pivateOptions('GET'));
            const data = await response.json();
            return data;
        },
        async createTarifa(data) {
            const response = await fetch(apiOrigin + `/tarifas`, pivateOptions('POST', data));
            const res = await response.json();
            return res;
        },
        async deleteTarifa(id) {
            const response = await fetch(apiOrigin + `/tarifas/${id}`, pivateOptions('DELETE'));
            const data = await response.json();
            return data;
        },
        async GetAllTarifasTipos() {
            const response = await fetch(apiOrigin + `/tarifas/tipo`, pivateOptions('GET'));
            const data = await response.json();
            return data;
        },
    },

    vouchers: {
        async getVouchers(idEvento, pageSize, currentPage) {
            const response = await fetch(apiOrigin + `/eventos/${idEvento}/vouchers?q&sortField=id&sortOrder=asc&limit=${pageSize}&page=${currentPage}`, pivateOptions('GET'));
            const res = await response.json();
            return res
        },

        async getVouchersByEvento(idEvento, page = 1) {
            const response = await fetch(`${apiOrigin}/eventos/${idEvento}/vouchers?q=&page=${page}`, pivateOptions('GET'));
            const data = await response.json();
            return data;
        },

        async getVoucherEvento(id) {
            const response = await fetch(apiOrigin + '/eventos/vouchers/' + id, pivateOptions('GET'));
            const data = await response.json();
            return data
        },

        async createVouchers(id, data) {
            const response = await fetch(apiOrigin + '/eventos/' + id + "/vouchers", pivateOptions('POST', data));
            const res = await response.json();
            return res;
        },

        async createVouchersExcel(idEvento, data) {
            const response = await fetch(apiOrigin + '/eventos/' + idEvento + "/crearvouchers", pivateOptions('POST', data));
            const res = await response.json();
            return res;
        },
    },

    compras: {
        async getAllCompras() {
            const response = await fetch(`${apiOrigin}/usuario/compras`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },

        async getCompra(id) {
            const response = await fetch(`${apiOrigin}/usuario/compra/${id}`, pivateOptions('GET'));
            const data = response.json();
            return data;
        }
    },

    participaciones: {
        async getParticipaciones() {
            const response = await fetch(`${apiOrigin}/participaciones`, pivateOptions('GET'));
            const data = response.json();
            return data;
        }
    },

    carrito: {
        async getCarrito() {
            const response = await fetch(`${apiOrigin}/carrito/activo`, pivateOptions('GET'));
            const data = response.json();
            return data;
        },
        async crearCarrito(data) {
            const response = await fetch(`${apiOrigin}/carrito/crear`, pivateOptions('POST', data));
            const res = response.json();
            return res;
        },
        async sumarRestarItems(data) {
            const response = await fetch(`${apiOrigin}/carrito/agregar`, pivateOptions('PUT', data));
            const res = response.json();
            return res;
        },
        async eliminarItem(id) {
            const response = await fetch(`${apiOrigin}/carrito/eliminar/${id}`, pivateOptions('DELETE'));
            const data = response.json();
            return data;
        }
    },
    media: {
        async upload(files) {
            const response = await fetch(apiOrigin + '/media/upload', mediaOptions('POST', files))
            const data = response.json();
            return data
        },
        async delete(id) {
            const response = await fetch(apiOrigin + '/media/delete', pivateOptions('DELETE', id))
            const data = response.json();
            return data
        }
    },
    email: {
        async send(data) {
            const response = await fetch(apiOrigin + '/email/send', pivateOptions('POST', data));
            const res = response.json();
            return res
        }
    },
    impresion: {
        async uploadImage(id, data) {
            const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/imageUpload`, pivateOptions("POST", data))
            const res = response.json()
            return res
        },
        async getImages(id) {
            const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/imageUpload`, pivateOptions("GET"))
            const res = response.json()
            return res
        },
        async deleteImage(id, idImage, publicID) {
            const response = await fetch(`${apiOrigin}/eventos/${id}/impresion/imageDelete/${idImage}`, pivateOptions("DELETE", publicID))
            const res = response.json()
            return res
        }
    },

    accesos: {
        async setVoucher(id, qr) {
            const response = await fetch(apiOrigin + `/eventos/${id}/voucher/${qr}`, pivateOptions('PUT'));
            const data = await response.json();
            return data;
        }
    },
    consultas: {
        async create(data) {
            const response = await fetch(publicOrigin + '/consultas', publicOptions('POST', data));
            const res = await response.json();
            return res;
        },
    }
};
