import { useEffect, useRef, useContext } from 'react';
import { Text, Transformer } from 'react-konva';
import { PaginatorContext } from '../../providers/Paginator';

const TextField = ({ textProp, isSelected, onSelect, onChange }) => {
  const textRef = useRef(null);
  const trRef = useRef(null);
  const { textArea, setTextArea, scale, dimensionContainer } = useContext(PaginatorContext);
  // let textArea = false

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([textRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected])

  return (
    <>
      <Text
        onClick={onSelect}
        onTap={onSelect}
        ref={textRef}
        {...textProp}
        draggable
        onDragMove={(e) => {
          const newX = Math.max(0, Math.min(e.target.x(), dimensionContainer.width * scale - textProp.width))
          const newY = Math.max(0, Math.min(e.target.y(), dimensionContainer.height * scale - textProp.height))
          textRef.current.x(newX);
          textRef.current.y(newY);
        }}
        onDragEnd={(e) => {
          onChange({
            ...textProp,
            x: e.target.x(),
            y: e.target.y()
          })

        }}
        onTransform={(e) => {
          const node = textRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...textProp,
            x: node.x(),
            y: node.y(),

            width: Math.max(5, node.width() * scaleX),
            height: Math.max(5, node.height() * scaleY),
            rotation: node.rotation(),
          })
          console.log(textProp);

        }}

        visible={isSelected & textArea ? false : true}

        onDblClick={(e) => {
          
          if (textProp.type === 'text') {
            setTextArea(true)
          }
        }}
      />

      {isSelected && (
        <Transformer
          id={textProp.id}
          onDblClick={(e) => {
            console.log('dblclick')
          }}
          visible={textArea ? false : true}

          onKeyDown={(e) => {
            console.log(e.key);
          }}

          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            const isOut =
            newBox.x < 0 ||
            newBox.y < 0 ||
            newBox.x + newBox.width > dimensionContainer.width*scale ||
            newBox.y + newBox.height > dimensionContainer.height*scale;
            
            if (isOut) {
              return oldBox;
            } 
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            
            return newBox;
          }}


        />


      )}

    </>
  )
}

export default TextField;