import { useEffect, useState } from "react";
import { api } from "../services/api";


const useCarrito = () => {
  const [stateCarrito, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const [carrito, setCarrito] = useState(null);
  const [itemsCarrito, setItemsCarrito] = useState([]);
  const [loadingItem, setLoadingItem] = useState({});
  const [loadingCarrito, setLoadingCarrito] = useState(false);

  const crearCarrito = async (idevento, items) => {
    setLoadingCarrito(true);
    
    try {
      const response = await api.carrito.crearCarrito({
        idevento,
        items,
      });

      if (response.status === "success") {
        setCarrito(response.data.carrito);
        setState({
          ...stateCarrito,
          apiMessage: response.message
        })
      }
      else {
        throw new Error(response.message || "Error al crear el carrito");
      }
      
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        error: error.message,
      }));
    } finally {
      setLoadingCarrito(false);

      setTimeout(() => {
        setState("");
      }, 3000);
    }
  }

  const actualizarCantidad = async (idtarifa, cantidad) => {
    // Activar el estado de carga para el item específico
    setLoadingItem(prevLoading => ({
      ...prevLoading,
      [idtarifa]: true
    }));

    try {
      const response = await api.carrito.sumarRestarItems({
        idtarifa,
        cantidad
      });

      if (response.status === "success") {
        // Actualizamos el carrito
        const carritoActualizado = carrito.map(item =>
          item.idtarifa === idtarifa ? {...item, cantidad} : item
        );
        setCarrito(carritoActualizado);
      }
      else {
        throw new Error(response.message);
      }

    } catch (error) {
      setState(prevState => ({
        ...prevState,
        error: error.message,
      }));
    } finally {
      // Desactivar el estado de carga para el item específico
      setLoadingItem(prevLoading => ({
        ...prevLoading,
        [idtarifa]: false
      }));
    }
  }

  const eliminarItem = async (idtarifa) => {
    setLoadingItem(prevLoading => ({
      ...prevLoading,
      [idtarifa]: true
    }));

    try {
      const response = await api.carrito.eliminarItem(idtarifa);
      if (response.status === "error") {
        throw new Error(response.message);
      }

    } catch (error) {
      setState(prevState => ({
        ...prevState,
        error: error.message,
      }));
    } finally {
      setLoadingItem(prevLoading => ({
        ...prevLoading,
        [idtarifa]: false
      }));
    }
  }

  const getCarrito = async () => {
    setLoadingCarrito(true);

    try {
      const response = await api.carrito.getCarrito();

      if (response.status === "success") {
        setCarrito(response.data.carrito);
        setItemsCarrito(response.data.carrito.items || []); // Aseguramos que sea un array
      }
      else {
        throw new Error(response.message);
      }
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        error: error.message,
      }));
    } finally {
      setLoadingCarrito(false);
    }
  }

  useEffect(() => {
    getCarrito();
  }, [loadingItem]);

  return {
    carrito,
    itemsCarrito,
    loadingItem,
    loadingCarrito,
    stateCarrito,
    getCarrito,
    crearCarrito,
    actualizarCantidad,
    eliminarItem
  };
}

export default useCarrito;