import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Alert, Button, Card, CardBody, CardText, CardTitle, Container } from 'reactstrap';
import Loading from '../../components/Loading';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { api } from '../../services/api';
import VolverButton from '../../components/VolverButton';
import { useTranslation } from 'react-i18next';

function CuentasBancarias() {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const history = useHistory();
  const param = useParams();
  const [t] = useTranslation("global");

  const [cuentas, setCuentas] = useState([]);
  const [totalLength, setTotalLength] = useState(0);

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.eventoCuentas.getAll(param.id);

        setCuentas(response.data.eventoCuentas);
        setTotalLength(response.data.eventoCuentas.length);

      } catch (error) {
        console.log(error)
      }
    }

    callApi();
  }, []);
  
  const handleRowClick = (cuenta) => {
    history.push(`/eventos/cuentas/${cuenta.id}`);
  }

  return (
    <Container fluid="sm">
      <Button color="success" className="d-flex align-items-center gap-2"
        onClick={() => history.push(`/eventos/${param.id}/crear-cuenta`)}
      >
        <FontAwesomeIcon icon={faAdd} className="fs-6" />
        {t("button.add")}
      </Button>

      {state.error &&
        <Alert color="danger" className="container">
          {state.error}
        </Alert>
      }

      <div className="col-lg-5 col-10 mx-auto mt-3" >
        {cuentas ? cuentas.map((cuenta, i) => (
          <Card key={i} onClick={() => handleRowClick(cuenta)} className="mb-3" style={{cursor: 'pointer'}}>
            <CardBody className="d-flex flex-wrap gap-3">
              <img className="rounded-circle align-self-center" style={{width: '4rem', height: '4rem'}}
                alt="Sample" src="https://picsum.photos/300/200"
              />
              <div className="flex-column">
                <CardTitle tag="h5">
                  {cuenta.titular}
                </CardTitle>

                <CardText className="m-0">
                  Alias: {cuenta.alias}
                </CardText>

                <CardText>
                  Cbu: {cuenta.cbu}
                </CardText>
              </div>
            </CardBody>
          </Card>)
          ) : (
            <Alert color="secondary">
              {t("accountBank.accountAlert")}
            </Alert>
          )
        }
      </div>

      <div className="d-flex justify-content-between text-secondary">          
        <small>
          {t("accountBank.totalEntries")} {totalLength}
        </small>
        
        <VolverButton/>
      </div>
    </Container>
  )
}

export default withAuthenticationRequired(CuentasBancarias, {
  onRedirecting: () => <Loading />,
});