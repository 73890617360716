import { Alert, Card } from 'reactstrap';
import Tickets  from './Tickets';
import { Vouchers } from './Vouchers';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';


export const Vales = () => {
    const [t] = useTranslation("global");

    const [view, setView] = useState(null); 

    const handleCardClick = (view) => {
        setView(view); 
    };


    return (
        <>
            <div className="d-flex justify-content-center align-items-center gap-4 mb-3">
                <Card className="d-flex flex-column justify-content-center align-items-center" style={{width: "150px", height:"70px", cursor: "pointer"}} onClick={() => handleCardClick('vouchers')}>
                    <i className="material-symbols-outlined main-menu_icon" style={{fontSize:"30px"}}>card_membership</i>
                    <span className='text'>Vouchers</span>
                </Card>
                <Card className="d-flex flex-column justify-content-center align-items-center" style={{width: "150px", height:"70px", cursor: "pointer"}} onClick={() => handleCardClick('tickets')}>
                    <i className="material-symbols-outlined main-menu_icon" style={{fontSize:"30px"}}>confirmation_number</i>
                    <span className='text'>{t("menuTemplate.tickets")}</span>
                </Card>
            </div>

            {view === 'vouchers' && <Vouchers />}
            {view === 'tickets' && <Tickets />}
        </>
    )
}

