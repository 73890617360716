import {useEffect, useState} from 'react';
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import {useParams} from 'react-router-dom';
import {Alert,Container,Row,Col} from "reactstrap";
import {api} from "../../services/api"
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/Loading';
import VolverButton from '../../components/VolverButton';
import { useTranslation } from 'react-i18next';

const Voucher = () => {
  const param = useParams();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const [data, setData] = useState();
  const [t] = useTranslation("global");

  useEffect(()=>{
    const callApi = async () =>{
      try {
        const response = await api.vouchers.getVoucherEvento(param.idvoucher);
        if(response.status === "success"){
          const res = response.data.voucher
          setData(res);
        }
      } catch (error) {
        setState( {
          ...state,
          error: "Error en la Red.",
        });
      }
    };
    callApi();
  },[]);


  return (
    <Container fluid="sm" style={{marginLeft:"10px", marginTop:"20px"}}>
      <Row className="d-flex justify-content-between text-left">
        <Col>
          <div className="mb-5">
            <div className="w-100 d-flex flex-column p-auto align-items-center">
              {data ? (
                <div key={param.idvoucher} className=" d-flex flex-column align-item-center mb-3 border align-items-center p-4">
                  {data.codigo &&
                    <div className="w-100 d-flex justify-content-center p-4">
                      <QRCode value={data.codigo} style={{height: "auto",maxWidth: 70, width:"100%"}} />
                    </div>}

                  {!data.codigo &&
                    <Alert color="info">No hay código.<br/>
                    Comuníquese con soporte</Alert>
                  }
                  <div className="w-100 d-flex flex-column align-item-center">
                    <p className="m-0 text-secondary">ID: {data.id}</p>
                    <p className="m-0 ">Nombre: {data.nombre}</p>
                    <p className="m-0 ">Apellido: {data.apellido}</p>
                  </div>
                </div>):(
                  <Alert color="info">Hubo un error en la obtención del voucher. Por favor comuniquese con el desarrollador.</Alert>
                )
              }
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <br />
        <VolverButton></VolverButton>
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(Voucher, {
  onRedirecting: () => <Loading />,
});