import { useState, useEffect, useContext } from "react";
import { api } from "../../services/api";
import Loading from "../../components/Loading";
import { Alert, Container, Row, Col, Table, Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Pagination, PaginationItem, PaginationLink, FormGroup, Input, Label, Spinner } from "reactstrap";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPrint, faSearch, faXmark, faPlus, faAdd } from "@fortawesome/free-solid-svg-icons";
import { PaginatorContext } from "../../providers/Paginator";
import VolverButton from "../../components/VolverButton";
import { useTranslation } from "react-i18next";
import { ExcelExport } from "./ExcelExport";



const Tickets = () => {
  const {
    query,
    setQuery,
    totalLength,
    setTotalLength,
    currentPage,
    setCurrentPage,
    pageSize,
    categoriaSelected,
    setCategoriaSelected
  } = useContext(PaginatorContext);

  const history = useHistory();
  const param = useParams()
  const [t] = useTranslation("global");
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const [data, setData] = useState([]);
  const [evento, setEvento] = useState();
  const [categoria, setCategoria] = useState([]);

  const [falseQuery, setFalseQuery] = useState(query);


  let limitPages = 5;
  let totalPages = Math.max(Math.ceil(totalLength / pageSize), 1);
  let startPage = Math.max(currentPage - Math.floor(limitPages / 2), 1);
  let endPage = Math.min(startPage + limitPages - 1, totalPages);

  if (endPage - startPage + 1 < limitPages && startPage > 1) {
    startPage = Math.max(endPage - limitPages + 1, 1);
  }

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await api.participaciones.getParticipaciones();

        if (response.status === "success") {
          const rol = response.data.participaciones.find((p) => p.idevento == param.id)?.idrol;
          setUserRole(rol);
        } else {
          setState({ ...state, error: "Error al obtener el rol." });
        }
      } catch (error) {
        setState({ ...state, error: "Error en la red." });
      }
    };
    fetchRole([]);
  }, [param.id])

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);

      try {
        const response = await api.eventos.getTicketByEvento(param.id, categoriaSelected, query, currentPage);

        if (response.status === "success") {
          const res = response.data.tikets;
          setData(res);
          setEvento(response.data.evento)

          if (response.data.totalDataSize) {
            setTotalLength(response.data.totalDataSize);
          }
          else {
            if (currentPage == 1) {
              setTotalLength(0);
            }
          }
        }
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      } finally {
        setLoading(false);
      }
    };
    callApi();
  }, [categoriaSelected, query, currentPage, totalLength]);

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.categorias.getAll(param.id);
        if (response.status === "success") {
          setCategoria(response.data.categorias);
        }

      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    }
    callApi();
  }, []);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const selectCategoria = (id) => {
    setCategoriaSelected(id);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.q.value);
    setCurrentPage(1);
  }

  const handleOnChangeSearch = (e) => {
    if (!e.target.value) setQuery('');
    setFalseQuery(e.target.value);
  }

  const paginationItems = [];
  for (let page = startPage; page <= endPage; page++) {
    paginationItems.push(
      <PaginationItem active={page === currentPage} key={page}>
        <PaginationLink onClick={() => handleChangePage(page)}>
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  }

  const [showSearch, setShowSearch] = useState();
  const handleToggleSearch = () => {
    setShowSearch(!showSearch); // Cambia el estado de visibilidad del campo de búsqueda
    setFalseQuery(''); // Limpia el campo de búsqueda al mostrarlo
  };


  return (
    <>
      {evento && (
        <Container fluid="sm">
          <div className="d-flex justify-content-between">
            {userRole === 1 && (
              <Button color="success"
                className="d-flex align-items-center gap-2"
                onClick={() => history.push("/eventos/" + param.id + "/nuevoTicket")}
              >
                <FontAwesomeIcon icon={faAdd} className="fs-6" />
                {t("tickets.newTicket")}
              </Button>
            )}
            <form onSubmit={(e) => e.preventDefault()}>
              {showSearch ? (
                <div className="w-100 d-flex gap-2">
                  <Input
                    id="exampleSearch"
                    name="q"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Buscar.."
                    type="search"
                  />
                  <Button onClick={() => setShowSearch(false)} color="light">
                    <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
                  </Button>
                </div>
              ) : (
                <Button color="secondary" onClick={handleToggleSearch}>
                  <FontAwesomeIcon icon={faSearch} className="fs-6" />
                </Button>
              )}
            </form>
          </div>

          <Row className="d-flex justify-content-between text-left">
            <Col>
              {state.error &&
                <Alert color="danger">
                  {state.error}
                </Alert>
              }
              <Table hover responsive size="sm" className="mt-3">
                <thead>
                  <tr key={0} className="table-light">
                    <th>
                      {t("tickets.identification")}
                    </th>
                    <th>
                      {t("tickets.code")}
                    </th>
                    <th>
                      <UncontrolledDropdown>
                        <DropdownToggle caret className="nav-link" tag="a">
                          {t("categorie.categorie")}
                        </DropdownToggle>
                        <DropdownMenu>
                          {categoria && categoria.length > 0 &&
                            <DropdownItem onClick={() => (selectCategoria())}>Todas</DropdownItem>
                          }
                          {categoria && categoria.length > 0 ? (categoria?.map((cat) => (
                            <DropdownItem onClick={() => (selectCategoria(cat.id))} key={cat.id}>{cat.categoria}</DropdownItem>
                          ))

                          ) : (
                            <DropdownItem>{t("tickets.notFoundCategories")}</DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </th>
                    <th>
                      {t("tickets.name")}
                    </th>
                    <th>
                      {t("tickets.lastName")}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={5} align="center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  ) : (
                    data && data.length > 0 ? (
                    data?.map((ticket) => (
                      <tr key={ticket.id} >
                        <td>
                          <div className="d-flex align-items-center gap-1 py-2">
                            <Button color="primary" size="sm" onClick={() => history.push(`/eventos/${param.id}/tickets/${ticket.id}`)}>
                              <FontAwesomeIcon icon={faPrint} size="lg" />
                            </Button>
                            {ticket.id}
                          </div>
                        </td>
                        <td>
                          {ticket.codigo}
                        </td>
                        <td>
                          {ticket?.categoria?.categoria}
                        </td>
                        <td>
                          {ticket.nombre}
                        </td>
                        <td>
                          {ticket.apellido}
                        </td>
                      </tr>))
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <Alert color="secondary" className="m-0">
                          {t("tickets.ticketsAlert")}
                        </Alert>
                      </td>
                    </tr>)
                  )}
                </tbody>
              </Table>

              <Row>
                <Col xs="auto">
                  <small className="text-left">
                    {t("tickets.totalEntries")} {totalLength}
                  </small>
                </Col>
                <Col xs="auto" className="mx-auto">
                  <Pagination className="pagination justify-content-center" aria-label="Page navigation example">
                    <PaginationItem disabled={currentPage <= 1} >
                      <PaginationLink
                        first
                        href=""
                        onClick={() => handleChangePage(1)}
                      />
                    </PaginationItem>

                    <PaginationItem disabled={currentPage <= 1} >
                      <PaginationLink
                        previous
                        href=""
                        onClick={() => handleChangePage(currentPage - 1)}
                      />
                    </PaginationItem>

                    {paginationItems}

                    <PaginationItem disabled={currentPage >= totalPages} >
                      <PaginationLink
                        next
                        href=""
                        onClick={() => handleChangePage(currentPage + 1)}
                      />
                    </PaginationItem>

                    <PaginationItem disabled={currentPage >= totalPages} >
                      <PaginationLink
                        last
                        href=""
                        onClick={() => handleChangePage(totalPages)}
                      />
                    </PaginationItem>
                  </Pagination>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <VolverButton />
          </div>
        </Container>
      )}
    </>
  )
};

export default withAuthenticationRequired(Tickets, {
  onRedirecting: () => <Loading />,
});