import { useContext, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Alert, Button } from 'reactstrap';
import Canva from '../../components/Canva';
import FontSideBar from '../../components/Impresion/FontSideBar';
import ImageSideBar from '../../components/Impresion/ImageSideBar';
import NavCanva from '../../components/Impresion/NavCanva';
import QrSideBar from '../../components/Impresion/QrSideBar';
import TemplateSideBar from '../../components/Impresion/TemplateSideBar';
import { PaginatorContext } from '../../providers/Paginator';

const Impresion = (setToggle = true) => {
  const { setIsToggled,
    event,
    font,
    qrCanva,
    image,
    templates,
    scale,
    setScale,
    dimensionContainer,
    fontToolBar,
    saved,
    edited,
    error,
    setSaved,
    setEdited,
    setError,
  } = useContext(PaginatorContext);

  const param = useParams();
  const history = useHistory();

  useEffect(() => {
    setIsToggled(setToggle)
    const header = document.getElementsByTagName('header')
    for (let i = 0; i < header.length; i++) {
      header[i].style.display = 'none';
    }
  }, [])

  const HandleBackward = () => {
    setIsToggled(false)
    const header = document.getElementsByTagName('header')
    for (let i = 0; i < header.length; i++) {
      header[i].style.display = 'block';
    }
    history.push(`/eventos/${param.id}`)
  }

  const handdleBackward = window.addEventListener('popstate', HandleBackward);





  return (
    <>
      <div className='container-fluid d-flex p-0 m-0 h-100 bg-body-secondary' on>
        <div className='position-absolute d-flex justify-content-center align-items-center' style={{ zIndex: 3, top: '90px', left: '50%' }}>
          <Button color='link' className='text-capitalize fs-5 text-decoration-none' onClick={() => HandleBackward()}>{event?.toLowerCase()}</Button>
        </div>
        <div className='h-100 d-flex flex-column justify-content-center align-items-center' style={{ 'width': '85px' }}>
          <NavCanva />
        </div>
        <div className='position-relative d-flex h-100 z-1000' style={{ zIndex: 3, transform: font ? 'translateX(5px)' : 'translateX(-600px)', transition: 'transform 0.6s ease' }} >
          <FontSideBar />
        </div>
        <div className='position-relative h-100 z-1000' style={{ zIndex: 3, transform: image ? 'translateX(5px)' : 'translateX(-600px)', transition: 'transform 0.6s ease' }} >
          <ImageSideBar />
        </div>
        <div className='position-relative h-100 z-1000' style={{ zIndex: 3, transform: qrCanva ? 'translateX(5px)' : 'translateX(-600px)', transition: 'transform 0.6s ease' }} >
          <QrSideBar />
        </div>
        <div className='position-relative h-100 z-1000' style={{ zIndex: 3, transform: templates ? 'translateX(5px)' : 'translateX(-600px)', transition: 'transform 0.6s ease' }} >
          <TemplateSideBar />
        </div>
        <div className='w-100 h-100 bg-body-secondary d-flex justify-content-center align-items-center overflow-hidden'
          style={{ zIndex: '2', position: 'relative' }}
        >
          {saved &&
            <Alert className='position-absolute flex-grow-1 d-flex align-items-center' style={{ zIndex: 10, top: '53px', right: '10px', }} >
              Guardado con exito
              <button className='d-flex align-items-center' onClick={() => setSaved(false)}>
                <i className='material-symbols-outlined' >close</i>
              </button>
            </Alert>
          }
          {edited &&
            <Alert className='position-absolute flex-grow-1 d-flex align-items-center' style={{ zIndex: 10, top: '53px', right: '10px', }}>
              Actualizacion con exito
              <button className='d-flex align-items-center' onClick={() => setEdited(false)}>
                <i className='material-symbols-outlined' >close</i>
              </button>

            </Alert>
          }
          {error &&
            <Alert color='danger' className='position-absolute flex-grow-1 d-flex align-items-center' style={{ zIndex: 10, top: '53px', right: '10px', }}>
              Error al actualizar
              <button className='d-flex align-items-center' onClick={() => setError(false)}>
                <i className='material-symbols-outlined' >close</i>
              </button>
            </Alert>
          }
          <Canva width={(dimensionContainer.width) * scale} height={(dimensionContainer.height) * scale} />
        </div>

        <ToastContainer position="bottom-center" />

        <style>
          {`
          @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
          
          .texto-roboto {
            font-family: 'Roboto', sans-serif;
          }
          .texto-merriweather {
            font-family: 'Merriweather', serif;
          }
          .texto-pacifico {
            font-family: 'Pacifico', cursive;
          }
          .texto-oswald {
            font-family: 'Oswald', sans-serif;
          }
          .texto-playfairDisplay {
            font-family: 'Playfair Display', serif;
          }
          .texto-lobster {
            font-family: 'Lobster', cursive;
          }
          .texto-anton {
            font-family: 'Anton', sans-serif;
          }
          .texto-indieFlower {
            font-family: 'Indie Flower', cursive;
          }
          .texto-abrilFatface {
            font-family: 'Abril Fatface', serif;
          }
          .texto-fjallaOne {
            font-family: 'Fjalla One', sans-serif;
          }

          header{
            display: none;
          }
        `}
        </style>




      </div>
    </>
  )
}

export default Impresion;