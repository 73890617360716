import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { Alert, Button, Label, Form, FormGroup, Input, InputGroup, InputGroupText, Row, Col, Container } from 'reactstrap';
import { useTranslation } from "react-i18next";


export const CrearVoucher = () => {
    const [state, setState] = useState({
        showResult: false,
        apiMessage: "",
        error: null,
    });
    const [tarifas, setTarifas] = useState();
    const [tarifasTipo, setTarifasTipo] = useState([])
    const [importe, setImporte] = useState()
    const [tarifaSeleccionada, setTarifaSeleccionada] = useState('');
    const param = useParams();
    const history = useHistory();
    const [t] = useTranslation("global");
    const [enviado, setEnviado] = useState(false);

    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        idtarifa: '',
        cantidadtarifas: '1',
        importe: '',
        cantidadvouchers: '1'
    })

    useEffect (() => {
        const fetchTarifa = async () => {
            try {
                let totalTarifas = [];
                let currentPage = 1;
                let hasMoreTickets = true;
                let filtro = '';
          
                while (hasMoreTickets) {
                    const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
                    if (response.status === "success") {
                        const tarifas  = response.data.tarifas; 
            
                        if (tarifas && tarifas.length > 0) {
                            totalTarifas = [...totalTarifas, ...tarifas];
                            currentPage++;
                        } else {
                            hasMoreTickets = false;
                        }
                    }
                    setTarifas(totalTarifas)
                }    
            } catch (error) {
                setState({
                    ...state,
                    error: error.error,
                });
            }
        }
        fetchTarifa();
    },[param.id]);

    const consumicionTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 2);


    const [unidad, setUnidad] = useState('');
    useEffect(() => {
        const selectedTarifa = tarifas?.find((tarifa) => tarifa.id === parseInt(formData.idtarifa));
        setUnidad(selectedTarifa ? selectedTarifa.unidad : '');
        setTarifaSeleccionada(selectedTarifa ? selectedTarifa.concepto : '');

        if (selectedTarifa && formData.cantidadtarifas) {
            const cantidad = parseInt(formData.cantidadtarifas);
            const precio = selectedTarifa.precio;
            setImporte(cantidad * precio);
        } else {
            setImporte(0);
        }
    }, [formData.idtarifa, formData.cantidadtarifas, tarifas]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    const idTarifa = parseInt(formData.idtarifa);
    const cantidadTarifas = parseInt(formData.cantidadtarifas);
    const cantidadVouchers = parseInt(formData.cantidadvouchers);

    const [errors, setErrors] = useState({});
    const handleSubmit = async (e) => { 
        e.preventDefault();
        let validationErrors = {};
    
        if (formData.nombre === '') {
          validationErrors.nombre = 'Por favor ingresar un nombre';
        }
        
        if (formData.idtarifa === '') {
          validationErrors.idtarifa = 'Por favor seleccionar una tarifa';
        }
        
    
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
    
        const data = {
            voucher: {
              nombre: formData.nombre,
              apellido: formData.apellido,
              idtarifa: idTarifa,
              cantidad: cantidadTarifas,
              importe: importe
            },
            cantidad: cantidadVouchers,
          };

        try { 
            const response = await api.vouchers.createVouchers(param.id, data);
            if (response.status === 'success') { 
              setEnviado(true);
              setTimeout(() => {
                history.push(`/eventos/${param.id}`);
              }, 4000);
            } 
            else { console.error('Error al crear el voucher:', response.error); 
            } 
        } catch (error) { 
        console.error('Error en la solicitud:', error.message); 
        } 
        console.log(data);
        console.log('Datos enviados a la API:', data); 
    }
  return (
    <>
    <Container fluid="sm" className=" d-flex justify-content-center flex-wrap">
        <div className="body p-4 border border-dark-subtle mt-2" style={{width: '800px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderradius: '8px'}}>

        <Form onSubmit={handleSubmit}>
        <Row>
        <Col md={6}>
            <FormGroup floating>
                <Input
                    id="nombre"
                    name="nombre"
                    placeholder="Email"
                    value={formData.nombre}
                    onChange={handleChange}
                />
                <Label>Nombre</Label>
                {errors.nombre && <span className="text-danger">{errors.nombre}</span>}
            </FormGroup>
            </Col>
            <Col md={6}>
            <FormGroup floating>
                <Input
                    id="apellido"
                    name="apellido"
                    placeholder="Apellido"
                    value={formData.apellido}
                    onChange={handleChange}
                />
                <Label>Apellido</Label>
            </FormGroup>
            </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <FormGroup floating>
                        <Input 
                            id="idtarifa" 
                            name="idtarifa" 
                            type="select" 
                            value={formData.idtarifa} 
                            onChange={handleChange}>
                            <option value="" hidden>
                                Selecciona una tarifa
                            </option>
                            
                            {consumicionTarifas?.map((tarifa) => (
                                <option key={tarifa.id} value={tarifa.id}>
                                    {tarifa.concepto}
                                </option>
                            ))}
                        </Input>
                        <Label>Tarifas</Label>
                        {errors.idtarifa && <span className="text-danger">{errors.idtarifa}</span>}
                    </FormGroup> 
                </Col>
                <Col md={6}>
                    <InputGroup>
                        <Input 
                            name="cantidadtarifas" 
                            type="number"
                            value={formData.cantidadtarifas} 
                            min={1}
                            style={{height: '58px'}}
                            onChange={handleChange}
                        />
                        {unidad && (
                            <InputGroupText>
                            {unidad}
                            </InputGroupText>
                        )}
                    </InputGroup>
                </Col>
            </Row>
            <FormGroup floating >
                  <Input id="cantidadvouchers" min={1} name="cantidadvouchers" type="number" value={formData.cantidadvouchers} onChange={handleChange}/>
                  <Label>{t("vouchers.numberOfVouchers")}</Label>
                </FormGroup>
            {enviado && ( 
              <div className="mt-3">
                <Alert>{t("vouchers.voucherCreated")}</Alert>
              </div>
            )}
            <div className="d-flex justify-content-end mt-4">
              <Button color="secondary" className='mr-3' onClick={() => history.push("/eventos/"+ param.id)}>Cancelar</Button>
              <Button color="primary" type="submit">Enviar</Button>
            </div>
        </Form>
        </div>

        <div style={{width: '300px', height: 'fit-content', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderradius: '8px'}} className="ml-4 py-4 px-3 border border-dark-subtle mt-2">
            <h5 style={{textAlign: "center"}}>{t("tickets.summary")}</h5>
            <hr/>
            { tarifaSeleccionada && formData.cantidadtarifas > 0 && (
              <p>{unidad} {tarifaSeleccionada} x {formData.cantidadtarifas}</p>
            )}
            <div className="d-flex justify-content-between" style={{backgroundColor: '#ededed', padding: '4px 7px'}}>
                <h4 style={{margin: '0px'}}>Total:</h4>
                <h4 style={{margin: '0px'}}>
                    {importe === 0 || !tarifaSeleccionada || !formData.cantidadtarifas
                        ? '0'
                    : 
                        `$${importe}`
                    }  
                </h4>
            </div>
        </div>
        <div style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderradius: '8px'}} className=" py-4 px-3 border border-dark-subtle mt-4">
          <h>{t("tickets.attention")}</h>
          <div className="d-flex flex-column">
            <p>{t("tickets.import")}</p>
            <Button className="align-self-end" color="success" onClick={() => history.push("/eventos/"+param.id+"/nuevoVoucher/importarExcel") }>{t("tickets.upload")}</Button>
          </div>
        </div>
        </Container>
    </>
  )
}
