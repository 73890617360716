import { useRef, useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { Stage, Layer } from 'react-konva';
import TextField from './Impresion/TextField';
import { PaginatorContext } from '../providers/Paginator';
import QRField from './Impresion/QRField';
import ImageField from './Impresion/ImageField';
import { api } from '../services/api';
import { useParams } from 'react-router-dom';

const Canva = ({ width, height }) => {
  const {
    setState,
    state,
    scale,
    setEditSucces,
    setIndex,
    isCreated,
    setIsCreated,
    textArea,
    setTextArea,
    setOpenFont,
    setOpenImage,
    setOpenQr,
    setOpenTemplates,
    setOpenFontToolBar,
    elements,
    setElements,
    selectedId,
    setSelectedId,
    setSaved,
    setEdited,
    setError,
    dimensionContainer,
    setDimensionContainer,
    setScale,
    history,
    setHistory,
    setIsToggled,
    indexHistory,
    setIndexHistory,
    uploadHistory,
    ableUndo,
    setAbleUndo,
    ableRedo,
    setAbleRedo,
  } = useContext(PaginatorContext);



  const stage = useRef(null);
  const textAreaRef = useRef(null)
  const layerRef = useRef(null);


  const param = useParams()

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
      setOpenFontToolBar(false);
      setOpenFont(false);
      setOpenImage(false)
      setOpenQr(false)
      setOpenTemplates(false)
      setTextArea(false)
    }
  };

  const HandleDeleteElement = (e) => {
    if (selectedId) {
      const newElements = elements.filter((element) => element.id !== selectedId);
      setElements(newElements);
      setSelectedId(null);
      setTextArea(false);
      setOpenFontToolBar(false);

    }
  }

  const HandleZIndex = (num) => {
    const index = elements?.findIndex((element) => element.id === selectedId)
    const newElements = elements.slice();
    const element = newElements[index];
    newElements.splice(index, 1);
    newElements.splice(index + num, 0, element);
    setElements(newElements);
  }

  const SaveStage = () => {
    let stringDimensiones = '';
    console.log(elements);

    const newElements = elements?.map((element) => {
      return {
        ...element,
        x: element.x / scale,
        y: element.y / scale,
        width: element.width / scale,
        height: element.height / scale,
        fontSize: element.fontSize / (0.3625 * scale)
      }
    })

    let data = {
      type: dimensionContainer.type,
      elements: newElements
    }

    const callApi = async () => {
      try {
        if (!(data instanceof String)) {
          stringDimensiones = JSON.stringify(data)
        }
        const response = await api.eventos.createImpresion(param.id, stringDimensiones);
        if (response.status === "success") {
          setIsCreated(true)//estado maneja el mostrados de botones Guardar o Editar
          setSaved(true);//estado que maneja el alert
        }

      } catch (error) {
        setState({
          ...state,
          error: error
        });
      }
    }
    callApi();
    console.log('Se guardo con exito');

  }

  const HandleEdit = () => {
    let stringDimensiones = ''

    console.log(elements);


    let newElements = elements?.map((element) => {
      return {
        ...element,
        x: element.x / scale,
        y: element.y / scale,
        width: element.width / scale,
        height: element.height / scale,
        fontSize: element.fontSize / (0.3625 * scale)
      }
    })

    let data = {
      type: dimensionContainer.type,
      elements: newElements
    }

    const callApi = async () => {
      try {
        if (!(data instanceof String)) {
          stringDimensiones = JSON.stringify(data)
        }

        const response = await api.eventos.updateImpresion(param.id, stringDimensiones);

        if (response.status === "success") {
          setEdited(true)//estado que maneja el alert
        } else {
          setError(true)
        }

      } catch (error) {
        setError(true)
        setState({
          ...state,
          error: error
        });
      }
    }
    callApi();

    console.log('Se realizó la actualizacion con exito');
  }

  const HandleDownload = () => {
    const callApi = async () => {
      try {
        const response = await api.eventos.downloadImpresion(param.id);
        console.log(response);

        if (!response) {
          throw new Error('Error al descargar el PDF');
        }
        const blob = await response;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `impresion_${param.id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        console.log('Se descargó con éxito');


      } catch (error) {
        console.error('Error al descargar el PDF:', error.message);
        throw error;
      }
    }
    callApi()
    console.log('Se descargó con exito');
  }



  useEffect(() => {
    setIsToggled(true)
    const callApi = async () => {
      try {
        const response = await api.eventos.getImpresion(param.id);
        setScale(6)
        if (response.status === "success") {
          const data = JSON.parse(response.data.impresion.json);
          if (data) {
            const newData = data.elements?.map((element) => {
              return {
                ...element,
                x: element.x,
                y: element.y,
                width: element.width,
                height: element.height,
                fontSize: element.fontSize
              }
            })
            setElements(newData)
            if (data.type === 'voucher') {
              setDimensionContainer({ type: 'voucher', width: 100, height: 50 })
              setScale(6)
            } else if (data.type === 'tarjeta') {

              setDimensionContainer({ type: 'tarjeta', width: 100, height: 50 })
              setScale(6)
            } else if (data.type === 'pulsera') {
              setDimensionContainer({ type: 'pulsera', width: 200, height: 17 })
              setScale(4) //revisar incluir este valor en el estado
            } else {
              throw new Error('Error al cargar la plantilla')
            }
            setIndex(1 + parseInt(data.elements?.reduce((max, element) => element.id > max ? element.id : max, 0)))//encuentra el id mas grande y le suma 1
            setIsCreated(true)
          }
        }

      } catch (error) {
        setState({
          ...state,
          error: error
        });
      }
    }
    callApi();
  }, [])

  useEffect(() => {
    const newData = elements?.map((element) => {
      return {
        ...element,
        x: element.x * scale,
        y: element.y * scale,
        width: element.width * scale,
        height: element.height * scale,
        fontSize: element.fontSize * (0.3625 * scale)
      }
    })
    setElements(newData)

    setHistory([newData])

  }, [scale])



  const HandleUndo = () => {
    setAbleRedo(true)
    if (indexHistory >= history.length - 1) {
      setAbleUndo(false)
      return // No puedes deshacer más allá del inicio del historial
    }
    // Calcula el nuevo índice de historial
    const newIndexHistory = indexHistory + 1

    // Accede al estado correspondiente en el historial
    const newElements = history[history.length - 1 - newIndexHistory]

    // Actualiza el estado con el nuevo valor
    setElements(newElements)
    setIndexHistory(newIndexHistory)
  }



  const HandleRedo = () => {
    // setAbleUndo(true)
    if (indexHistory <= 0) {
      setAbleRedo(false)
      return // No puedes rehacer más allá del final del historial
    }

    // Calcula el nuevo índice de historial
    const newIndexHistory = indexHistory - 1;

    // Accede al estado correspondiente en el historial
    const newElements = history[history.length - 1 - newIndexHistory];

    // Actualiza el estado con el nuevo valor
    setElements(newElements);
    setIndexHistory(newIndexHistory);
  };



  return (
    <>
      <div className='position-absolute' style={{ zIndex: 10, top: '10px', right: '10px' }}>
        {isCreated ? (
          <div className='d-flex gap-1'>
            <div className='d-flex pr-1 gap-1'>
              <button
                className={`d-flex btn align-items-center btn btn-dark ${ableUndo ? '' : 'disabled'} `}
                onClick={HandleUndo}
              >
                <i className='material-symbols-outlined p-0 m-0'>undo</i>
              </button>
              <button
                className={`d-flex btn align-items-center btn btn-dark ${ableRedo ? '' : 'disabled'}`}
                onClick={HandleRedo}
              >
                <i className='material-symbols-outlined p-0 m-0'>redo</i>
              </button>
            </div>
            <Button color='primary' onClick={HandleEdit} type='button' className=''>Editar</Button>
            <Button color='primary' outline onClick={HandleDownload} type='button' className=''>Descargar PDF</Button>
          </div>
        ) : (
          <Button color='primary' onClick={SaveStage} type='button' className=''>Guardar</Button>
        )
        }
      </div>
      <div style={{ paddingLeft: '300px' }}>

        <div className='bg-body position-relative'
          style={{ border: '2px dashed gray' }}
        >
          <Stage
            width={width}
            height={height}
            ref={stage}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
          >
            <Layer ref={layerRef}>

              {elements?.map((element) => {
                if (element.type === 'image') {
                  return (
                    <ImageField
                      key={element.id}
                      imgProp={element}
                      isSelected={element.id === selectedId}

                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(false);
                      }}

                      onChange={(newAttrs) => {
                        const newElements = elements.slice();
                        const index = elements?.findIndex((i) => i.id === element.id)

                        newElements[index] = newAttrs;
                        setElements(newElements);
                        uploadHistory(newElements)
                      }}
                    />
                  )
                }
                if (element.type === 'qr') {
                  return (
                    <QRField
                      key={element.id}
                      qrProp={element}
                      isSelected={element.id === selectedId}

                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(false);
                      }}

                      onChange={(newAttrs) => {
                        const newQrField = elements.slice();
                        const index = elements?.findIndex((q) => q.id === element.id)
                        newQrField[index] = newAttrs;
                        setElements(newQrField);
                        uploadHistory(newQrField)
                      }}
                    />
                  )
                }
                if (element.type === 'text' | element.type === 'text-evento' | element.type === 'text-codigo' | element.type === 'text-nombre') {
                  return (
                    <TextField
                      key={element.id}
                      textProp={element}
                      isSelected={element.id === selectedId}
                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(true);
                        setOpenImage(false);
                        setOpenQr(false);
                        setOpenTemplates(false);
                      }}
                      onChange={(newAttrs) => {
                        const newTextField = elements.slice();
                        const index = elements?.findIndex((t) => t.id === element.id)
                        newTextField[index] = newAttrs;
                        setElements(newTextField)
                        uploadHistory(newTextField)
                      }}
                    />
                  )
                }
                return null
              })
              }
            </Layer>
          </Stage>

          {textArea && (
            elements?.map((text) => {
              let condition = text.type === "text" ||
                text.type === "text-evento" ||
                text.type === "text-codigo" ||
                text.type === "text-nombre"

              if (condition && text.id === selectedId) {
                return (
                  < textarea
                    key={text.id}
                    id={text.id}
                    ref={textAreaRef}
                    value={text.text}
                    style={{
                      position: 'absolute',
                      top: `${text.y}px`,
                      left: `${text.x}px`,
                      width: `${text.width}px`,
                      height: `${text.height}px`,
                      border: 'none',
                      padding: '0px',
                      margin: '0px',
                      fontSize: `${text.fontSize}px`,
                      outline: 'true',
                      background: 'none',
                      transform: ` rotate(${text.rotation}deg)`,
                      fontFamily: `${text.fontFamily}`,
                      color: `${text.fill}`,
                      lineHeight: 1,
                      fontWeight: `${text.fontStyle === ('bold' | 'bold italic') ? 'bold' : 'normal'} `,
                      fontStyle: `${text.fontStyle === ('italic' | 'bold italic') ? 'italic' : 'normal'} `,
                      textDecoration: `${text.textDecoration}`,
                    }}

                    onChange={(e) => {
                      const newTextField = elements.slice()
                      const index = elements?.findIndex((t) => t.id === e.target.id)
                      newTextField[index] = { ...newTextField[index], text: e.target.value }
                      setElements(newTextField)
                      uploadHistory(newTextField)
                    }}

                    onKeyDown={
                      (e) => {
                        if (e.key === 'Enter') {
                          setTextArea(false)
                        }
                      }
                    }
                  />
                )
              }
              return null
            })
          )}

          {selectedId && elements &&
            (
              elements?.map((element) => {
                if (element.id === selectedId) {
                  return (
                    <div className='position-absolute p-1 w-auto h-auto rounded-3 border d-flex bg-light align-items-center gap-2'
                      style={{
                        top: `${element.height + element.y + 12}px`,
                        left: `${element.width / 2 + element.x - 64}px`,
                      }}
                    >
                      <button className='btn btn-danger p-0' onClick={HandleDeleteElement}>
                        <div className='d-flex align-items-center p-1'><i className='material-symbols-outlined' >delete</i></div>
                      </button>
                      <button className='btn btn-outline-secondary p-0' onClick={() => HandleZIndex(1)}>
                        <div className='d-flex align-items-center p-1'><i className="material-symbols-outlined">stat_2</i></div>
                      </button>
                      <button className='btn btn-outline-secondary p-0' onClick={() => HandleZIndex(-1)}>
                        <div className='d-flex align-items-center p-1'><i className="material-symbols-outlined">stat_minus_2</i></div>
                      </button>
                    </div>
                  )
                }
                return null
              }
              )
            )
          }
        </div>
      </div>
    </>
  );
};

export default Canva;