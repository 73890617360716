import React, { useState,useEffect,useRef } from "react";
import Loading from "../../components/Loading";
import {api} from "../../services/api"
import {useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import  "./Styles.css"
import moment from 'moment';
import QrReader from 'react-weblineindia-qrcode-scanner'
import { Container,Row,Col,ListGroup,Button,Card,CardImg,CardBody,ListGroupItem,CardTitle,Alert, CardSubtitle } from "reactstrap";
import {  withAuthenticationRequired } from "@auth0/auth0-react";

export const QrVoucher = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const beepSoundOkRef = useRef(null);
  const beepSoundErrRef = useRef(null);
  const [evento, setEvento] = useState();
  const [voucher, setVoucher] = useState();
  const [acceso, setAcceso] = useState();
  const [success, setSuccess] = useState();
  const [scan, setScan] = useState(true);
  const [alerta, setAlerta] = useState();

  const [qr, setQr] = useState();
  const param = useParams();

  const callApi = async () => {
    try {
      const response = await api.eventos.get(param.id);

      if(response.status === "success") {
        setEvento(response.data.evento);
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };
  
  useEffect(() => {
    beepSoundOkRef.current = new Audio('/ok.mp3');
    beepSoundOkRef.current.preload = 'auto';
    beepSoundErrRef.current = new Audio('/error.wav');
    beepSoundErrRef.current.preload = 'auto';
    callApi();
  }, []);

  const callQr = async (data) => {
    setQr(data);

    const response = await api.accesos.setVoucher(param.id, data);
    console.log(data)
    console.log(response)

    if(response.status === "success") {
      await beepSoundOkRef.current.play();
      setSuccess(true);
      setAcceso(response.data.acceso_voucher);
      setVoucher(response.data.acceso_voucher.voucher);
    }
    else {
      await beepSoundErrRef.current.play();
      setSuccess(false);
      setAcceso(response.data.acceso_voucher);
      setVoucher(response.data.acceso_voucher.voucher);
    }
  };

  const previewStyle = {
    width:"100%",
    height: "40vh",
  }

  const handleScan = (data) => {
    if (data) {
      setScan(false);
      callQr(data);
    } 
  }

  const handleError = (err) => {
    console.error(err);
  }

  return (
    <>
      {evento &&
        <Container fluid="sm" className="d-flex justify-content-center">
          {scan &&
            <QrReader                  
              delay={100}
              legacyMode={false}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
            />
          } 
          {voucher &&
            <Card className="my-2 d-flex justify-content-center" color="light" outline>
              {success
                ?
                <CardImg
                  alt="OK"
                  src="/qrverde.png"
                  top
                  style={{width: '300px'}}
                />                    
                :
                <CardImg
                  alt="Error"
                  src="/qrrojo.png"
                  top
                  style={{width: '300px'}}
                /> 
              }
              <CardBody>
                <CardTitle tag="h5">
                  {qr}
                </CardTitle>
                
                <CardSubtitle tag="h5">
                  {voucher.apellido} {voucher.nombre}
                </CardSubtitle>
              </CardBody>

              <ListGroup flush>
                {acceso &&
                  <ListGroupItem>
                    Reclamado: {acceso.reclamado} {moment.parseZone(acceso.fecha).format("HH:mm:ss")}
                  </ListGroupItem>
                }
              </ListGroup>

              <CardBody>      
                <Button
                  color="primary"
                  onClick={() => {
                    setScan(true)
                    setVoucher(null)
                  }}
                >
                  Volver <FontAwesomeIcon icon={faQrcode} /> 
                </Button>
              </CardBody>
            </Card>
          }
          <Alert color="success" isOpen={alerta>""}>
            {alerta}
          </Alert>
        </Container>
      }
    </>
  )
}

export default withAuthenticationRequired(QrVoucher, {
  onRedirecting: () => <Loading />,
});
