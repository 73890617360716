import { useContext } from "react";
import { PaginatorContext } from "../../providers/Paginator";

const UseTemplate = ({ children, template }) => {
  const {
    event,
    elements,
    setElements,
    scale,
    setScale,
    dimensionContainer,
    setDimensionContainer,
    uploadHistory,
  } = useContext(PaginatorContext)

  const HandleTemplate = (template) => {
    if (template === 'pulsera') {
      setDimensionContainer({ ...dimensionContainer, type: 'pulsera', width: 200, height: 17 })
      setScale(4)
      setTimeout(() => {
        setElements([
          {
            "id": "128",
            "x": 738,
            "y": 9,
            "width": 50,
            "height": 50,
            "type": "qr"
          },
          {
            "id": "129",
            "x": 297.99999999999955,
            "y": 24.999999999999986,
            "text": event,
            "fontSize": 21,
            "rotation": 0,
            "width": 228.69632969085396,
            "height": 25.03974412673583,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-evento"
          },
          {
            "id": "131",
            "x": 8,
            "y": 53,
            "text": "Pepe Gonzalez (dinamico)",
            "fontSize": 14,
            "rotation": 0,
            "width": 200,
            "height": 15,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-nombre"
          }
        ])
      }, 300)
      
    }
    if (template === 'tarjeta') {
      setDimensionContainer({ ...dimensionContainer, type: 'tarjeta', width: 100, height: 50 })
      setScale(6)
      setTimeout(() => {
        setElements([
          {
            "id": "117",
            "x": 404.0125537552901,
            "y": 50.83304794757473,
            "width": 165.77157238457835,
            "height": 165.7715723845781,
            "type": "qr",
            "fontSize": 0
          },
          {
            "id": "122",
            "x": 108,
            "y": 224,
            "text": "Pepe Gonzalez (dinamico)",
            "fontSize": 14,
            "rotation": 0,
            "width": 170,
            "height": 15,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-nombre"
          },
          {
            "id": "124",
            "x": 51,
            "y": 224,
            "text": "Nombre:",
            "fontSize": 14,
            "rotation": 0,
            "width": 60.999999999999986,
            "height": 14.999999999999984,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text"
          },
          {
            "id": "126",
            "x": 50.99999999999994,
            "y": 65.00000000000003,
            "text": event,
            "fontSize": 39,
            "rotation": 0,
            "width": 169.52659490477916,
            "height": 121.26719927495176,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-evento"
          },
          {
            "id": "127",
            "x": 441.0000000000001,
            "y": 234.00000000000028,
            "text": "012345678912 (dinamico)",
            "fontSize": 14,
            "rotation": 0,
            "width": 175,
            "height": 15.000000000000028,
            "state": false,
            "fontFamily": "Roboto",
            "fontStyle": "normal",
            "textDecoration": "",
            "fill": "#000000",
            "align": "left",
            "type": "text-codigo"
          }
        ])
      }, 300)
    }
    if (template === 'voucher') {
      setDimensionContainer({ ...dimensionContainer, type: 'voucher', width: 100, height: 50 })
      setScale(6)
      setTimeout(() => {
        setElements([])
      }, 300)
    }
    setTimeout(() => {
      uploadHistory(elements)
    }, 600)
  }

  return (
    <div className="w-100 d-flex justify-content-between align-items-center border rounded-3 p-2">
      <div>
        <p className=" text-center p-0 m-0 align-middle fs-6 fw-normal">{children}</p>
      </div>
      <div>
        <button className="btn btn-primary rounded-2 d-flex align-items-center p-0" onClick={() => HandleTemplate(template)}>
          <i className="material-symbols-outlined">add</i>
        </button>
      </div>
    </div>
  )
};

export default UseTemplate;