import { useContext, useEffect, useRef } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
import { PaginatorContext } from "../../providers/Paginator";

const QRField = ({ onChange, qrProp, onSelect, isSelected }) => {
  // const {  } = useContext(PaginatorContext);
  const [image] = useImage('https://upload.wikimedia.org/wikipedia/commons/5/5b/Qrcode_wikipedia.jpg');
  const {dimensionContainer,scale} = useContext(PaginatorContext);
  const qrRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([qrRef.current]);
      trRef.current.getLayer().batchDraw();

    }
  }, [isSelected])


  return (
    <>
      <Image
        onClick={onSelect}
        onTap={onSelect}
        ref={qrRef}
        draggable
        image={image}
        {...qrProp}
        onDragEnd={(e) => {
          onChange({
            ...qrProp,
            x: e.target.x(),
            y: e.target.y()
          })

        }}

        onDragMove={(e) => {
          const newX = Math.max(0, Math.min(e.target.x(), dimensionContainer.width*scale - qrProp.width))
          const newY = Math.max(0, Math.min(e.target.y(), dimensionContainer.height*scale - qrProp.height))
          qrRef.current.x(newX);
          qrRef.current.y(newY);
        }}

        onTransform={(e) => {
          const node = qrRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...qrProp,
            x: node.x(),
            y: node.y(),

            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          })

        }}



      />

      {isSelected && (
        <Transformer
          id={qrProp.id}
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            const isOut =
            newBox.x < 0 ||
            newBox.y < 0 ||
            newBox.x + newBox.width > dimensionContainer.width*scale ||
            newBox.y + newBox.height > dimensionContainer.height*scale;
            
            if (isOut) {
              return oldBox;
            }  
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}

    </>
  )
};

export default QRField;