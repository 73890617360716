import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import { Button, Input, Img } from "reactstrap";
import "./homeHeader.css";
import fondoHeader from "../../assets/background-header.jpg";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

export const HomeHeader = ({ searchPub, onSearchChange }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const { i18n, t } = useTranslation("global");
  const [currentIndex, setCurrentIndex] = useState(0);
  const views = [
      { title: <>{t("home.title1")}</> },
      { title: <>{t("home.title2")}</>},
      { title: <>{t("home.title3")}</>},
      { title: <>{t("home.title4")}</>}
  ];

  useEffect(() => {
      const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % views.length);
      }, 5000); 

      return () => clearInterval(interval); 
  }, []);

  const handleClick = () => {
    window.open(
      "https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="">
      {isMobile ? (
        <div className="header-container-mobile">
          <div className="mobile-background">
            <p className="mobile-title">{t("home.title1")}</p>
            <div className="mobile-search-container">
              <div className="search-input-container-mobile">
                <div className="icon-search-container-mobile">
                  {!searchPub ? (
                    <i className="material-symbols-outlined">search</i>
                  ) : (
                    <button
                      className="clearButton-mobile"
                      onClick={() => onSearchChange("")}
                    >
                      <i className="x-btn-search material-symbols-outlined">
                        close
                      </i>
                    </button>
                  )}
                </div>
                <Input
                  type="text"
                  placeholder={t("publications.searchEvent")}
                  value={searchPub}
                  onChange={(e) => onSearchChange(e.target.value)}
                  className="input-search-mobile"
                />
                <button type="submit" className="searchButton-mobile">
                {t("button.search")}
              </button>
              </div>
            </div>
          </div>
        </div>

      ) : (

        <div className="header-container">
          <div className="img-header">
            <img src={fondoHeader} />
          </div>
          <div className="content-header">
            <p className="content-header-title">{views[currentIndex].title}</p>

            <p>{t("home.subtitle")}</p>
            <div className="search-container">
              <div className="search-header">
                <div
                  className={`search-input-container ${searchPub ? "hay-txt" : ""}`}>
                  <div className="icon-search-container">
                    {!searchPub ? (
                      <i className="material-symbols-outlined">search</i>
                    ) : (
                      <button
                        className="clearButton"
                        onClick={() => onSearchChange("")}
                      >
                        <i className="x-btn-search material-symbols-outlined">
                          close
                        </i>
                      </button>
                    )}
                  </div>

                  <Input
                    type="text"
                    placeholder={t("publications.searchEvent")}
                    value={searchPub}
                    onChange={(e) => onSearchChange(e.target.value)}
                    className="input-search"
                  />
                </div>
                <button type="submit" className="searchButton">
                {t("button.search")}
                </button>
              </div>
            </div>
          </div>
        </div>

      )}


    </div>
  );
};
