import React, { useState } from "react";
import { Button, Modal, ModalHeader, Form, Input, FormGroup, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

const EditLocationModal = ({ id, callApi, loc, ubicaciones, onUpdate }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [value, setValue] = useState(loc.ubicacion);
  const [t] = useTranslation("global");

  function handleSubmit(e) {
    e.preventDefault();

    if(value.trim() === ''){
      setError(<>{t("errors.emptyField")}</>);
    }else if(value.length > 20){
        setError(<>{t("errors.characters")}</>);
    
    } else if (ubicaciones.some(item => item.ubicacion === value)) {
      setError(<>{t("errors.locationExist")}</>);
    }else{

    const updatedLocation = {
      id : loc.id,
      idevento: loc.idevento,
      ubicacion: value,
    }
    onUpdate(updatedLocation);
    toggle();
    setError("");
  }
}
function handleChange(e) {
    setValue(e.target.value);
  }

  const handleCancelClick = () => {
    setError("");
    setValue("");
    toggle();
  };

  return (
    <div>
      <Button outline color="primary" style={{marginLeft: '5px'}} onClick={toggle}>
      <FontAwesomeIcon icon={faPencil}/>
      </Button>
      <Modal style={{ zIndex: 9999, paddingTop:"80px"}}isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>{t("location.editLocation")}</ModalHeader>
        <ModalBody>
        <Form onSubmit={handleSubmit}>
                    <FormGroup>
                    <Input
                      type="text"
                      value={value}
                      onChange={handleChange}
                      style={{ borderColor: error ? 'red' : '' }}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    </FormGroup>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type= "submit" color="primary" style={{margin: "5px"}}>
                      {t("button.save")}
                    </Button>
                    <Button className="btn btn-outline-secondary" type="button" style={{margin: "5px"}} color="light" onClick={handleCancelClick}>
                    {t("button.cancel")}
                    </Button>
                    </div>
              </Form>
              </ModalBody>
          </Modal>
    </div>
  );
}
export default EditLocationModal;