import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMediaQuery } from "react-responsive";
import { api } from "../../services/api";
import "./ads.css";
import AdsDetail from "./AdsDetail";

import DatePicker from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { setDate } from "date-fns";


const categorias = ["Deportivos", "Beneficios", "Recepciones"];
let globalIndex = 0;

const categoriaIcons = {
  "Recepciones": "celebration",
  "Beneficios": "handshake",
  "Deportivos": "sports_and_outdoors"
};


const Ads = ({ searchPub }) => {
  const [publicaciones, setPublicaciones] = useState([]);
  const [publicacionesConFechaEvento, setPublicacionesConFechaEvento] = useState({});
 /*  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); */
  const [loading, setLoading] = useState(true);
  const [filteredPublicaciones, setFilteredPublicaciones] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [dateRange, setDateRange] = useState([]);
  /* const [searchPub, setSearchPub] = useState(''); */
  const calendarRef = useRef();
 /*  const pageSize = 3; */
  const { i18n, t } = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width:770px)` });

  const months = [
    ["Enero", "En"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "Ma"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sept"],
    ["Octubre", "Oct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dic"],
  ]
  const monthsEN = [
    ["January", "Jan"],
    ["February", "Feb"],
    ["March", "Mar"],
    ["April", "Apr"],
    ["May", "May"],
    ["June", "Jun"],
    ["July", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["October", "Oct"],
    ["November", "Nov"],
    ["December", "Dec"],
  ]
  const weekDays = [
    ["Domingo", "Do"],
    ["Lunes", "Lu"],
    ["Martes", "Ma"],
    ["Miercoles", "Mi"],
    ["Jueves", "Ju"],
    ["Viernes", "Vi"],
    ["Sabado", "Sa"],
  ]
  const weekDaysEN = [
    ["Sunday", "Sun"],
    ["Monday", "Mon"],
    ["Tuesday", "Tue"],
    ["Wednesday", "Wed"],
    ["Thursday", "Thu"],
    ["Friday", "Fri"],
    ["Saturday", "Sat"],
  ]

  const handleFecha = (dates) => {
    setDateRange(dates);
    setSelectedCategory('');
  };

  const fetchData = async () => {
    try {
      const response = await api.publicaciones.getAllActive(/* "", pageSize, currentPage */);

      if (response.status === "success") {

        setPublicaciones(response.data.publicaciones || []);
       /*  const existingPublications = new Set(publicaciones.map(pub => pub.id));
        const filteredNew = newPublicaciones.filter(pub => !existingPublications.has(pub.id));

        setPublicaciones([...publicaciones, ...filteredNew]);
        setCurrentPage((prevPage) => prevPage + 1);
        setHasMore(newPublicaciones.length >= pageSize); */

      } else {
        console.error("Error fetching publicaciones: ", response.message);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTipoEvento = (idevento, tipoEvento) => {
    setPublicaciones((prevPublicaciones) =>
      prevPublicaciones.map((pub) =>
        pub.idevento === idevento ? { ...pub, category: tipoEvento } : pub
      )
    );
  };

  const handleFechaEventoChange = (publicacionId, fechaEvento) => {
    setPublicacionesConFechaEvento(prev => ({
      ...prev,
      [publicacionId]: fechaEvento
    }));
  };

  const handleCategoryChange = (category) => {
    setDateRange([])

    setSelectedCategory(category);
    /* setSearchPub(''); */
  };

  const handleAllPublications = () => {
    setSelectedCategory('');
    setDateRange([]);
  };

  const handleSearchChange = (searchValue) => {
    /* searchPub(searchValue);
    setSelectedCategory(''); */
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let filtered = publicaciones;

    if (selectedCategory) {
      filtered = filtered.filter(pub => pub.category === selectedCategory);
    }

    const [startDate, endDate] = dateRange;
    if (startDate && endDate) {
      filtered = filtered.filter(pub => {
        const fechaEvento = publicacionesConFechaEvento[pub.id];
        if (!fechaEvento) return false;
        const pubDate = new Date(fechaEvento);
        return pubDate >= startDate.toDate() && pubDate <= endDate.toDate();
      });
    }

    if (searchPub) {
      filtered = publicaciones.filter((pub) =>
        pub.titulo.toLowerCase().includes(searchPub.toLowerCase())
      );
    }

    setFilteredPublicaciones(filtered);
  }, [publicaciones, selectedCategory, dateRange, searchPub, publicacionesConFechaEvento]);

  return (
    <>

      {isMobile ? (
        <div className="pb-4" style={{ backgroundColor: "#ffff" }}>
          <div className="filters-mobile">
            <div className="category-buttons-mobile">
              <button className={`category-button-mobile ${selectedCategory === '' ? 'active' : ''}`} onClick={handleAllPublications}>
                <p className="txt-btn-category">{t("publications.all")}</p>
              </button>

              {categorias.map((category) => (
                <button
                  className={`category-button-mobile ${selectedCategory === category ? 'active' : ''}`}
                  key={category}
                  onClick={() => handleCategoryChange(category)}
                >
                  <p className="txt-btn-category-mobile">{category}</p>
                </button>
              ))}
            </div>
          </div>
          <div className="calendar-mobile">
            <div className="container-calendar-mobile" ref={calendarRef}>
              <DatePicker
                placeholder={t("home.filterDate")}
                months={months}
                weekDays={weekDays}
                range
                value={dateRange}
                onChange={handleFecha}
                format={`DD MMMM YYYY`}
                numberOfMonths={1}
                rangeColors={['#0A58C2']}
                dateSeparator=" al "
                className="fs-6 rmdp-mobile"
                mobileButtons={[
                  {
                    label: "Limpiar",
                    type: "button",
                    className: "rmdp-button rmdp-action-button",
                    onClick: () => setDateRange([]),
                  }
                ]}
                mobileLabels={{
                  OK: "Aceptar",
                  CANCEL: "Cerrar",
                }}
              />
            </div>
          </div>

{/* 
          <InfiniteScroll dataLength={filteredPublicaciones.length} next={fetchData} hasMore={hasMore}> */}
            <div className="container-ads-mobile">
              <div className="columns-ads-mobile">
                {filteredPublicaciones.length > 0 ? (
                  filteredPublicaciones.map((pub) => (
                    <AdsDetail className="ads-detail-mobile" key={pub.id} publicacion={pub} onTipoEventoChange={handleTipoEvento} onFechaEventoChange={handleFechaEventoChange} />
                  ))
                ) : (
                  <p className="mt-5">{t("publications.publicationsAlert")}</p>
                )}
              </div>
            </div>
          {/* </InfiniteScroll> */}
        </div>

      ) : (

        <div className="pb-4" style={{ backgroundColor: "#ffff" }}>
          <div className="filters">
            <div className="category-buttons">
              <div className={`button-ads ${selectedCategory === '' ? 'active' : ''}`}>
                <button className={`category-button `} onClick={handleAllPublications}>
                  <p className="txt-btn-category">{t("publications.all")}</p>
                </button>
              </div>

              {categorias.map((category) => (
                <div className={`button-ads ${selectedCategory === category ? 'active' : ''}`} key={category}>
                  <button className={`category-button `} onClick={() => handleCategoryChange(category)}>
                    <p className="txt-btn-category">{category}</p>
                  </button>
                </div>
              ))}
            </div>
            <div className="container-calendar" ref={calendarRef}>
              <DatePicker
                placeholder={t("home.filterDate")}
                months={months}
                weekDays={weekDays}
                range
                value={dateRange}
                onChange={handleFecha}
                format={`DD MMMM YYYY`}
                numberOfMonths={1}
                rangeHover
                rangeColors={"#7D888D"}
                dateSeparator=" al "
                className="fs-6 rmdp-prime"
                scrollSensitive={false}
                plugins={[
                  <Toolbar
                    position="bottom"
                    names={{
                      today: "Hoy",
                      deselect: "Limpiar",
                      close: "Cerrar"
                    }}
                  />
                ]}
              >
              </DatePicker>
            </div>
          </div>

          {/* <InfiniteScroll dataLength={filteredPublicaciones.length} next={fetchData} hasMore={hasMore}> */}

            <div className="container-ads-desktop">
              <div className="columns-ads">
                {filteredPublicaciones.length > 0 ? (
                  filteredPublicaciones.map((pub) => (
                    <AdsDetail className="ads-detail" key={pub.id} publicacion={pub} onTipoEventoChange={handleTipoEvento} onFechaEventoChange={handleFechaEventoChange} />
                  ))
                ) : (
                  <p className="mt-5">{t("publications.publicationsAlert")}</p>
                )}
              </div>
            </div>
          {/* </InfiniteScroll> */}
        </div>
      )}
    </>

  );
};

export default Ads;
